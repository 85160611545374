import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Mykonos_ by Fleet Foxes

La agenda de hoy:

- Terminar Unidad 7 y repasarla

*******************************************************************************

### Repaso 

|_Color_| Color|
|---| ---|
|_blanco_ | white|
|_azul_ | blue|
|_amarillo_ | yellow|
|_negro_ | black|
|_anaranjado_ | orange|
|_verde_ | green|
|_morado_ | purple|
|_rojo_ | red|
|_marrón_ | brown|
|_gris_ | gray|
|_rosado_ | pink|

- una camisa azul = a blue shirt
- un lápiz anaranjado = an orange pencil
- un pantalón negro = black pants
- una blusa morada = a purple blouse
- una corbata verde = a green tie
- zapatos marrones = brown shoes
- una falda azul = a blue skirt
- un vestido blanco = a white dress
- calcetines negros = black socks
- ¿Cuánto cuesta esta corbata? = How much is this tie?
- La corbata cuesta $20 = The tie is twenty dollars
- ¿Cuánto cuestan estos zapatos? = How much are these shoes? 
- Los zapatos cuestan $50 = The shoes are fifty dollars

------
- factura = receipt/invoice/bill
- buy (bai) = comprar
- bye = adiós
- should = debería
- I should finish my work early = Yo debería terminar mi trabajo temprano
- What should I do? = ¿Qué debería hacer? 
- Quiero comprar una cartera = I want to buy a purse/handbag.
- Me gusta correr = I like to run
- Necesito limpiar mi cuarto = I need to clean my room
- Necesito comprar comida = I need to buy food
- tener que + verbo = to have + infinitive
- Tengo que ir al banco = I have to go to the bank
- Él tiene que hacer la tarea = He has to do homework
- Do you need help? = ¿Necesitas ayuda?
- What did you do today? = ¿Qué hiciste hoy?
- I cooked today = Hoy cociné
- I worked = trabajé
- I used to work = yo trabajaba
- I have worked = He trabajado
- ayer = yesterday

*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 10 Lecture Notes (11/9)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
