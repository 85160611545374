import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

export default function Navbar() {
	return (
		<div>
			<ul className = "navbar">
				<li className = "navlist"> <Link to='/'><FaHome/></Link></li>

				<li className = "navlist"> <Link to='/blog'>Blog</Link></li>
				<li className = "navlist"> <Link to='/books'>Books</Link></li>
			</ul>
		</div>
	);
}
