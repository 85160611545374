import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
Song of the day (_Canción del día_): _Before He Cheats_ by Carrie Underwood
Lo que vamos a cubrir: los temas C y D del cuarto capítulo

*******************************************************************************
## Vocabulary review

|Español | English |
|:-----|:-----|
|enfermero| nurse|
|medicina| medicine|
|doctor| doctor|
|consultorio| doctor’s office|
|paciente| patient|
|pierna| leg|
|mano| hand|
|brazo| arm|
|cabeza| head|
|estómago| stomach|
|pie| foot|
*******************************************************************************

## What hurts?
La frase «¿Qué te/le duele?» se puede traducir como *What hurts?*.
Imagínense una conversación entre dos personas, Jack y Jill:

– Jack: What hurts?\\
– Jill: ________\\
– Jack: ________

¿Con qué puede contestar Jill?

Ella va a utilizar la forma «my» + «parte del cuerpo» + «hurt(s)».
¿Cómo se sabe cuál forma hay que utilizar, _hurt_ o _hurts_?
Si el sujeto es singular, se usa _hurts_. Si el sujeto es plural, se usa _hurt_. Por ejemplo, con _leg_
(pierna), se usa _hurts_, pero con _arms_ (brazos), se usa _hurt_.

Por lo tanto, la conversación puede suceder así:

– Jack: What hurts?\\
– Jill: My leg hurts./My arms hurt. (Me duele la pierna./Me duelen los brazos.)\\
– Jack: I’m sorry.

### Unos ejemplos

— What hurts? (¿Qué te duele?) \\
— My hand hurts. (Me duele la mano.)

— What hurts? -> (¿Qué te duele?)\\
— My hands hurt. -> Me duelen las manos. 

— ¿Qué te duele? (What hurts?)\\
— Me duele el ojo. (My eye hurts.)

— ¿Qué te duele? (What hurts?)\\
— Me duelen los ojos. (My eyes hurt.)

— Me duelen los pies: My _feet_ hurt\\
— Me duele el pie: My _foot_ hurts

__Plurales irregulares__:\\
• _foot/feet_ - pie/pies\\
• _tooth/teeth_ - diente/dientes
*******************************************************************************

## Unas enfermedades/unos síntomas comunes
| English | Español |
|:-----| :-----|
|stomachache |dolor de estómago|
|headache | dolor de cabeza|
|toothache | dolor de muelas|
|sore throat | dolor de garganta|
|fever | fiebre|
|cold | resfriado|
|ache | dolor|

Para expresar que alguien tiene una enfermedad o que experimenta un síntoma, se usa
la fórmula <pronombre personal/nombre de la persona> + <conjugación del verbo _to
have_> + <a/an> + <la enfermedad/el síntoma>.

Primero, repasemos la conjugación el verbo _to have_ (tener).

|Pronoun | Conjugation|
|:------| :-----|
| I | have|
|You | have|
|She/He | has|
|We | have|
|They | have|

Veamos unos ejemplos:

— Ella tiene dolor de cabeza. → She has a headache.\\
— Tengo una fiebre. → I have a fever.\\
— Iván tiene dolor de garganta. → Iván has a sore throat.\\
— Ana está resfriada. → Ana has a cold.\\
— David tiene dolor de estómago. → David has a stomachache.

### Hot and Cold
Para decir que alguien tiene frío/calor, se usa la expresión _to be cold/hot_.
Les dejo la conjugación del verbo _to be_ como referencia:

|Pronoun| Conjugation| Contraction|
|:-----|:------|:-----|
|I| am |I’m|
|You|are|You’re|
|She/He/It|is|She’s/ He’s|
|We|are|We’re|
|They|are|They’re|

Vamos a ver unos ejemplos:\\
• Tenemos frío. → We are cold/We’re cold.\\
• Miguel tiene frío. → Miguel is cold.\\
• Tengo calor. → I am hot./I’m hot.

Para decir que alguien está resfriado, se usa la expresión _to have a cold._

Unas frases útiles:\\
• ¿Estás bien? → _Are you okay?_\\
• ¿Cómo te sientes? → _How are you feeling?_\\
• ¿Qué te pasa? → _What’s the matter?_\\
• ¿Ya tomaste algo? → _Did you take something for it?_\\
• ¿Puedo hacer algo por ti? → _Can I do something for you?_\\
• ¿Cómo te puedo ayudar? → _How can I help you?_\\
• ¿Te puedo ayudar? → _Can I help you?_\\
• ¿Necesitas ayuda? → _Do you need help?_\\
• ¿Puedes ayudarme? → _Can you help me?_\\
• Necesito ... → _I need ..._

Una conversación:

– Jack: What’s the matter?\\
– Jill: I have a cold. (Estoy resfriada.)\\
– Jack: Did you take something for it? (¿Te tomaste algo?)
*******************************************************************************
## Las partes del cuerpo - una lista más completa, opcional

|English | Español|
|:----|:-----|
|body|cuerpo|
|foot|pie|
|feet |pies|
|leg|pierna|
|toe|dedo de pie|
|hand |mano|
|arm|brazo|
|finger|dedo|
|tooth|diente|
|teeth|dientes|
|neck|cuello|
|nose|nariz|
|eyes|ojos|
|mouth|boca|
|ears|orejas|
|head|cabeza|
|hair|cabello|
|tongue|lengua|
|eyelashes|pestañas|
|eyebrows|cejas|
|eyelid|párpado|
|face|cara|
|forehead|la frente|
|elbow|codo|
|shoulders|hombros|
|knees|rodillas|
|nails|uñas|
|ankle|tobillo|
|heel|talón|
|waist|cintura|
|chest|pecho|
|heart|corazón|
|stomach|estómago|
|throat|garganta|
|wrist|muñeca|
|jaw|mandíbula|
|back|espalda|
|thumb|pulgar|
|index finger |índice|
|middle finger |medio|
|ring finger |anular|
|little finger/pinky |meñique|

## Más vocabulario - el consultorio, opcional

|English | Español|
|:----|:-----|
|sick (adj) |enfermo (adj.)|
|health |la salud|
|healthy |saludable/sano|
|unhealthy |insalubre|
|flu|gripe|
|cough|tos|
|pills|pastillas/píldoras|
|sneeze|estornudo|
|allergy|alergia|
|nausea|náusea|
|infection|infección|
|shots/injections |inyecciones|
|pain|dolor|
|cast|yeso|
|magazine|revista|
|waiting room|sala de espera|
|toddler|niño de 2 o 3 años|
|appointment|cita|
|paperwork |papeleo|
|insurance|seguranza|
|card|tarjeta|
|blood|sangre|
|blood pressure|presión arterial|
|thermometer|termómetro|
|temperature|temperatura|
|stethoscope|estetoscopio|
|ultrasound|ultrasonido|
|tooth extraction|extracción de muelas|
|family planning|planificación familiar|
|overweight|sobrepeso|
|underweight|de peso más bajo que el normal|
|fracture|quebradura|
|give birth|dar a luz|
|bloodwork|exámenes de sangre|
|anesthesia|anestesia|
|heart attack|infarto|
|stroke|apoplejía/derrame cerebral|
*******************************************************************************
`

export default function Week05() {
	return (
		<div>
			<h1>Week 3 Lecture Notes (6/222)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://quizlet.com/206745028/partes-del-cuerpo-ingles-flash-cards/"> Un Quizlet sobre las partes del cuerpo</a></li>
					<li><a href="https://www.wallstreetenglish.com.ar/blog/vocabulario-basico-en-ingles-para-ir-al-medico">Más vocabulario médico</a></li>
					<li><a href="https://www.wallstreetenglish.cl/blog/vocabulario-medico-en-ingles">Más información y vocabulario médico</a></li>

				</ul>
			</p>
		</div>
	)
}
