
import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

- Song of the day (_Canción del día_): _All Too Well_ by Taylor Swift
- La agenda de hoy:
	- Comenzar la Unidad 8

*******************************************************************************
| Profesión | Profession |
| ---- | -----|
| _vendedor_ | salesperson |
| _mecánico_ | mechanic |
| _mesero_ | server |
| _recepcionista_ | receptionist |
| _cajero_ | cashier | 
| _plomero_ | plumber |
| _conductor de autobús_ | bus driver |
| _pintor_ | painter |
| _ama de casa_ | homemaker |
| _ayudante del profesor_ | teacher's aide |
| _conserje_ | custodian |

| Sentence | _Traducción_ | Profession |
| ---- | ---- | ---- |
| She answers the phone | _Ella contesta el teléfono_ | receptionist|
| She counts money | _Ella cuenta dinero_ | cashier |
| He fixes cars | _Él arregla carros_ | mechanic |
| He cleans buildings | _Él limpia edificios_ | custodian |
| She sells clothes | _Ella vende ropa_ | salesperson |
| He serves food | _Él sirve comida_ | server |


- What do you do (for work)? = _¿Qué haces?_
- What does she/he do? = _¿Qué hace ella/él?_

## Preguntas y respuestas
- Does he fix cars? = _¿Él arregla carros?_
	- Yes, he does. = _Sí, él arregla carros._
	- No, he doesn't. = _No, él no arregla carros._
- Does she sell clothes? = _¿Ella vende ropa?_
	- Yes, she does. = _Sí, ella vende ropa._
	- No, she doesn't. = _No, ella no vende ropa._
- Do you serve food? = _¿Tú sirves comida?_
	- Yes, I do. = _Sí, yo sirvo comida._
	- No, I don't. = _No, yo no sirvo comida._
- Do they clean buildings? = _¿Ellos limpian edificios?_
	- Yes, they do. = _Sí, ellos limpian edificios._
	- No, they don't. = _No, ellos no limpian edificios._
- Does it (the dog) run? = _¿El perro corre?_
*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 10 Lecture Notes (11/7)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
