import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Too Sweet_ by Hozier

La agenda de hoy:

- Terminar la Unidad 6

-------------------------------------------------------------------------------
| Event | _Evento_ |
| ----| -----|
| appointment | _cita_ | 
| movie | _película_ |
| class | _clase_ |
| meeting | _reunión_ |
| party | _fiesta_ |
| TV show | _serie/programa_ |
| concert | _concierto_ |


- Son las 7 = It's seven o'clock
- Son las 5:30 = It's five thirty/It's half past five
- Son las 5:15 = It's five fifteen/It's quarter past five
- Son las 4:45 = It's four forty-five/It's quarter til/to five (faltan cuarto para las cinco)
- mediodía = noon
- medianoche = midnight
- por la mañana = in the morning
- por la tarde = in the afternoon/evening
- por la noche = in the night
- AM (ey-em) - medianoche a mediodía
- PM (pi-em)- mediodía a medianoche

*******************************************************************************
### Unas horas especiales

- _quarter past_ = y cuarto (_quarter_ significa «cuarto»)
- _half past_ = y media (_half_ significa «mitad», y la «l» no se pronuncia)
- *It is &lt;minutes&gt; till &lt;hour&gt;* = faltan &lt;minutos&gt; para &lt;hora&gt;
- faltan &lt;minutes&gt; para finalizar = _&lt;minutes&gt; left / twenty &lt;minutes&gt; to go_

| Frase | Traducción |
| ----- | --------|
| _Son las ocho y cuarto_ | It's quarter past eight |
| _Son las seis y media_ | It is half past eight |
| _Son las nueve y diez_ | It is ten past nine |
| _Faltan cuarto para las ocho_ | It's quarter to/till eight |
| _Faltan cinco para las doce_ | It's five to/till twelve |
| _Faltan diez para las tres_ | It's ten to/till three |
*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 8 Lecture Notes (3/28)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.ingles.com/guia/como-decir-la-hora-en-ingles">Decir la hora en inglés</a></li>
				</ul>
			</p>
		</div>
	)
}
