import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Who's Afraid of Little Old Me_ by Taylor Swift

La agenda de hoy:

- repasar la unidad 8
- continuar con la unidad 9

*******************************************************************************
# Professions

| Profesión | Profession |
| ---- | -----|
| _vendedor_ | salesperson |
| _mecánico_ | mechanic |
| _mesero_ | server |
| _recepcionista_ | receptionist |
| _cajero_ | cashier | 
| _plomero_ | plumber |
| _conductor de autobús_ | bus driver |
| _pintor_ | painter |
| _ama de casa_ | homemaker |
| _ayudante del profesor_ | teacher's aide |
| _conserje_ | custodian |

| Sentence | _Traducción_ | Profession |
| ---- | ---- | ---- |
| She answers the phone | _Ella contesta el teléfono_ | receptionist|
| She counts money | _Ella cuenta dinero_ | cashier |
| He fixes cars | _Él arregla carros_ | mechanic |
| He cleans buildings | _Él limpia edificios_ | custodian |
| She sells clothes | _Ella vende ropa_ | salesperson |
| He serves food | _Él sirve comida_ | server |

- Ellos sirven comida = They serve food
- Yo sirvo comida = I serve food
- Ellos reparan carros = They fix cars
- Yo cuento dinero = I count money
- Yo limpio edificios = I clean buildings
*******************************************************************************
## Present progressive
- Presente progresivo = present progressive
	- Estar + participio / « to be » + participle
	- -ando/-iendo = -ing
- Yo estoy escribiendo = I am writing

## Formar el participio presente
- eat (comer) - eating
- walk (caminar) - walking
- cook (cocinar) - cooking

#### Excepciones
- Una « e » muda - hay que quitarla antes de añadir el sufijo
	- write - writing
	- make - making
	- bike - biking
- Si tiene una sílaba y termina en consonante-vocal-consonante - dupliquen la última letra
	- cut (cortar) - cutting
	- stop (parar) - stopping
	- run (correr) - running
	- get - getting
	- Excepciones - si la palabra termina en x, w, y - solamente agreguen el sufijo
		- buy (comprar) - buying
		- draw (dibujar) - drawing
		- tow (remolcar) - towing
		- tax (cobrar impuestos) - taxing
- Si termina en una c, agreguen una k 
	- panic (aterrorizarse) - panicking
- Verbos que terminan en « ie »: reemplacen « ie » con « y »
 	- tie (atar) -  tying
	- lie (mentir) - lying

## Verbs!
- Verb Forms
	- Base form (diccionario) (wash = lavar)
	- Infinitive (to walk = lavar)
		- Quiero leer un libro = I want to read a book
	- Conjugation (washes = lava)

| Infinitive | Present progressive | _Traducción_| 
|----| ----| ----|
| to do homework | doing homework | _hacer tarea_ |
| to do the laundry | doing the laundry | _lavar la ropa_ |
| to dry the dishes | drying the dishes | _secar los platos_ |
| to make lunch | making lunch | _preparar el almuerzo_ | 
| to make the bed | making the bed | _arreglar la cama_ |
| to wash the dishes | washing the dishes | _lavar los platos_ |
| to cut the grass | cutting the grass | _cortar el pasto_ |
| to get the mail | getting the mail | _recoger el correo_ |
| to take out the trash | taking out the trash | _sacar la basura_ |
| to walk the dog | walking the dog | _pasear al perro_ |
| to wash the car | washing the car | _lavar el carro_ |
| to water the grass | watering the grass | _regar el pasto_ | 

- Vamos a lavar el carro = We are going to wash the car
- Lavamos el carro = We wash the car
- Estamos lavando el carro = We are washing the car

*******************************************************************************
1. Pasear al perro
	- Tú paseas al perro = You walk the dog
	- Tú estás paseando al perro = You are walking the dog
	- Tú quieres pasear al perro = You want to walk the dog
2. Secar los platos
	- Yo seco los platos = I dry the dishes
	- Yo estoy secando los platos = I am drying the dishes
	- Necesito secar los platos = I need to dry the dishes
	- No me gusta secar los platos = I don't like to dry the dishes
3. Secar los platos
	- Ella seca los platos = She dries the dishes
	- Ella está secando los platos = She is drying the dishes
	- Ella tiene que secar los platos = She has to dry the dishes
4. Regar el pasto
	- Él riega el pasto = He waters the grass
	- Él está regando el pasto = He is watering the grass
	- A él le gusta regar el pasto = He likes to water the grass
5. Preparar el almuerzo
	- Ellos preparan el almuerzo = They make lunch
	- Ellos están preparando el almuerzo = They are making lunch
	- Ellos quieren preparar el almuerzo = They want to make lunch
	- Ellos tienen que preparar el almuerzo = They have to make lunch
	- Ellos tenían que preparar el almuerzo = They had to make lunch
*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 11 Lecture Notes (7/11)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.ingles.com/guia/el-presente-progresivo">Presente progresivo</a></li>
					<li><a href="https://inglesfull.com/presente-progresivo-en-ingles/">Presente Progresivo en Inglés</a></li>
				</ul>
			</p>
		</div>
	)
}
