import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
Song of the day (_Canción del día_): _The Living Years_, by Mike + The Mechanics\\
Lo que vamos a cubrir: terminar la primera unidad (temas E, F)

*******************************************************************************

| Country | _País_ |
| ---- | ---- |
| the United States | _los Estados Unidos_ | 
| Mexico | _México_ |
| Haiti | _Haíti_ |
| Brazil | _Brazil_ |
| Russia | _Rusia_ |
| Somalia | _Somalia_ |
| Vietnam | _Vietnam_ |
| China | _China_ |

- Where are you from? = _¿De dónde eres?_
- Where is he from? = _¿De dónde es él?_
- Where is she from? = _¿De dónde es ella?_
- Where are they from? = _¿De dónde son ellos?_

Pregunta y respuesta:
- Where are you from?
- I am from the United States.

| to be | _ser/estar_ |
| ---- | --- |
| I am | _yo soy/estoy_ |
| You are | _Tú eres/estás_ | 
| He is | _Él es/está_ |
| She is | _Ella es/está_ |
| It is | _Es/está_ |
| We are | _Nosotros somos/estamos_ | 
| They are | _Ellos son/están_ |


- I am from ... = _Soy de ..._
- He is from Russia 
- She is from China
- They are from Mexico


| _Mes_ | Month |
| ---- | ----- |
| _enero_| January |
| _febrero_ | February |
| _marzo_ | March |
| _abril_ | April |
| _mayo_ | May | 
| _junio_ | June |
| _julio_ | July |
| _agosto_ | August | 
| _septiembre_ | September |
| _octubre_ | October |
| _noviembre_ | November |
| _diciembre_ | December | 

Los meses se escriben en mayúscula

- When is (When's) your birthday? =  _¿Cuándo es tu cumpleaños?_
- My birthday is in MONTH = _Mi cumpleaños es en MES_
- My birthday is DATE = _Mi cumpleaños es en FECHA_

| Word | _Palabra_ |
| --- | --- |
| name | _nombre_ |
| first name | _nombre de pila_ |
| last name | _appellido_ |
| area code | _código de área_ |
| ZIP code | _código postal_|
| phone number | _número de teléfono_|
| signature | _firma_ | 

| Possive pronouns | _pronombres posesivos_ |
| ---- | ---- |
| my | _mi/mis_ |
| your | _tu/tus_ |
| his | _su (de él)_ |
| her | _su (de ella)_ |
| our | _nuestro/nuestros_ |
| its | _su_ |

Respuestas de ejemplo con _my_:
- My phone number is 873-2246
- My ZIP code is 78723
- My area code is 512/737


Otras respuestas de ejemplo:
- Your last name is Jackson
- Our ZIP code is 78705
- What is (What's) your name? = _Cómo te llamas?_
- What is (What's) his name? = _Cómo se llama él?_
- What is (What's) her name? = _Cómo se llama ella?_
- Her name is Raquel = _Ella se llama Raquel_
- His name is Miguel = _Él se llama Miguel_
- Your name is Manuel = _Tú te llamas Manuel_
- What is your .... / _¿Cuál es tu/tus ...._

*******************************************************************************
`

export default function Week01() {
	return (
		<div>
			<h1>Week 1 Lecture Notes (6/8)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://www.ingles.com/guia/como-dar-la-fecha-en-ingles">https://www.ingles.com/guia/como-dar-la-fecha-en-ingles</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
