import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Harleys in Hawaii_ by Katy Perry

La agenda de hoy:

- Empezar Unidad 5 (hacer actividades en secciones A y B)

*******************************************************************************

## Los lugares (Places)
|Español | English |
|:-----|:-----|
|banco| bank|
|biblioteca| library (lai-bre-ri)|
|librería| bookstore/bookshop|
|restaurante| restaurant  |
|tienda| store/shop|
|escuela| school|
|calle| street|
|supermercado| supermarket|
|peluquería| barbershop/salon|
|panadería| bakery|
| farmacia | pharmacy|
| hospital | hospital | 
| cine | movie theater |
| gasolinera | gas station | 
| lavandería | laundromat | 
| oficina de correo | post office | 

- ph suena como "f"
	- physics = física
	- photo = foto

- Where's = Where is
- Ricardo está en el banco = Ricardo is at the bank
- Ricardo is in the bank

| English | Traducción | 
| --- | --- |
|I need to buy books! Where can I buy them? <br/> Bookstore | ¡Necesito comprar libros! ¿Dónde puedo comprarlos? <br/>  librería| 
| Where can I learn English? <br/> School | ¿Dónde puedo aprender inglés? <br/> Escuela|
| I'm hungry! Where can I get some food? <br/> Restaurant/Supermarket | Tengo hambre. ¿Dónde se vende comida? <br/> Restaurante/supermercado |
| Where can I drive a car? <br/> Street | ¿Dónde puedo manejar un carro? <br/> Calle |
| I want cookies. Where can I get some? <br/> Bakery/supermarket | Quiero galletas. ¿Dónde se venden? <br/> Panadería/supermercado|
| I wrote a letter to my friend. Where can I send it? <br/> Post office | Escribí una carta a mi amigo. ¿Dónde puedo enviarla? <br/> Oficina de correro |
| My car ran out of gas! Where should I go? <br/> gas station | ¡Mi carro se quedó sin gasolina! ¿Adónde debo ir? <br/> Gasolinera  | 
| I need a haircut. Where should I go? <br/> barbershop | Necesito un corte de cabello. ¿A dónde debo ir? <br/> Peluquería |
| Where can I get vaccinated? <br/> pharmacy/hospital | ¿Adónde puedo ir para vacunarme? <br/> Farmacia/hospital |
| This man is really sick! Where should they take him? <br/> Hospital | ¡Este hombre está muy enfermo! ¿Adónde lo deben llevar? <br/> hospital | 

Prepositions
- I am at home = Estoy en casa
- I am at work
- I am at the library
- I am at school
- I am on the street
*******************************************************************************
`

export default function Week02() {

	return (
		<div>
			<h1>Week 6 Lecture Notes (10/10)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://englishlive.ef.com/es-mx/blog/tips-para-estudiar/reglas-de-fonetica-para-leer-y-escribir-hablar/">La "e" muda</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
