import React from 'react';
import { Link } from 'react-router-dom';

export default function Arch () {
	return (
		<div>
			<h1>Arch Linux Install + Setup</h1>

			<h2>Why the switch?</h2>
			<p>
				I previously used to run Debian on &Eacute;owyn, and Ubuntu prior to that.
				I was getting frustrated with Debian for a fair bit of time.
				For one, support for Debian packages seemed like an afterthought.
				The <span class="monospace">mupdf</span> plugin
				for <span class="monospace">zathura</span> is officially supported for
				Arch, and there's a ppa for Ubuntu folks. For Debian, though? Zilch.
				Other packages seemed to require the latest versions of programs
				like vim/neovim, and the latest version of the package Debian would let
				me get was still way behind the latest released version.
				I really just wanted the most updated software, and Arch seemed to offer that.
				Arch also seemed to have really, really nice documentation about everything.
				Some combination of the wikis and the forums seemed to cover just about
				everything I needed. I wasn't really seeing that much of an involved
				user base in Debian.
			</p>
			<p>
				My Debian installation was also getting to be a bit bloated and gross.
				I had no idea what half the things that lived in
				my <span class="monospace">.config</span> directory were, so I wanted
					to build from ground-up.
				Being able to pick and choose what I wanted my setup to look and
				feel like, and not having loads of unnecessary packages installed
				and programs launched was important to me.
			</p>
			<p>
				Just FYI, I'm probably going to define some terms here that I learnt along the way,
				but feel free to skip those if you already know what they mean!
			</p>
			<h2>Installation</h2>
			<p>
				With that said, let's talk about the installation process. As of April 2021,
				Arch has an <a href="https://wiki.archlinux.org/title/Archinstall"> official
				guided installation script (archinstall) </a>, which radically simplifies things. With
				the installer, most of the installation should be rather seamless, so don't
				worry too much.
			</p>
			<p>
				As always, burn the image to a flash drive, and launch from there.
				As for the burning, I like using <span class="monospace">dd</span> after
				praying to the Linux gods; I've had a really low success rate with programs like Etcher.
				Once you're in, use <span class="monospace">iwctl</span> (this tool is so nice!)
				to get an internet connection going. Then you can proceed
				with <span class="monospace">archinstall</span>!
			</p>
			<p>
				Go ahead and pick the options you like! For the formatting, I chose
				the Linux file system, and formatted the whole disk.
				I chose to keep the same network settings, and
				installed <span class="monospace">xorg</span> for the DE-related
				question, and had it install Intel drivers for the graphics card.
				That was pretty much it! There'll be a bit at
				the end that tells you that you can reboot; remember to take
				your flash drive out before you do that.
			</p>
			<p>
				Ideally, you'll be golden if you did all that.
				I think I accidentally skipped the step about installing a boot
				loader like GRUB2, so I couldn't boot into my computer for a bit. 
				I was initially planning to 
				use <a href="https://www.rodsbooks.com/refind/">rEFInd</a> as
				my boot <i>manager</i> based on a friend's recommendation.
				I made a live disk with reFINd, expecting to have to install it
				from there, but even once I took the flash drive out, I was able to
				boot into my computer.
				I'm pretty sure the way it works is that rEFInd has an auto-recognizing
				mechanism; I think that's why just inserting the flash drive worked.
				I'm using <span class="monospace">systemd-boot</span>, by sheer accident.
				It seems pretty minimalist-ey, and I haven't had to do much to configure
				it or anything, so I'm good with it for now.
			</p>
			<p>
				That's probably not a good reason to do anything, so here's some
				more background!
				Typically, GRUB2 is the default boot <i>loader</i> for many Linux distros.
				GRUB2's pretty decent! The configuration seems pretty complex, but
				that's probably more so due to my inexperience.
				The OG GRUB, GRUB Legacy, is a bit painful to modify,
				and LILO's really, really old. Neither support EFI booting.
				This brings us to the "EFI" in "rEFInd." The EFI (and now the UEFI)
				were created to avoid using the BIOS, which can be clunky and slow.
			</p>

			<h2>X</h2>
			<p>
				Yay! You're in! Now, you'll want to probably start X if you're going
				to do anything vaguely graphic-ey. I added the line
				<div class="realcode">
					exec startx
				</div>
				to my <span class="monospace">.bash_profile</span>.
				Now, your <span class="monospace">.xinitrc</span> will contain
				everything that's being launched, like any window manager you like.
				I went with i3! Install whatever you like and its dependencies (might
				take a bit of trial-and-error).
				This should work; if not, you might need to install
				some <span class="monospace">xorg</span>-ey things.
			</p>
			<p>
				The errors should give you a pretty good idea of what you need to install.
				Also, one thing about the <span class="monospace">exec</span> command
				is that it <em>replaces</em> the program running it. This means
				that anything after <span class="monospace">exec</span> is
				tantamount to a comment, since there won't be any program left to execute
				the rest of the instructions.
			</p>
			<p>
				Great! Assuming that works, you can resume setting everything else up
				mentioned in my <Link to="/blog/preferences">preferences</Link>.
				That file and its links should contain the rest!
			</p>
			<h2>Miscellaneous</h2>
			<p>
				I'm just putting down some things I had to do during this process.
			</p>
			<p>
				When I plugged in my monitor (through HDMI), it just duplicated
				the display. I wanted it to be more of an extention, so
				I had to add the following lines to my i3 config:
				<div class="realcode">
					exec_always --no-startup id xrandr --output DP3 --right-of eDP1 <br />
					exec_always --no-startup-id xrandr --output eDP1 --left-of DP3
				</div>
				{/* Do I know why the monitor is labeled "DP3" instead of HDMI-1 or something? Nope. */}
				{/* Do I know what DP1 and DP2 are? Also, nope. Please let me know if you find out, though! */}
				eDP1 stands for Embedded DisplayPort, which most likely refers to the native
				display on your laptop. the other DPs refer to other DisplayPorts.
				It might also be called something like "HDMI-A" or something like that depending
				on what kinds of external display connections you're using
			</p>
			<p>
				Initially, <span class="monospace">pulseaudio</span> gave me some weird error
				about "fail[ing] to open cookie file '~/.config/pulse/cookie'". I found
				some solution about symlinking it to another cookie file, but that failed. I don't know
				what that's about, but running it again helped.
				FYI, you'll need to run <span class="monospace">pulseaudio</span> before you
				run <span class="monospace">pavucontrol</span>.
				I added this to my i3 config as
				<div class="realcode">
					exec_always --no-startup-id pulseaudio
				</div>
			</p>
			<p>

				<s>
				I have no idea how to install fonts. I'll update this when I figure out how/find the
					the need to. So far, the default seems to be holding up okay.
				</s>
				Promised update:
				you can install fonts with <span class="monospace">pacman</span>.
				You can first query the packages with <span class="monospace">pacman -Ss</span>.
				The AUR's pretty sweet too!
			</p>

			<h2>That's all, folks!</h2>
			<p>
				<s>
				I'm still setting things up, but I'll update this in the upcoming weeks as I learn
				more about Arch and figure out what I want/need.
				</s> I feel like I've settled down with a distro.
				I'm the sort of person to change OSs any time I'm stressed, but since I installed Arch,
				I've pretty much lost interest in doing that. I've wanted to try QubesOS, BSDs, Gentoo, etc.
				but I feel like I'd wind up back here in Arch. The impeccable documentation,
				cutting edge software, and beautifully seamless package management (pacman and the AUR)
				make it nearly impossible to change distros.
				Even if I were to change, I think I'd miss my Arch set up too much.
				Feel free to reach out to me if you have any questions!
			</p>

			<hr/>
			<h2>Arch on Faramir</h2>
			<p>
				Setting up Arch on Faramir followed a similar process. Navigating the BIOS was a pain.
				Pressing <span class="monospace">F10</span> takes you to a BIOS settings menu. You'll have
				to disable secure boot and save and exit. It should ideally take you to this bit that
				asks you to enter in a code just to make sure. If it doesn't ask you to do this, it hasn't
				written the changes properly. From there,
				you can spam <span class="monospace">F9</span> till you get to the USB, and then you can boot
				from the USB. 
			</p>
			<p>
				This took me a few tries to get it working. When I tried disabling
				secure boot, go to the boot list and select the USB, I'd get some error about secure boots. When
				I went back to the corresponding section in the BIOS, it would say that the secure boot was enabled.
				I previously thought that something about choosing a boot option was overriding the settings,
				but it seems like the changes just weren't being written properly. Additionally, I did the same
				settings as before, this time selecting AMD drivers instead of Intel, using GRUB for the bootloader,
				and using <span class="monospace">zram</span>.
			</p>
		</div>
	);
}
