import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
*******************************************************************************
## Welcome! ¡Bienvenidos!

Cuando se unen a la reunión, por favor cambien sus nombres de Zoom para que coinciden con sus nombres reales (esto me ayuda a tomar asistencia). ¡Gracias!

When you join the meeting, please change your name to match your real name (this helps me take attendance). Thank you!

Song of the day (_Canción del día_): _Thousand Miles_ by Miley Cyrus, Brandi Carlile

La agenda de hoy:

- Unidad 1
	- Repasar secciones A y B
	- Hacer actividades en secciones C y D


*******************************************************************************
# Repaso

| Country | _País_ |
| ---- | ---- |
| the United States | _los Estados Unidos_ | 
| Mexico | _México_ |
| Haiti | _Haití_ |
| Brazil | _Brasil_ |
| Russia | _Rusia_ |
| Somalia | _Somalia_ |
| Vietnam | _Vietnam_ |
| China | _China_ |


Preguntas:
- Where are you from? =  _¿De dónde eres?_
- Where is he from? = _¿De dónde es él?_
- Where is she from? = _¿De dónde es ella?_
- Where are they from? = _¿De dónde son ellos?_

Respuestas:
- I am from ... = _Yo soy de ..._
- He is from ... = _Él es de ..._
- She is from ... = _Ella es de ..._
- They are from ... = _Ellos son de ..._
- We are from ... = _Somos de ..._

| to be | _ser/estar_ |
| ----- | ----- |
| I am | _Yo soy_ | 
| You are | _Tú eres/Ud. es/Uds. son_|
| He is | Él es | 
| She is | Ella es |
| We are | Nosotros somos|
| They are | Ellos son |



*******************************************************************************
## Sección C
- What's your name? (¿Cuál es tu nombre?) = _¿Cómo te llamas?_
- My name is ... = _Me llamo ..._

| Possessive pronouns | _Pronombres posesivos_ |
| ---- | ---- |
| my | _mi/mis_ |
| your | _tu/tus_ |
| his | _su (de él)_ |
| her | _su (de ella)_ |
| our | _nuestro/nuestros_ |
| its | _su_ |
| their | _su (de ellos)_ |


Ejemplos de posesión:
- my pencil = mi lápiz
- your pen = tu pluma
- his eraser = su borrador (el borrador de él)
- her stapler = su grapadora (la grapadora de ella)

Para presentar a alguien:
- What is her name? = _¿Cómo se llama ella?_
	- Her name is ... = _Ella se llama_
- What is his name? = _¿Cómo se llama él?_
	- His name is ... = _Él se llama_
- What are their names? = _¿Cómo se llaman ellos?_
	- Their names are ... = _Ellos se llaman_

*****************************************
| Word | _Palabra_ |
| --- | --- |
| name | _nombre_ |
| first name | _primer nombre_ |
| last name | _apellido_ |
| area code (512, 737)| _código de área_ |
| ZIP code (78723, 78705)| _código postal_|
| phone number | _número de teléfono_|
| ID card | _tarjeta de identificación_ |
| signature | _firma_ | 
| sign | _firmar_ |

- What's (What is) your last name? = _¿Cuál es tu apellido?_
- His last name is Jackson = _Su apellido es Jackson (de él)_ 
- What's your area code? = _¿Cuál es tu código de área?_
- Our area code is 512 = _Nuestro código de área es 512_
	- area = eiria (fonético)
- Her ZIP code is 78705 = _Su código postal es 78705 (de ella)_

*******************************************************************************
## Sección D


- birthday = cumpleaños
- When is (When's) your birthday? =  _¿Cuándo es tu cumpleaños?_
- When is (When's) her birthday? =  _¿Cuándo es su cumpleaños (de ella)?_
- When is (When's) his birthday? =  _¿Cuándo es su cumpleaños (de él)?_
- My birthday is in MONTH = _Mi cumpleaños es en MES_
- My birthday is in June
- His birthday is in February.
- Her birthday is in April.

| _Mes_ | Month |
| ---- | ----- |
| _enero_| January |
| _febrero_ | February (feb-yu-eri) |
| _marzo_ | March |
| _abril_ | April |
| _mayo_ | May | 
| _junio_ | June |
| _julio_ | July |
| _agosto_ | August | 
| _septiembre_ | September |
| _octubre_ | October |
| _noviembre_ | November |
| _diciembre_ | December | 
*******************************************************************************
`

export default function Week01() {
	return (
		<div>
			<h1>Week 1 Lecture Notes (9/5)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://www.ingles.com/guia/como-dar-la-fecha-en-ingles">https://www.ingles.com/guia/como-dar-la-fecha-en-ingles</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
