import React from 'react';

export default function Sleep () {
	return (
		<div>
			<h1>Sleep and Wake</h1>

			<h2>The Problem</h2>

				<p>
					&Eacute;owyn, my lovely Dell XPS 15 9570, likes to give its Ubuntu (and Linux) users hell when
					it comes to going to sleep mode and waking up. As it stood, I couldn't close my laptop and 
					then resume working, which was rather problematic since closing the laptop should have theoretically
					only put the computer in sleep mode. Every time I closed the lid, I had to power off and power on
					my laptop.
				</p>
				<h2>Stuff that didn't work</h2>
				<p>
					The first solution I looked up was command-line ways to put the computer to sleep. I
					tried using the <div class="realcode">pm-suspend-hybrid</div> command
					from <span class="monospace">pm-utils</span> as a superuser (which did
					nothing) as well as tried the <div class="realcode">systemcl hibernate</div> command.
					The latter had the same effect as closing the laptop, unfortunately.
				</p>
				<p>
					The next step was checking out my BIOS settings.
					<ol>
						<li>
							Get drivers (an <span class="monospace">.exe</span> file) from the Dell website
							(a truly dreadful experience), put the file on a USB drive, and mount the drive.
						</li>
						<li>
							Shut down the computer and enter the BIOS by pressing the F2 key, and going
							to <i>Settings</i> → <i>Power Management</i> →
							<i>USB Wake Support</i> as well as <i>Settings</i> → <i>Power Management</i> →
							<i>Block Sleep.</i> Why are BIOS's so painful to navigate?
						</li>
						<li>
							One website said that the <i>Enable USB Wake Support</i> button
							would be disabled, and that I should enable it. It also said that that
							the <i>Block Sleep</i> option would also be selected, and that
							I should disable it. However, my BIOS settings were already in these states.</li>
						<li>
							The next step was to update the BIOS.
							<ol>
								<li>Repeatedly pressing <i>F12</i> (I don’t actually know
									if doing it only once suffices)
								</li>
								<li>Selecting the <i>BIOS Flash Update</i></li>
								<li> Navigating to and selecting that <i>.exe</i> file we talked about earlier </li>
								<li> Waiting for a <i>while</i></li>
							</ol>
						</li>
					</ol>
					This did absolutely zilch to fix the issue, but it was a learning experience. Yay! I now
					know how to update my BIOS.
				</p>
			<h2>The Solution</h2>
				<p>
					There’s a pretty
					simple <a href="https://youtu.be/f-u7Zk_itUU">4-ish minute long YouTube video</a> that
					explained this the best. The chap pulled a ton of this stuff from the ArchWikis. 
					The pertinent stuff can be found in
					the <a href="https://wiki.archlinux.org/title/Dell_XPS_15_7590#Suspend"> Power Management</a> section
					of the page.
					<ol>
						<li>
							If you type <div class="realcode">cat /sys/power/mem_sleep</div> you’ll see
							the (default) response: <span class="monospace">[s2idle] deep</span>, meaning that
							the s2idle is selected.
						</li>
						<li>
							To fix the issue, you’ll want to use the deep version instead.
							You could just go ahead and use the command
							<div class="realcode">echo deep|sudo tee/sys/power/mem_sleep</div> which would
							temporarily fix the issue.
						</li>
						<li>
							To make the change permanent, change the kernel parameters by
							editing the <span class="monospace">/etc/default/grub</span> as a superuser.
						</li>
						<li>
							In the <span class="monospace">grub</span> file,
							the <span class="monospace">GRUB_CMDLINE_LINUX_DEFAULT</span> (the boot options)
							is set to <span class="monospace"> quiet splash</span>.
							<ul>
								<li> The <span class="monospace">quiet</span> part just means “non-verbose.”
									Without this, a bazillion messages would be printed to the screen.
								</li>
								<li> The <span class="monospace">splash</span> bit gives us something to look at
									while the real stuff loads in the background.
									Without this, we’d just be staring at a blank screen.
								</li>
							</ul>
						</li>
						<li>
							After the <span class="monospace">quiet splash</span>, add
							the text <span class="monospace">mem_sleep_default=deep</span>
						</li>
						<li> Now type <div class="realcode">sudo grub-mkconfig -o /boot/grub/grub.cfg</div> </li>
						<li> Reboot, test, and we’re done!</li>
					</ol>
				</p>
		</div>
	)
}
