import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
Song of the day: Graveyard, Halsey\\
Lo que vamos a hacer hoy: los temas A, B, C de la 6<sup>a</sup> unidad
*******************************************************************************

## Telling time! (_decir la hora_)

Vamos a aprender a contestar la pregunta _What time is it?_ (¿Qué hora es?)

Con horas exactas, se contesta con _<el número cardinal de la hora>_ + _o'clock_\\
_o’clock_ viene de « of the clock » = del reloj 
- Son las 10 en punto: _It is 10 o’clock_
-  Es la una : _It is one o’clock_

Si la hora no es exacta, sólo se contesta con _<el número cardinal de la hora>_ + _<el número cardinal de los minutos>_
- Es la una y media: _It’s one thirty._
- Son las 4:40: _It’s four fourty._
- Son las 9:20: _It’s nine twenty._

*******************************************************************************
## Unas expresiones útiles

- de la mañana: _in the morning_
- de la tarde: _in the afternoon/in the evening_
- de la noche: _in the night_
- AM (ey-em) - medianoche a mediodía
- PM (pi-em)- mediodía a medianoche
- mediodía: _noon_
- medianoche: _midnight_ (Taylor Swift lanzó « Midnights »)

Cuando usamos las expresiones _in the morning_, _in the afternoon/evening_, _in the night_ o _AM/PM_ con
horas exactas, no es necesario incluir _o'clock_. Por ejemplo, la frase «Son las 3 de la mañana» se pedue traducir como _It is three in the morning_ o _It is three o'clock in the morning_; son iguales.

### Unos ejemplos
| Frase | Traducción |
|----|----|
| Son las 6 de la mañana | It is six AM <br/> It is six o'clock in the morning <br/> It is six in the morning|
| Son las 4 de la tarde | It is four PM <br/> It is four in the afternoon <br/> It is four o'clock in the afternoon <br/> It is four in the evening <br/> It is four o'clock in the evening |
| Son las 10 de la noche | It is ten PM <br/> It is ten in the night. <br/> It is ten o'clock in the night|
| Son las 9:38 de la mañana| It is nine thirty-eight AM <br/> It is nine thirty-eight in the morning |
| Son las 5:28 de la tarde | It is five twenty-eight PM <br/> It is five twenty-eight in the afternoon <br/> It is five twenty-eight in the evening |


*******************************************************************************
## Más vocabulario

| Event | _Evento_ |
| ----| -----|
| appointment | _cita_ | 
| movie | _película_ |
| class | _clase_ |
| meeting | _reunión_ |
| party | _fiesta_ |
| TV show | _serie/programa_ |
| concert | _concierto_ |


Ejemplos
- Tengo una cita a las 4: I have an appointment at four o’clock
- El programa comienza a las seis y media: The TV show starts at six thirty.

__Con los días, usen « on »  y con las horas, usen « at ».__

### Práctica del libro

#### Unas frases
- I have an appointment with the doctor at one thirty on Tuesday
- I have a meeting with my friend on Friday at three thirty
- I have a class on Wednesday at eight thirty.
- [I am watching/I am going to watch] a movie on Saturday at seven thirty. / The movie starts _(la película comienza)_ at seven thirty on Saturday
- My friend has a party on Sunday at five in the afternoon.
- I am watching a TV show on Thursday at four thirty. _(veo un programa …)_/ I am going to watch a TV show on Thursday at 4:30. _(Voy a ver un programa …)_

#### Preguntas y respuestas

| Sentence | _Traducción_ |
| ----- | ----- |
| What time is the appointment? <br/> At one thirty | _¿A qué hora es la cita? <br/> A la 1:30_|
| What time is the class? <br/> At eight thirty | _¿A qué hora es la clase? <br/> A las 8:30_ |
| What time is the TV show? <br/> At four thirty | _¿A qué hora es el programa? <br/> A las 4:30_ |
| What time is the meeting?<br/> At three o'clock | _¿A qué hora es la reunión?<br/> A las 3_ |
| What time is the movie?<br/> At nine o'clock | _¿A qué hora es la película?<br/> A las 9_ |
| What time is the party? <br/>At five o'clock | _¿A qué hora es la fiesta? <br/> A las 5_ |



*******************************************************************************
### Unas horas especiales

- _quarter past_ = y cuarto (_quarter_ significa «cuarto»)
- _half past_ = y media (_half_ significa «mitad», y la «l» no se pronuncia)
- *It is &lt;minutes&gt; till &lt;hour&gt;* = faltan &lt;minutos&gt; para &lt;hora&gt;
- faltan &lt;minutes&gt; para finalizar = _&lt;minutes&gt; left / twenty &lt;minutes&gt; to go_

| Frase | Traducción |
| ----- | --------|
| _Son las ocho y cuarto_ | It's quarter past eight |
| _Son las seis y media_ | It is half past eight |
| _Son las nueve y diez_ | It is ten past nine |
| _Faltan cuarto para las ocho_ | It's quarter to/till eight |
| _Faltan cinco para las doce_ | It's five to/till twelve |
| _Faltan diez para las tres_ | It's ten to/till three |

*******************************************************************************

## Preguntas con *to be* /ser/estar

Se puede contestar con _yes, it is_ o _no, it is not (isn't)_.

#### Repaso de « to be » _(ser/estar)_
- I am
- You are
- She/He/It is
- We are
- They are

Contracciones
- isn’t = is not
- aren’t = are not

#### Unas frases
- Is your class at eleven o’clock? _(¿Tu clase es a las 11?)_
	- Yes, it is.
	- No, it isn’t = No, it is not.

- Is your shirt blue? _(¿Tu camisa es azul?)_
	- Yes, it is
	- No, it isn’t.

- Are your hands hurt? _(¿Tus manos están heridas?)_
    - Yes, they are. - Yes, my hands are hurt.
    - No, they aren’t./No, they are not. - No, my hands are not hurt/ No, my hands aren’t hurt.

- Are you hurt? _(¿Estás herido?)_
    - Yes, I am
    - No, I am not.

- Is your leg hurt? _(¿Tu pierna está herida?)_
    - Yes, it is.
    - No, it isn’t.

- I’m right! _(¡Tengo razón!)_
	- Yes, you are
	- No, you aren’t/are not

_Hurt_ en este caso sirve como adjetivo, no como verbo. Vean la diferencia:
- My hands hurt = _Me duelen las manos_
- My hands are hurt = _Mis manos están heridas_



*******************************************************************************
`

export default function Week09() {
	return (
		<div>
			<h1>Week 9 Lecture Notes (3/30)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.ingles.com/guia/como-decir-la-hora-en-ingles">Cómo decir la hora</a></li>
				</ul>
			</p>
		</div>
	)
}
