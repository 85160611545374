import React from 'react';
import { Link } from 'react-router-dom';

export default function MacPreferences() {
	return (
		<div>
			<h1>My Mac Setup</h1>
			<p>
				I really wanted my Mac setup to look as similar as possible to my <Link to="/blog/preferences">Linux setup</Link>.
				This became all the more important once I started using a Mac for work.
				Mac already comes with a lot of commonly used packages installed, like Git and Python, so that's why this list is a lot shorter than
				my Linux setup list.
				I'll add more things as I think of them!

			</p>
			<h2>Things to install</h2>
			<p>
				<ul>
					<li><a href="https://brew.sh/">Homebrew</a></li>
					<li>Neovim</li>
						<ul>
							<li><a href="https://github.com/junegunn/vim-plug">Vim Plug</a></li>
						</ul>
					<li>Kitty</li>
					<li>
						<span class="monospace">coreutils</span> so that I can use the GNU version
						of <span class="monospace">ls</span> rather than the BSD version
					</li>
					<li><span class="monospace">lsd</span> (a colored version of <span class="monospace">ls</span>)</li>
					<li>
						<span class="monospace">libqalculate</span> package for <span class="monospace">qalc</span>, a good terminal calculator
					</li>
					<li>
						A browser!
						<ul>

							<li> Firefox + yes, I have a <a href="https://color.firefox.com/?theme=XQAAAAJZAQAAAAAAAABBqYhm849SCia48_6EGccwS-xMDPrxiv6IJMc3or-IsxQFlQ3E_J4nlQbENFJf_KbIylsXoYWiRO8Ch9iUNdBj4uqDLxnvtNdHwfW_lJ33-NZuQXh8xf3Uzvrjfwimf35LnnyAXGUdt6F96U5Z-l0Vz55Ttcdb9E7OaFaAwEOSnVSI1EGhVKFIPH-xjoOWNisMuf0Ifs3sZ_9wCArgrwUSbofLMJWEk6bS5w7XQSoEnJv4JYDUaaqL2wtSIAfsfZTW9u87veFuz1E9oB8_oAPRc5P1ChQQ291Y45zh90CePAY-7_Rzm_9dl6AA">custom theme</a>. Here are some plugins I like: </li>
								<ul>
									<li>uBlock Origin</li>
									<li>Firefox Color</li>
									<li>Bypass Paywalls Clean</li>
									<li>HTTPS Everywhere</li>
									<li>Canvas Blocker</li>
									<li>DuckDuckGo Privacy Essentials</li>
									<li>Vimium</li>
									<li>Tree Style Tab</li>
								</ul>
							<li>Chrome for my work laptop. Here are some extensions I like:</li>
								<ul>
									<li>uBlock Origin</li>
									<li>Canvas Blocker</li>
									<li>DuckDuckGo Privacy Essentials</li>
									<li>Vimium</li>
									<li>Group Tabs By Domain</li>
									<li>
										<a href="https://chrome.google.com/webstore/detail/under-the-leaf/ighhdikapddjihgaphbnbcgipafcamjk?hl=en-US">Under
										The Leaf</a> theme, by Marisol Ortega
									</li>
								</ul>
						</ul>
					</li>
				</ul>
			</p>
			<h2>Todo list (other things I like) </h2>
			<p>
				<ul>
					<li>
						Get your <span class="monospace">.zshrc</span> and aliases over!
						Same for a <span class="monospace">.vimrc</span>, any Vim colorschemes
						(these go in <span class="monospace">~/.vim/colors/</span> ), and 
						like 3 lines in <span class="monospace">~/.config/nvim/init.vim</span>.
					</li>
					<li>
						Speaking of a <span class="monospace">.zshrc</span>, you'll need to add <span class="monospace">alias ls='gls'</span>
						at the top of your aliases file, before the other <span class="monospace">ls</span> aliases in order to get
						the <span class="monospace">LS_COLORS</span> variable working.
					</li>
					<li>
						In the <span class="monospace">.zshrc</span>, change the value
						of <span class="monospace">PS1</span> to <span class="monospace">%F&#123;green&#125;>%f </span> (this is because I migrate
						from Bash to Zsh)
					</li>
					<li>
						Install the Ubuntu Mono Ligaturized fonts. Within the Font Manager, make sure all the font styles are activated.
						On Macs, for some reason, 12pt font is a little too small for me, so I like making it 14pt (changed in the kitty config file).
					</li>
					<li>I like to set my desktop color to a relaxing dark green, <span class="monospace">#002E20</span></li>
					<li>
						Just to have it somewhere, here's my Slack theme:
						<div class="realcode">#1F2E29,#1A2B23,#79F79D,#1A1D21,#1A2B23,#00FFB7,#4EE077,#1C913D,#1A2B23,#4EE077</div>
					</li>
					<li>
						Here's my Linear theme as well!
						<div class="realcode">lch(18.5% 17.82 171.751),#edeff0,lch(33% 58.74 291.6),#edeff0,lch(45.5% 78.54 178.2),#fefffe</div>
					</li>
					<li>
						To enable Git autocomplete in Zsh, the following line to your <span class="monospace">.zshrc</span>
						<div class="realcode">
							autoload -Uz compinit && compinit
						</div>
					</li>

				</ul>
			</p>
		</div>
	)
}
