import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const text = 
`
Canción del día: Stitches, by Shawn Mendes\\
Lo que vamos a cubrir: los temas E y F del primer capítulo, repasar el capítulo

*******************************************************************************
## Actividades del texto:

primero: first\\
último: last\\
nombre: name\\
teléfono: phone\\
número: number\\
código: code\\
área: area

Her first name is Linda. \\
Her last name is Wong. \\
Her area code is 916.\\
Her phone number is 555-7834.\\
She is from China.

### Los meses del año - the months of the year
Recuerden que en inglés, hay que escribir los meses en mayúscula

enero: January\\
febrero: February\\
marzo: March\\
abril: April\\
mayo: May\\
junio: June\\
julio: July\\
agosto: August\\
septiembre: September\\
octubre: October\\
noviembre: November\\
diciembre: December

78702, 78723 &rarr; ZIP codes (código postal)\\
512, 737 &rarr; area codes

mes: month\\
country: país
*******************************************************************************
Para decir la fecha: <mes> <un número ordinal>

### Los números ordinales - ordinal numbers

primero: first: 1<sup>st</sup>\\
segundo: second: 2<sup>nd</sup>\\
tercero: third: 3<sup>rd</sup>\\
cuarto: fourth: 4<sup>th</sup>\\
quinto: fifth: 5<sup>th</sup>\\
sexto: sixth: 6<sup>th</sup>\\
séptimo: seventh: 7<sup>th</sup>\\
octavo: eighth: 8<sup>th</sup>\\
noveno: ninth: 9<sup>th</sup>\\
décimo: tenth: 10<sup>th</sup>

Hay que agregar el sufijo _-th_ después del número, a menos que sea irregular,
como "first", "second" o "third", en cuyo caso usen los sufijos _-st_, _-nd_ o _-rd_
respectivamente.

11<sup>o</sup>: eleventh\\
12<sup>o</sup>: twelfth\\
13<sup>o</sup>: thirteenth\\
14<sup>o</sup>: fourteenth\\
15<sup>o</sup>: fifteenth\\
16<sup>o</sup>: sixteenth\\
17<sup>o</sup>: seventeenth\\
18<sup>o</sup>: eighteenth\\
19<sup>o</sup>: nineteenth

20<sup>o</sup>: twentieth\\
21<sup>o</sup>: twenty first: 21<sup>st</sup>\\
22<sup>o</sup>: twenty second: 22<sup>nd</sup>\\
23<sup>o</sup>: twenty third: 23<sup>rd</sup>\\
24<sup>o</sup>: twenty fourth: 24<sup>th</sup>\\
25<sup>o</sup>: twenty fifth\\
26<sup>o</sup>: twenty sixth\\
27<sup>o</sup>: twenty seventh\\
28<sup>o</sup>: twenty eighth\\
29<sup>o</sup>: twenty ninth

30<sup>o</sup>: thirtieth - 30 &rarr; thirty \\
31<sup>o</sup>: thirty first \\
32<sup>o</sup>: thirty second

El cumpleaños de mi amigo es el 6 de agosto. \\
My friend's birthday is August 6<sup>th</sup> (sixth). 

Hoy es el 9 de febrero.\\
Today is February 9<sup>th</sup> (ninth).

23 de octubre &rarr; October 23<sup>rd</sup> (twenty third) \\
30 de abril &rarr; April 30<sup>th</sup> (thirtieth)\\
18 de julio &rarr; July 18<sup>th</sup> (eighteenth)\\
12 de junio &rarr; June 12<sup>th</sup> (twelfth)

*******************************************************************************
### Years:

2013 &rarr; 20 15 &rarr; twenty thirteen \\
1995 &rarr; 19 95 &rarr; nineteen ninety-five\\
1999 &rarr; 19 99 &rarr; nineteen ninety-nine\\
1990 &rarr; 19 90 &rarr; nineteen ninety

June 15, 2015 \\
June 15<sup>th</sup> (fifteenth), twenty fifteen

2000 &rarr; two thousand \\
2001 &rarr; two thousand one\\
2005 &rarr; two thousand five\\
...\\
2009 &rarr; two thousand nine

2010 &rarr; twenty ten\\
2011 &rarr; twenty eleven\\
2012 &rarr; twenty twelve\\
..

1505 &rarr; fifteen oh five\\
1609 &rarr; sixteen oh nine\\
1302 &rarr; fifteen oh two 

1900 &rarr; ninteen hundred \\
1800 &rarr; eighteen hundred\\
1200 &rarr; twelve hundred\\
1000 &rarr; one thousand

19000 &rarr; nineteen thousand

100 &rarr; hundred\\
1000 &rarr; thousand
*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 2 Lecture Notes (2/9)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.ingles.com/guia/los-numeros-ordinales-en-ingles">https://www.ingles.com/guia/los-numeros-ordinales-en-ingles</a></li>
					<li><a href="https://www.ingles.com/guia/como-dar-la-fecha-en-ingles">https://www.ingles.com/guia/como-dar-la-fecha-en-ingles</a></li>
				</ul>
			</p>
		</div>
	)
}
