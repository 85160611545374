import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
*******************************************************************************
Song of the day (_Canción del día_): _mine_ by Kelly Clarkson

La agenda de hoy:
- Comenzar la 2<sup>a</sup> unidad (secciones A y B)

*******************************************************************************
| _Palabra_ | Word |
| ---- | ----- |
_un libro_ | a book
_una silla_ | a chair
_una computadora_ | a computer
_un escritorio_ | a desk
_un cuaderno_ | a notebook
_un lápiz_ | a pencil

## Los artículos indefinidos
- a/an: los artículos indefinidos
- a: si el sustantivo comienza en una consonante (el sonido de una consonante)
- an: si el sustantivo comienza en una vocal (el sonido es lo que cuenta)

Ejemplos:
- an apple (_una manzana_)
- an umbrella (_un paraguas_)
- a university - "yu"
- an heir
- an hour
- a historic event

Cosas que recordar
- Si la "u" se pronuncia como "yu", usen "a"
- El sonido al principio del sustantivo decide el artículo que elegimos

Como acabamos de ver con "u", la misma cosa sucede con "h"
- an heir
- a historic event

| _Palabra_ | Word |
| ---- | ----- |
_diccionario_ | a dictionary
_papel_ | paper
_una hoja de papel_ | a sheet of paper
_pluma_ | a pen
_borrador_ | an eraser
_grapadora_ | a stapler
_regla_ | a ruler

## Preposiciones
- in = _en_/_dentro de_
- on = _en_/_sobre_
- under = _debajo de_

Ejemplos
- The book is on the desk = _El libro está encima del escritorio_
- The book is in the desk = _El libro está dentro del escritorio_
- The book is under the desk = _El libro está debajo del escritorio_

*******************************************************************************
`

export default function Week01() {
	return (
		<div>
			<h1>Week 2 Lecture Notes (2/8)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://quizlet.com/84184442/stationary-spanish-flash-cards/">Quizlet on stationery</a></li>
					<li><a href="https://www.britishcouncil.org.mx/blog/preposiciones">Preposiciones en inglés</a></li>
					<li><a href="https://grammar.collinsdictionary.com/us/english-easylearning-in-spanish/cuando-se-usan-a-o-an-en-ingles">A/An</a></li>
				</ul>
			</p>
		</div>
	)
}
