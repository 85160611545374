import React from 'react';
import { Link } from 'react-router-dom';

export default function Teaching () {
	return (
		<div>
			<h1>ESL Lecture Notes</h1>
			<p>
				I'm teaching an introductory ESL course
				at <a href="https://elbuen.org/">El Buen Samaritano</a> during the spring 2023 session,
				and previously during the spring and summer 2021 sessions.
				<br/>
				<br/>
				Here are a set of lecture notes covering both
				things we've talked about in class as well as some additional examples.
				I'll be updating this page as the semester progresses.
				Hopefully this helps!
				<br/>
				<br/>
				<ul>
					<li>Week 1 (week of 1/31) - no class due to inclement weather</li>
					<li><Link to="/spring23/week02">Week 2 (week of 2/6)</Link></li>
					<li><Link to="/spring23/week03">Week 3 (week of 2/13)</Link></li>
					<li>Week 4 (week of 2/20) - substitute instructor</li>
					<li><Link to="/spring23/week05">Week 5 (week of 2/27)</Link></li>
					<li><Link to="/spring23/week06">Week 6 (week of 3/6)</Link></li>
					<li>Week 7 (week of 3/13) - no class, have a nice break!</li>
					<li><Link to="/spring23/week08-1">Week 8 (week of 3/20)</Link> - subbing in for Tuesday class</li>
					<li><Link to="/spring23/week08-2">Week 8 (week of 3/20)</Link></li>
					<li><Link to="/spring23/week09">Week 9 (week of 3/27)</Link></li>
					<li><Link to="/spring23/week10">Week 10 (week of 4/3)</Link></li>
					<li><Link to="/spring23/week11">Week 11 (week of 4/10)</Link></li>
					<li><Link to="/spring23/week12">Week 12 (week of 4/17)</Link></li>
					<li><Link to="/spring23/week13">Week 13 (week of 4/24)</Link></li>
					<li>Week 14 (week of 5/1) - Exam review! Good luck!</li>
				</ul>
			</p>


		</div>
	)
}
