import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Bad Habits_ by Ed Sheeran

La agenda de hoy:

- Repasar las primeras secciones de Unidad 3
- Hacer actividades en secciones E y F de Unidad 3

*******************************************************************************
## Repaso

| Word | _Traducción_ |
| ---- | ----- |
| baby | _bebé_ |
| babies | _bebés_ |
| man | _hombre_ |
| men | _hombres_ |
| woman (wu-muhn) | _mujer_ |
| women (wi-men) | _mujeres_ |
| son | _hijo_|
| daughter | _hija_ |
| grandfather | _abuelo_ |
| grandmother | _abuela_ |
| uncle | _tío_ |
| aunt | _tía_ |
| father | _padre_ |
| mother | _madre_ |
| brother | _hermano_ |
| sister | _hermana_ |
| boy | _chico_ |
| girl | _chica_ |
| teenager | _adolescente_ |
| wife | _esposa_ |
| husband | _esposo_ |

- ant = hormiga
- Tengo un hermano = I have a brother
- Tengo una tía = I have an aunt
- Tengo un tío = I have an uncle
- y -> ies (si la palabra termina en una "y", y la letra que precede la "y" es una consonante)

| Singular | Plural |
| -- | --- |
| family (familia) | families (familias) |
| boy (niño) | boys (niños) |
| strawberry (fresa) | strawberries (fresas) |
| monkey (mono) | monkeys (monos)| 
| child | children (hijos)|

| to have | _tener_ |
| --- | --- |
| I have | Yo tengo | 
| You have | Tú tienes/ Ud. tiene/ Uds. tienen| 
| He has | Él tiene | 
| She has | Ella tiene | 
| It has | tiene |
| We have | Nosotros tenemos |
| They have | Ellos tienen | 

- Él tiene un hijo = He has a son
- Ella tiene una hermana = She has a sister
- ¿Tienes una hija? = Do you have a daughter?
- Tienes una hija = You have a daughter

## Unas palabras opcionales 
| Word | _Traducción_ |
| ---- | ----- |
| nephew (nefyu)| _sobrino_ |
| niece (nis) | _sobrina_ |
| brother-in-law | _cuñado_ |
| sister-in-law | _cuñada_ |
| father-in-law | _suegro_ |
| mother-in-law | _suegra_ |
| son-in-law | _yerno_ |
| daughter-in-law | _nuera_ |
| in-laws | _suegros_ |
| stepfather | _padrastro_ |
| stepmother | _madrastra_ |
| stepbrother | _hijastro_ |
| stepsister | _hijastra_ |
| godfather | _padrino_ |
| godmother | _madrina_ |
| cousin | _primo/prima_ |
| son's/daughter's godmother | _comadre_ |
| son's/daughter's godfather | _copadre_ |
| son's/daughter's father-in-law | _consuegro_ |
| son's/daughter's mother-in-law | _consuegra_ |


- I'm at my in-laws' place = Estoy en la casa de mis suegros

*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 4 Lecture Notes (9/28)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.berlitz.com/es-us/blog/miembros-de-la-familia-en-ingles">Miembros de la familia</a></li>
					<li><a href="https://www.inglessencillo.com/el-plural">Formar el plural</a></li>
				</ul>
			</p>
		</div>
	)
}
