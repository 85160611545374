import React, { useState } from "react";
import './books.css';
let book_data = require('./books.json');

export default function Books(){
	const searchMagicNumber = "MAGICNUMBER"
	const [currTag, setCurrTag] = useState("")
	const [currSearch, setCurrSearch] = useState(searchMagicNumber)

	let genres = ["Adventure/Thriller",
		"Classics",
		"Fantasy",
		"Gothic",
		"History/Journalism",
		"Horror",
		"Humor",
		"Informational",
		"Letters and Essays",
		"Literary Fiction",
		"Memoir/Biography",
		"Mystery",
		"Romance",
		"Science Fiction",
		"Self Help",
		"Tech"]
	const getSeed = () => {
		const today = new Date();
		return today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate();
	};

	// Seedable random number generator (simple implementation)
	const seededRandom = (seed) => {
		const x = Math.sin(seed) * 10000;
		return x - Math.floor(x);
	};

	const handleTagClick = (e) => {
		let clickedTag = e.target.textContent
		if (currTag === clickedTag) {
			setCurrTag("")
		}
		else {
			setCurrTag(clickedTag)
		}
	}

	const handleSearch = (e) => {
		let searchInput = e.target.value.toLowerCase()
		console.log(searchInput)
		if (searchInput === "") {
			setCurrSearch(searchMagicNumber)
		}
		else {
			setCurrSearch(searchInput)
		}
	}

	// sorts by lastName, firstName and title
	const sorted = book_data.sort((a, b) => {
		if (a.lastName < b.lastName) {
			return -1;
		}
		else if (a.lastName > b.lastName) {
			return 1;
		}
		else {
			if (a.firstName < b.firstName) {
				return -1;
			}
			else if (a.firstName > b.firstName) {
				return 1;
			}
			else {
				if (a.title < b.title) {
					return -1;
				}
				else if (a.title > b.title) {
					return 1;
				}
				else {
					return 0;
				}

			}
		}
	});

	let fiction = sorted.filter(entry => entry["fiction"] === true)
	let nonfics = sorted.filter(entry => entry["fiction"] === false)


	let good_fiction = book_data.filter(entry => entry["fiction"] && entry["rating"] >= "3.5" && entry["title"])
	let good_nonfics = book_data.filter(entry => !entry["fiction"] && entry["rating"] >= "3.5")

	const seed = getSeed();
	let fic_rec = good_fiction[Math.floor(seededRandom(seed) * good_fiction.length)]
	let non_rec = good_nonfics[Math.floor(seededRandom(seed) * good_nonfics.length)]

	return (
		<div>
			<div className="books" style={{width: "100%", maxWidth: "850px", margin: "auto", padding: "15px 0px"}}>

				<h1 className="cursive">Boooooooooks</h1>
				<p>
					Welcome to my library of recent reads!
					Books I especially enjoyed are in bold, and each one has a rating out of five stars.
					Search by title or author, or browse genres using the buttons below!
					<br/>
					<br/>

					Books of the day (&ge; 3.5 stars)!
					<ul>
						<li> Fiction: <em>{fic_rec?.["title"]}</em> by {fic_rec?.["firstName"] + " " + fic_rec?.["lastName"]} </li>
						<li> Nonfiction: <em> {non_rec?.["title"]}</em> by {non_rec?.["firstName"] + " " + non_rec?.["lastName"]} </li>
					</ul>
					Happy reading!
				</p>

				<p style={{textAlign: "center"}}>
					<input type="text" id="search" onKeyUp={handleSearch} placeholder="Search for a book... 📚"/>

				</p>
					<ul>
					{sorted.filter(entry => entry["lastName"].toLowerCase().includes(currSearch) || entry["firstName"].toLowerCase().includes(currSearch)  || entry["title"].toLowerCase().includes(currSearch)).map(entry => 
						<li key={entry["title"] + entry["loved"]}>
							{entry["lastName"]}, {entry["firstName"]}: {' '}
							<span style={{ fontWeight: entry["loved"] ? "bold" : "normal" }}>
								<em>{entry["title"]}</em>&nbsp;
								{entry["additional"]}&nbsp;({entry["rating"]}&nbsp;★)
							</span> 
						</li>

						)}
					</ul>

				<div style={{width: "100%", textAlign: "center"}}>
				{genres.map(genre => <button key={genre} onClick={handleTagClick} className={currTag === genre ? "selected cursive" : "cursive"}>
					{genre}
				</button>)}
				</div>
				<div className="content">

					{fiction.filter(entry => entry["genre"].includes(currTag)).length > 0 && (
						<>
							<h3 className="cursive">Fiction</h3>
							<ul>
								{fiction.filter(entry => entry["genre"].includes(currTag)).map(entry => (
									<li key={entry["title"] + entry["loved"]}>
										{entry["lastName"]}, {entry["firstName"]}: {' '}
										<span style={{ fontWeight: entry["loved"] ? "bold" : "normal" }}>
											<em>{entry["title"]}</em>&nbsp;
											{entry["additional"]}&nbsp;({entry["rating"]}&nbsp;★)
										</span> 
									</li>
								))}
							</ul>
						</>
					)}
					{nonfics.filter(entry => entry["genre"].includes(currTag)).length > 0 && (
						<>
							<h3 className="cursive">Nonfiction</h3>
							<ul>
								{nonfics.filter(entry => entry["genre"].includes(currTag)).map(entry => (
									<li key={entry["title"] + entry["loved"]}>
										{entry["lastName"]}, {entry["firstName"]}: {' '}
										<span style={{ fontWeight: entry["loved"] ? "bold" : "normal" }}>
											<em>{entry["title"]}</em>&nbsp;
											{entry["additional"]}&nbsp;({entry["rating"]}&nbsp;★)
										</span> 
									</li>
								))}
							</ul>
						</>
					)}
				</div>

			</div>
		</div>
	);
}
