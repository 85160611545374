import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text =  `

Song of the day: no body, no crime, by Taylor Swift\\
Lo que vamos a cubrir: terminar la 8<sup>a</sup> unidad (temas E, F) , y comenzar la 9<sup>a</sup> unidad (temas A, B)

*******************************************************************************
## Unit 8

pronunciación: building (edificio) = bil-ding

Dear Grandpa: *Querido Abuelo*\\
How are you?: *¿Cómo estás?*\\
We are all well here.: *Nosotros estamos bien aquí*\\
Luis and María have new jobs: *Luis y María tienen nuevos trabajos*\\
Luis is a server: *Luis es mesero* \\
He serves food: *Él sirve comida*\\
María is a receptionist: *María es recepcionista*\\
She answers the phone: *Ella contesta el teléfono*\\
I’m a homemaker: *Soy un ama de casa*\\
I work at home: *Trabajo en casa*\\
Write soon: *Escribe pronto*

*******************************************************************************
## Unit 9


| Infinitive | Present progressive | _Traducción_| 
|----| ----| ----|
| to do homework | doing homework | _hacer tarea_ |
| to do the laundry | doing the laundry | _lavar la ropa_ |
| to dry the dishes | drying the dishes | _secar los platos_ |
| to make lunch | making lunch | _preparar el almuerzo_ | 
| to make the bed | making the bed | _arreglar la cama_ |
| to wash the dishes | washing the dishes | _lavar los platos_ |
| to cut the grass | cutting the grass | _cortar el pasto_ |
| to get the mail | getting the mail | _recoger el correo_ |
| to take out the trash | taking out the trash | _sacar la basura_ |
| to walk the dog | walking the dog | _pasear al perro_ |
| to wash the car | washing the car | _lavar el carro_ |
| to water the grass | watering the grass | _regar el pasto_ | 


- do, water, walk, write, - infinitives (infinitivos)
- doing, watering, walking, writing - participio presente

Ejemplo:
- Riego las plantas - I water the plants
- Estoy regando las plantas - I am watering the plants / I’m watering the plants

El presente progresivo:
- Estar + participio / « to be » + participle
- -ando/-iendo = -ing 

## Cómo formar los participios presentes

Típicamente, hay que agregar un sufijo de _-ing_
- look: looking
- walk: walking
- eat: eating

### Excepciones

Una « e » muda - hay que quitarla antes de añadir el sufijo
- write - writing
- make - making
- bike - biking

Si un verbo tiene una sílaba y termina en consonante-vocal-consonante - dupliquen la última letra
- run - running
- cut - cutting
- stop - stopping
- get - getting
- Excepciones - c, x, w, y
    - panic - panicking - agreguen una k

Verbos que terminan en « ie »: reemplacen « ie » con « y »
- lie: lying
- tie: tying


## Lección de gramática

- El infinitivo es la forma más sencilla de un verbo. En español, terminan con
*-ar, -er, o -ir* (como « saltar », « caer », y « sentir »). En inglés, empiezan con
«to» (como « to jump », « to fall » o « to feel »).
- Después de una preposición, siempre se usa el infinitivo
- Después de un verbo conjugado, se puede usar:
	- el infinitivo: *Quiero dormir → I want to sleep.*
	- un participio pasado: *He comido → I have eaten*. En español, estas formas siguen una conjugación del verbo « haber ». En inglés, siguen el verbo « to have ».
	- un participio presente: *Ella está durmiendo → She is sleeping.* En español, estas
formas siguen una conjugación del verbo « estar ». En inglés, siguen el
verbo « to be ».
- No se usa “to” con los participios.

### Unos ejemplos
_tener que + infinitivo_ = have to + infinitivo

_hacer la tarea_ = to do homework
- _Quiero hacer la tarea_ = I want to do homework
- _Hago la tarea_ = I do homework
- _Estoy haciendo la tarea_ = I am doing homework

_lavar el carro_ = to wash the car
- _Necesitas lavar el carro_ = You need to wash the car
- _Lavas el carro_ = You wash the car
- _Estás lavando el carro_ = You are washing the car

_preparar el almuerzo_ = to make lunch
- _Voy a preparar el almuerzo_ = I am going to make lunch
- _Preparo el almuerzo_ = I make lunch
- _Estoy preparando el almuerzo_ = I am making lunch

_pasear al perro_ = to walk the dog
- _Ellos quieren paear al perro_ = They want to walk the dog
- _Ellos pasean al perro_ = They walk the dog
- _Ellos están paseando al perro_ = They are walking the dog

_sacar la basura_ = to take out the trash
- _Ricardo tiene que sacar la basura_ = Ricardo has to take out the trash
- _Ricardo saca la basura_ = Ricardo takes out the trash
- _Ricardo está sacando la basura_ = Ricardo is taking out the trash

_regar las plantas_ = to water the plants
- _Ella quiere regar las plantas_ = She wants to water the plants
- _Ella riega las plantas_ = She waters the plants
- _Ella está regando las plantas_ = She is watering the plants

### Repaso de « to be » ser/estar

|Subject | Conjugation |
| ---|----|
|I | am|
|You| are|
|He |is|
|She |is|
|We| are|
|They| are|


### Más práctica
| _Frase_ | Traducción |
| ---- | -----| 
| _Estoy recogiendo el correo_ | I am getting the mail |
| _Estoy cortando el pasto_ | I am cutting the grass |
| _Ella está lavando la ropa_ | She is doing the laundry | 
| _¿Qué está haciendo él?_ | What is he doing? |
| _¿Qué está haciendo ella?_ | What is she doing? |
| _¿Qué están haciendo ellos?_ | What are they doing? |
| _¿Qué estamos haciendo nosotros?_ | What are we doing? |
| _Ella está regando el pasto_ | She is watering the grass |
| _Estás arreglando la cama_ | You are making the bed |
| _Ellos están lavando el carro_ | They are washing the car |
| _Él está lavando los platos_ | He is washing the dishes |
| _Estoy tirando la basura_ | I am taking out the trash | 
*******************************************************************************

`
export default function Week09() {
	return (
		<div>
			<h1>Week 12 Lecture Notes (4/20)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.ingles.com/guia/el-infinitivo-en-ingles">Los infinitivos</a></li>
					<li><a href="https://www.ingles.com/guia/el-participio-presente">Los participios presentes</a></li>
				</ul>
			</p>
		</div>
	)
}
