import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Francesca_ by Hozier

La agenda de hoy:

- Repasar las primeras secciones de Unidad 2
- Hacer actividades en secciones C y D de Unidad 2

*******************************************************************************

 | _Palabra_ | Word |
 | ---- | ----- |
 |_cuaderno_ | a notebook |
 |_libro_ | a book|
_borrador_ | an eraser
_computadora_ | a computer
_escritorio_ | a desk
_papel_ | paper
_una hoja de papel_ | a sheet of paper
_regla_ | a ruler
_diccionario_ | a dictionary
_grapadora_ | a stapler
_pluma_ | a pen
_silla_ | a chair
_lápiz_ | a pencil
_mesa_ | a table


### Unas preposiciones
- in = _en_/_dentro de_
- on = _en_/_sobre_
- under = _debajo de_

Ejemplos
- The book is on the desk = _El libro está encima del escritorio_
- The book is in the desk = _El libro está dentro del escritorio_
- The book is under the desk = _El libro está debajo del escritorio_


Where is/are:
- ¿Dónde está ...? = Where is ...? = Where's ...?
- ¿Dónde están ...? = Where are ...?



### Translation

| English | Spanish |
| --- | --- |
| It's Monday, your first day of English class! | Es el lunes, tu primer día de la clase de inglés.| 
| You need a pencil, eraser, notebook, and dictionary | Necesitas un lápiz, un borrador, un cuaderno y un diccionario
| The pencil is in the desk | El lápiz está dentro del escritorio |
| The eraser is on the desk | El borrador está sobre el escritorio |  
| The notebook is on my computer | El cuaderno está sobre mi computadora |
| And the dictionary is under the chair | Y el diccionario está debajo de la silla |
| Have fun at school! | ¡Diviértete en la escuela! 

divertirse = to have fun

- You have fun = tú te diviertes
- He has fun = él se divierte
- Did you have fun? = ¿Te divertiste?
	- Yes, I did
	- No, I didn't
- Are you having fun? = ¿Te estás divirtiendo? (literalmente)

*******************************************************************************
| Día | Day |
| --- | ---- |
| domingo | Sunday |
| lunes | Monday |
| martes | Tuesday |
| miércoles | Wednesday |
| jueves | Thursday |
| viernes | Friday |
| sábado | Saturday |

- What day is it?/What day is today? = ¿Qué día es hoy?
- Today is Tuesday
- The class is on Tuesday
- On Monday, I have to go to work = El lunes, tengo que ir al trabajo
- Siempre se escriben en mayúscula


Fechas
- Today is September 19<sup>th</sup>
- Today is Tuesday, September 19<sup>th</sup>
- Today is September 19
- Tomorrow is September 20<sup>th</sup>
- Tomorrow is the 20<sup>th</sup> of September
- July 4<sup>th</sup>
- Thursday, August 9<sup>th</sup>


Números ordinales
| Ordinal number | Forma corta | Número ordinal|
| --- | --- |  --- |
| first |1<sup>st</sup>  | primero|
second |2<sup>nd</sup>| segundo
 third |3<sup>rd</sup> | tercero
fourth |4<sup>th</sup> | cuarto
fifth | 5<sup>th</sup> | quinto
sixth |6<sup>th </sup> | sexto
seventh | 7<sup>th</sup> | séptimo
eighth | 8<sup>th</sup> | octavo
ninth | 9<sup>th</sup> | noveno
tenth | 10<sup>th</sup> | décimo
eleventh | 11<sup>th</sup>  | undécimo |  
twelfth | 12<sup>th</sup>  | duodécimo |  
thirteenth | 13<sup>th</sup>  | decimotercero |  
fourteenth | 14<sup>th</sup>  | decimocuarto |  
fifteenth | 15<sup>th</sup>  | decimoquinto |  
sixteenth | 16<sup>th</sup>  | decimosexto |  
seventeenth | 17<sup>th</sup>  | decimoséptimo |  
eighteenth | 18<sup>th</sup>  | decimoctavo |  
ninteenth | 19<sup>th</sup>  | decimonoveno |  
twentieth | 20<sup>th</sup>  | vigésimo |  
twenty-first | 21<sup>st</sup> | vigésimo primero | 
twenty-second | 22<sup>nd</sup> | vigésimo segundo | 
twenty-third | 23<sup>rd</sup> | vigésimo tercero | 
twenty-fourth | 24<sup>th</sup> | vigésimo cuarto | 
twenty-fifth | 25<sup>th</sup> | vigésimo quinto | 
twenty-sixth | 26<sup>th</sup> | vigésimo sexto | 
twenty-seventh | 27<sup>th</sup> | vigésimo séptimo | 
twenty-eighth | 28<sup>th</sup> | vigésimo octavo | 
twenty-ninth | 29<sup>th</sup> | vigésimo noveno| 
thirtieth | 30<sup>th</sup> | trigésimo | 
thirty-first | 31<sup>st</sup> | trigésimo primero | 


## Answers
- Sunday, March 12<sup>th</sup>
- Monday, March 13<sup>th</sup>
- Tuesday, March 14<sup>th</sup>
- Wednesday, March 15<sup>th</sup>
- Thursday, March 16<sup>th</sup>
- Friday, March 17<sup>th</sup>
- Saturday, March 18<sup>th</sup>


*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 3 Lecture Notes (9/19)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
