import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
*******************************************************************************
Song of the day (_Canción del día_): _Innocent_ by Taylor Swift

- to do, to water, to walk, to write, - infinitives (infinitivos)
- doing, watering, walking, writing - participio presente

| Infinitive | Present progressive | _Traducción_| 
|----| ----| ----|
| to do homework | doing homework | _hacer tarea_ |
| to do the laundry | doing the laundry | _lavar la ropa_ |
| to dry the dishes | drying the dishes | _secar los platos_ |
| to make lunch | making lunch | _preparar el almuerzo_ | 
| to make the bed | making the bed | _arreglar la cama_ |
| to wash the dishes | washing the dishes | _lavar los platos_ |
| to cut the grass | cutting the grass | _cortar el pasto_ |
| to get the mail | getting the mail | _recoger el correo_ |
| to take out the trash | taking out the trash | _sacar la basura_ |
| to walk the dog | walking the dog | _pasear al perro_ |
| to wash the car | washing the car | _lavar el carro_ |
| to water the grass | watering the grass | _regar el pasto_ | 

- I do my homework = _Hago mi tarea_
- I am doing my homework = _Estoy haciendo mi tarea_
- She is watering the grass
- He is getting the mail
- They are washing the car

-----------
El presente progresivo:

- Estar + participio / « to be » + participle
- -ando/-iendo = -ing 


## Cómo formar los participios presentes

Típicamente, hay que agregar un sufijo de _-ing_

- look: looking
- walk: walking
- eat: eating

### Excepciones

Una « e » muda - hay que quitarla antes de añadir el sufijo

- write - writing
- make - making
- bike - biking

Si un verbo tiene una sílaba y termina en consonante-vocal-consonante - dupliquen la última letra

- run - running
- cut - cutting
- stop - stopping
- get - getting
- Excepciones - si la palabra termina en c, x, w, y - solamente agreguen el sufijo

<!--     - panic - panicking - agreguen una k -->

Verbos que terminan en « ie »: reemplacen « ie » con « y »

- lie: lying
- tie: tying

### Práctica

#### Repaso de « to be » ser/estar

|Subject | Conjugation |
| ---|----|
|I | am|
|You| are|
|He |is|
|She |is|
|We| are|
|They| are|


1. Ella está recogiendo el correo: She is getting the mail
2. Él está lavando el carro: He is washing the car
3. Tú estás secando los platos: You are drying the dishes
4. Ellos están preparando el almuerzo: They are making lunch
5. Nosotros estamos arreglando la cama: We are making the bed
6. Estoy paseando al perro: I am walking the dog
7. Ella está lavando la ropa: She is doing the laundry
8. Tú estás cortando el pasto: You are cutting the grass
9. Ellos están regando el pasto: They are watering the grass
10. Estoy haciendo la tarea: I am doing homework
11. Él está sacando la basura: He is taking out the trash
12. Nosotros estamos lavando los platos: We are washing the dishes

- What is he/she doing? = ¿Qué está haciendo él/ella?
- What are you doing? = ¿Qué estás haciendo/qué está haciendo Ud./qué están haciendo Uds.?
- What are they doing? = ¿Qué están haciendo ellos?

*******************************************************************************
`


export default function Week05() {
	return (
		<div>
			<h1>Week 7 Lecture Notes (7/18)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,

		</div>
	)
}
