
import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

- Song of the day (_Canción del día_): _All Too Well_ by Taylor Swift
	- Su nuevo álbum se lanzará esta noche :) (The Tortured Poets Department)
- La agenda de hoy:
	- Terminar Unidad 8 y repasarla

*******************************************************************************
| Profesión | Profession |
| ---- | -----|
| _vendedor_ | salesperson |
| _mecánico_ | mechanic |
| _mesero_ | server |
| _recepcionista_ | receptionist |
| _cajero_ | cashier | 
| _plomero_ | plumber |
| _conductor de autobús_ | bus driver |
| _pintor_ | painter |
| _ama de casa_ | homemaker |
| _ayudante del profesor_ | teacher's aide |
| _conserje_ | custodian |

| Sentence | _Traducción_ | Profession |
| ---- | ---- | ---- |
| She answers the phone | _Ella contesta el teléfono_ | receptionist|
| She counts money | _Ella cuenta dinero_ | cashier |
| He fixes cars | _Él arregla carros_ | mechanic |
| He cleans buildings | _Él limpia edificios_ | custodian |
| She sells clothes | _Ella vende ropa_ | salesperson |
| He serves food | _Él sirve comida_ | server |

- borrow/lend
- lend = prestar
- Ella me presta un libro = She lends me a book
	- I borrow a book from her
- He lends me money = Él me presta dinero
	- I borrow money from him
*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 10 Lecture Notes (4/18)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
