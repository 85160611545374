import React from 'react';

export default function Practices () {
	return (
		<div>
			<h1>Some Good Practices</h1>

			<p>
				<b>Don't be me.</b>
				<br/>
				<br/>
				Here's another list! This covers
				<ul>
					<li>things I wish someone had told me earlier</li>
					<li>things people told me that I unfortunately ignored</li>
				</ul>
			</p>

			<h2>Back up your stuff. </h2>
			<p>
				You'll need a place to back up to, and optionally a script/tool to help you/a reminder.
				<br/>
				<br/>
				I like compressing my files before backing up,
				but that's optional. There are utilities (I know Ubuntu has
				one by default) to do this for you, if you like!
				I highly recommend having a script/cronjob to do this for
				you, or using a built-in tool.
				If you don't have this automated, you might want to set up a reminder to do this regularly!
				<br/>
				<br/>
				On a side note, also back up before you do anything major, like
				partitioning your drive.
				<br/>
				<br/>
				The pain and anxiety you'll feel when you've lost something important
				isn't worth it at all. Please help yourself.
			</p>


			<h2>Keep your files organized and give them useful names</h2>
			<p>

				Concerning most non-hidden files in my home directory,
				my rule of thumb is that I should just know the contents of a file given its location and name.
			</p>
			<p>
				I know it's tempting to name
				files <span class="monospace">temp</span>, <span class="monospace">test</span>,
				and <span class="monospace">foo</span>, but that scheme isn't doing you
				any favors.
			</p>
			<p>
				Go ahead and get in the habit of naming things in a Linux-compatible way.
				Don't include spaces in your file/directory names; it'll make
				any CLI thing with Linux painful. This bit comes back to the file organization concept,
				have a uniform way of dealing with natural spaces between words. Some folks like
				camelCase, others like kebab-case, and others prefer snake_case.
			</p>
			<p>
				Unfortunately, there's not a one-ring-to-rule-them-all
				solution: camelCase doesn't work in React file naming, we can't use hyphens in Java identifiers,
				and you might have to escape certain files if they contain underscores in certain
				languages (LaTeX does this, for one).
				Find a way that works for you, though, given that it'll probably change depending on
				what you're doing.
			</p>
			<p>
				Begin your names with a lowercase character; no one has the energy to hit
				the <i>Shift</i> key every time (tabbing will take you through the rest!).
			</p>



			<h2>Do your updates and upgrades</h2>
			<p>
				They're good for your computer (and you).
				Do 'em regularly (set up a cronjob or at least a reminder). :)
			</p>
			<p>
				I have a quick script that does this for me!
			</p>
			<div class="realcode">
				#!/bin/bash <br/>
				FILENAME=&lt;path_to_file&gt; <br/>
				<br/>
				echo `date '+%a %d %b %Y %T %Z'` &gt;&gt; $FILENAME <br/>
				echo '    started' &gt;&gt; $FILENAME <br/>
				sudo pacman -Syu<br/>
				sudo pacman -Qdtq | sudo pacman -Rs - <br/>
				echo '    exited' &gt;&gt; $FILENAME <br/>
			</div>


			<h2>Before you install something (package, repo, ppa), find out how to uninstall it. </h2>
			<p>
				The docs are usually a great place to go for this! Also, you might be able to message the
				developer sometimes if you need something.
			</p>


			<h2>The ArchWikis are golden</h2>
			<p>
				That, combined with forums and the official docs are your best friends in debugging.
				More often than not, the ArchWikis have a copy-pastable solution to whatever
				issue I'm having, no matter which distro I'm working with.
			</p>

			<h2>Make a copy of anything you're editing</h2>
			<p>
				If you're making significant changes to an important file,
				make a copy of that file and name appropriately.
				For example, you could copy
				the file <span class="monospace">.bashrc</span> to <span class="monospace">.old_bashrc</span>,
				and then make all the changes/test to the actual file without too much fear. The
				file <span class="monospace">.old_bashrc</span> won't be executed due its name.
			</p>

			<h2>Test your scripts before running them for real</h2>
			<p>
				Please, please, test your scripts on a set of representative files before running them
				on the real deal.  This <em>especially</em> holds true with commands
				such as <span class="monospace">sed</span> and recursive things.
				Ask me about "wtypeype" if you want to hear about how I corrupted 40+ GB worth of files
				with an infinitely recursing script on November 30, 2018.
			</p>

			<h2>Version control</h2>
			<p>
				Git is probably the most common, and it's really easy to use. Please track your versions and commits
				with <em>meaningful</em> names; future-you will thank present-you when your code suddenly stops working.
			</p>

			<h2>Try to edit sensitive files as little as possible</h2>
			<p>
				You know how you're supposed to use <span class='monospace'>visudo</span> to edit the sudoers list,
				or run <span class="monospace">crontab -e</span> instead of editing the file directly? Try to
				do the same thing as much as you can. Most of the time, these utilities tell you immediately
				if you've made a mistake in configuration, and they won't accept an incorrect configuration.
				This way, you won't be stuck with an impossible-to-debug situation,
				or worse, a brick.
			</p>

			<h2>Ask for help early and often</h2>
			<p>
				I'd recommend setting a defined amount of time after which you have to ask for help;
				this could be posting on Reddit or one of the forums, 
				asking a friend, or anything else you like.
				If you've already spent a fair amount of time without making any progress, 
				you're probably either looking
				at the problem wrong, or looking for answers in the wrong place. Either way, asking for
				help will save you a fair amount of energy and needless frustration.
			</p>

			<h2>Remember to have fun!</h2>
			<p>
				Most of exploring computers (at least as a hobby) is supposed to be interesting and fun.
				If it ever gets so stressful to where it no longer feels like it's that fun, or you're exclusively
				doing things that don't mean anything to you, you might want to reevaluate where you're heading.
				You'll want to make sure you're actually interested in testing out a tool, rather than testing
				it out just to say you've done it.
				<br/>
				<br/>
				It's a lot more difficult and confusing to learn about and configure a new operating
				system or a tiling environment when you have an exam in two days. Odds are, you'll wind up copying
				someone's default config without even understanding what they're doing if you're that stressed.
				Allotting enough time to learn about the different tools you have at your disposal and their use
				cases makes the learning experience significantly more enjoyable.
			</p>

			<h2>Privacy Tips</h2>
			<p>
				I thought I'd add a section on privacy, since it wasn't something I took seriously for a while.
				Here are a few things I like to do in that regard!
			</p>

			<p>
				<ul>
					<li className="space">
						Use DuckDuckGo as your default search engine! I've used it for years, and I really enjoy it.
						You can quickly access Google based tools and other popular websites
						using <a href='https://duckduckgo.com/bang'>bangs</a>, which is a really nice convenience!
						Other search engines also use previous search history to give you future results, which
						can lead you into an echo chamber or a filter bubble.
						Also, DDG doesn't track you! I've
						noticed comparable search result quality; the transition isn't bad at all.
					</li>
					<li className="space">
						Speaking of trackers, you can't block a site's own trackers - e.g., Google's
						trackers on Gmail or YouTube. Moving to a more privacy focused environment in general,
						such as by using Proton Mail or Tutanota for your mail helps you avoid these trackers
						and allow you a greater degree of privacy. DDG has also débuted a email protection
						service that removes email trackers from your mail and then forwards it onto you!
					</li>
					<li className="space">
						Our calendars also give out a good amount of data about our lives, so I prefer
						to keep those private too! Once again, Proton has a great calendar service, and I
						believe Tutanota does too! They offer a perfectly usable free tier as well as a paid plan.
						I'm also on the lookout for a privacy focused, E2E to-do list app - I currently
						use Todoist, but it's not very private. Let me know if you know of any!
					</li>
					<li className="space">
						I like using privacy-focused browsers such as Firefox, DDG (on my phone), and Tor.
						Firefox (Chrome too, but I don't use Chrome) has a whole suite of add-ons that
						can help with privacy. I absolutely recommend uBlock Origin, DDG, and HTTPS Everywhere.
						uBlock and DDG block trackers and HTTPS Everywhere ensures that your data (not all of it, 
						though) is encrypted. 
					</li>
					<li className="space">
						Encrypt your hard drive. If someone has physical access to your machine, they can
						access your files pretty easily if the drive isn't encrypted. Encryption properly protects your
						filesystem, since file deletion just modifies filesystem pointers.
					</li>
					<li className="space">
						I like communicating with people largely (not exclusively) though
						media that are end-to-end encrypted, like Signal. It's free and open-source,
						which is what we'd want to look for in security apps - i.e., loads of people
						have vetted the security and have had the opportunity to look for holes.
					</li>
					<li className="space">
						Using a password manager is really important! I'm currently
						using the free version of Bitwarden (open source, cross platform), and I'm really
						liking it. Previously, I've also tried Proton Pass and 1Password.
						Proton Pass is still relatively new, so I wasn't a huge fan of it. It's autofill
						capacities are really limited, which I didn't really enjoy.
						Unfortunately, 1Password doesn't have a free tier.
						Proton's development in general is quite promising, so I'm optimistic about
						this service's improvement in the near future!
						In terms of feature parity, 1Password's probably the most similar to Bitwarden.
						If it had a free version, I'd definitely consider using it! But for now,
						Bitwarden seems to be what I've best liked.
					</li>
					<li className="space">
						I also really like using Standard Notes (open source, e2e, cloud-synced) for a
						lot of quick note-taking!
						I've been using as a replacement for the iOS Notes app, and it has some of
						the features the Notes app lacks:
							<ul>
								<li>I can easily access and modify notes on my Linux machine (as well
								as phone and Mac). This means I'm less tied to the Apple ecosystem.</li>
								<li>It supports Markdown! (paid version, but I use the student discount).
									Most apps I use support MD (Slack, Discord, MS Teams, Notion, etc.),
									so it's really weird using an app that doesn't support it.
								</li>
							</ul>
					</li>
					<li className="space">
						I'd also recommend using a VPN! I'm currently using ProtonVPN, since I already
						use Proton's mail and calendar services. It's a pretty easy way to hide
						some of your internet activity from your ISP! I'd suggest using one that <a href="https://protonvpn.com/blog/no-logs-audit/">logs very little of your activity</a>.

					</li>
				</ul>
			</p>
					{
						/*
						 *	Hmf.
	- document ur shit (at least for other ppl)
		- going off (past-me would have done sth is not great)
	- take time to learn
	- also take time to have fun while learning
		- if the learning is more painful than fun, reevaluate
						*/
					}

		</div>
	)
}


