import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
*******************************************************************************
Song of the day (_Canción del día_): _Who We Are_ by Hozier

La agenda de hoy:

- Repasar las primeras secciones de Unidad 1
- Hacer actividades en secciones C y D de Unidad 1


------------

| Country | _País_ |
| ---- | ---- |
| the United States | _los Estados Unidos_ | 
| Mexico | _México_ |
| Haiti | _Haití_ |
| Brazil | _Brazil_ |
| Russia | _Rusia_ |
| Somalia | _Somalia_ |
| Vietnam | _Vietnam_ |
| China | _China_ |

Unas preguntas:

- Where are you from? = _¿De dónde eres?_
- I am from ... = _Soy de ..._

| Word | _Palabra_ |
| --- | --- |
| name | _nombre_ |
| first name | _nombre de pila_ |
| last name | _apellido_ |
| area code (512, 737)| _código de área_ |
| ZIP code (78723, 78705)| _código postal_|
| phone number | _número de teléfono_|
| signature | _firma_ | 
| sign | _firmar_ |

| to be | _ser/estar_ |
| ---- | --- |
| I am | _yo soy/estoy_ |
| You are | _Tú eres/estás_ | 
| He is | _Él es/está_ |
| She is | _Ella es/está_ |
| It is | _Es/está_ |
| We are | _Nosotros somos/estamos_ | 
| They are | _Ellos son/están_ |




Preguntas:

- Where is he from? = _¿De dónde es él?_
- Where is she from? = _¿De dónde es ella?_
- Where are they from? = _¿De dónde son ellos?_

Respuestas:

- She is from Russia.
- He is from China.
- We are from Mexico.

---------------------

- What's your name? = _¿Cómo te llamas?_

| Possessive pronouns | _pronombres posesivos_ |
| ---- | ---- |
| my | _mi/mis_ |
| your | _tu/tus_ |
| his | _su (de él)_ |
| her | _su (de ella)_ |
| our | _nuestro/nuestros_ |
| its | _su_ |
| their | _su (de ellos)_ |

### Unas frases
- His name is ... = _Él se llama ... _
- Her name is ... = _Ella se llama ... _
- Our area code is 512. = _Nuestro código de área es 512_
- His last name is Jackson = _Su apellido es Jackson (de él)_ 
- Her ZIP code is 78705 = _Su código postal es 78705 (de ella)_
- What's (What is) your last name? = _¿Cuál es tu apellido?_
- What's your area code? = _¿Cuál es tu código de área?_
- area = eiria (fonético)

| _Mes_ | Month |
| ---- | ----- |
| _enero_| January |
| _febrero_ | February |
| _marzo_ | March |
| _abril_ | April |
| _mayo_ | May | 
| _junio_ | June |
| _julio_ | July |
| _agosto_ | August | 
| _septiembre_ | September |
| _octubre_ | October |
| _noviembre_ | November |
| _diciembre_ | December | 

### Birthdays
- When is (When's) your birthday? =  _¿Cuándo es tu cumpleaños?_
- My birthday is in MONTH = _Mi cumpleaños es en MES_
- His birthday is in February.
- Her birthday is in April.
*******************************************************************************
`

export default function Week01() {
	return (
		<div>
			<h1>Week 1 Lecture Notes (9/7)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://www.ingles.com/guia/como-dar-la-fecha-en-ingles">https://www.ingles.com/guia/como-dar-la-fecha-en-ingles</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
