import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
Song of the day (_Canción del día_): _Anti-Hero_ by Taylor Swift\\
Lo que vamos a cubrir: terminar la 5a unidad (temas E, F)\\
Recordatorio: Examen: disponible hoy, fecha límite - el domingo a la medianoche (primeras 5 unidades) \\

*******************************************************************************
## Los lugares (Places)

|Español | English |
|:-----|:-----|
|banco| bank|
|biblioteca| library (pronunciación: la «i» se pronuncia como «ai» - piénsenlo como lai-bre-ri)|
|librería| bookstore/bookshop|
|restaurante| restaurant (pronunciación: rest-rant)|
|tienda| store/shop|
|escuela| school|
|colegio/preparatoria| high school|
|calle| street|
|supermercado| supermarket|
|peluquería| barbershop/salon|
|panadería| bakery|

*******************************************************************************
## Más lugares 

|English | Español |
|:-----|:-----|
|pharmacy| farmacia|
|hospital| hospital|
|laundromat| lavandería|
|post office| oficina de correo|
|movie theater| cine|
|gas station| gasolinera|

*******************************************************************************
## Modes of transportation

|English | Español |
|:-----|:-----|
|bicycle| bicicleta|
|taxi| taxi |
|on foot | a pie|
|car | carro |
|bus | bus |
|train | tren|

*******************************************************************************
`

export default function Week06() {
	return (
		<div>
			<h1>Week 4 Lecture Notes (6/29)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://quizlet.com/2127031/lugares-places-flash-cards/">Quizlet con unas palabras de vocabulario</a></li>
				</ul>
			</p>
		</div>
	)
}
