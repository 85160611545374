
export default function Terminal () {
	return (
		<div>
			<h1>Terminal Emulator Reviews</h1>

			<p>
				I've tried a few terminal emulators, and I wanted to jot down
				my experiences as well as my likes and dislikes. I've only tested ones compatible
				with Linux and MacOS thus far. 
			</p>

			<h2>Linux only</h2>

			<h3>GNOME Terminal (default on Ubuntu) </h3>
			<p>
				This was probably the first terminal emulator I'd ever really used, so it has
				a special place in my heart. Like most things GNOME, most, if not all of the
				customization is done through a GUI. It's not particularly customizable, but it'll
				suffice for most users that don't live on a terminal. Having a GUI menu is pretty
				nice for quick customization, but the config exports quite poorly. However, I don't expect
				most users to really want to export their GNOME config to another system, so I'm guessing
				this works fine as a default for most users. 
			</p>
			<p>
				Unfortunately, GNOME Terminal doesn't
				support multiplexing; you'll have to use something like tmux, which I never really
				got used to; the keybindings are a bit long to type and unintuitive.
				For example, to split the screen vertically, 
				you'd have to type <i>Ctrl</i> + <i>b</i> + <i>Shift</i> + <i>5</i> (the
				last two just form the "%" character); I'm not sure any part of that is intuitive.
				This brings me to the next
				entry on our list, Terminator.
			</p>

			<h3>Terminator</h3>
			<p>
				This has probably been the emulator I've used for the longest amount of time!
				Multiplexing is a really big deal for me, and I feel like Terminator makes it really,
				really easy to do! Being able to split with a right-click and a tap is pretty nice;
				I feel like it's a bit more intuitive and faster than tmux keybindings. You can
				also drag to resize windows, which is really easy to do. I feel like learning
				new keybindings does come at a great advantage, but I'm often too lazy to go do that.
			</p>
			<p>
				Terminator offers most of the customization people wind up wanting in a terminal
				emulator - it lets you change default keybindings, palettes, colorschemes, fonts,
				cursor styles, tabbing preferences, et cetera. It's not extensive, but it had
				pretty much everything I really wanted. Customization is done via a GUI, and you can
				export your Terminator config fairly easily. Unfortunately, directly modifying the 
				config file is difficult; you'll have to go through the GUI. I really wanted a feature
				that would let me have a margin/padding on all sides, so I wouldn't be quite so glued to
				the edges of the window. Also, ligatures are <i>not</i> supported.
				Aside from that, I was pretty pleased with Terminator. 
			</p>

			<h2>MacOS only</h2>

			<p>
				When I got my Mac, Mithrandir, I wanted a terminal emulator that would basically be like
				Terminator, just Mac edition. 
			</p>

			<h3>Terminal (default)</h3>
			<p>
				I'm not sure why the default Terminal offers nothing. Maybe I was just frustrated with
				MacOS (it was nothing like Arch and I was still heartbroken),
				but the default terminal is unusable.
				MacOS doesn't have good tiling by default. Multiplexing isn't needed so much when
				you have a good tiling window manager like i3, but in the absence of tiling,
				multiplexing is an absolute must.
				This is probably especially true if you live on a terminal.
				I jumped to iTerm2 nearly immediately after reading <a href="https://apple.stackexchange.com/questions/7078/is-there-any-multiple-terminal-emulator-for-mac-os">this StackExchange answer</a>.
			</p>

			<h3>iTerm2</h3>
			<p>
				Just ... wow. I've never been so overwhelmed with configuration options.
				It provided really nice multiplexing support (without the option to open the terminal-child
				in the same directory) and provided the standard configuration options. Despite
				it being a tad resource-hungry (especially since most everything was done through a GUI),
				I was really happy, but I didn' t want to have to memorize two different
				keybindings. This was nearly <em>better</em> than Terminator, but unfortunately it's MacOS
				only. That's when I started looking into customizable emulators that work for
				both Linux and MacOS. As a result, I didn't bother looking for exportability - but I'm pretty
				sure that if I looked hard enough, I'd be able to find a way to export my settings (they'd
				have to be stored <i>somewhere</i>, right?). However, I'm not so sure about whether
				those settings would be easy to modify via a CLI.
			</p>

			<h2>Both Linux and MacOS</h2>

			<p>
				I dislike having two sets of keybindings between computers.
				Even the change from <i>Ctrl</i> to <i>Command</i> is quite annoying.
				I was surprised by how few people use multiplexing; perhaps they have
				an alternative. Maybe they just run processes in the background, or 
				they have some sort of a <i>good</i> tiling system. MacOS does have some
				tiling, but it's not nearly as good or as easy to use as i3, for example.
				Thus, I only sampled things that were both cross-platform and supported multiplexing.
				Ligature support was an added plus!
			</p>

			<h3>Wezterm</h3>
			<p>
				This was the first cross-platform emulator I tested, since I'd read that
				supports ligatures and multiplexing. Although the documentation looked nice,
				I could make sense of little. Additionally, at the time of writing this, it
				says the feature is still fairly new. I didn't feel like being a guinea pig, especially
				for something that's so important and with (I imagine) a steep learning curve.
				I preferred something that had already been developed with a large user support base.
			</p>

			<h3>Tabby</h3>
			<p>
				This was a fairly resource-hungry cross-platform alternative.
				My favorite feature was that the GUI supported multiplexing, just as Terminator does.
				I also liked that it gave you the option to open a tab in the same working directory,
				which is similar to Terminator.
				I do like some GUI functionalities, but the overhead in this case was too much.
				In iTerm's case, the overhead seemed to be worth it, but this offered too few features
				and too little customization to be worth it to me.
			</p>

			<h3>Kitty</h3>
			<p>
				This is my current terminal. It's insanely customizable and lightweight,
				and <span class="monospace">kittens</span> (small Python programs that provide additional
				in-terminal support,
				like <a href="https://sw.kovidgoyal.net/kitty/kittens/icat/">image viewing</a>)
				greatly improve the experience. The colorschemes and keybindings are quite intuitive
				and highly customizable. The settings are easily portable, and the overhead is minimal.
			</p>
			<p>
				Ligature support's built in, and customization is done through a well-documented configuration
				file. Multiplexing is really nice, and there are different layouts to choose from. I don't
				miss the GUI feel at all - the keybindings took me a grand total of 5 seconds to get used to.
				There's the option to open a window in the same working directory, which is insanely
				useful. Speaking of which, a small workaround is to have a Python script to copy the working
				directory to the system clipboard. I have it bound to an alias. The following is mine!
			</p>
			<div class="realcode">
			#! /usr/bin/python <br/>
			import os <br/>
			directory_path = os.getcwd() <br/>
			print(directory_path) <br/>
			 <br/>
			import pyperclip <br/>
			pyperclip.copy(directory_path) <br/>
			</div>
			<p>
				I've recently run into some trouble with <span class="monospace">ssh</span>, but 
				I'll keep this page updated as I debug that. Also, after I try to exit the terminal,
				it sometimes hangs before quitting on its own. 
				I'm not sure what's causing this, but I just quit using
				i3's <em>Mod</em> + <em>Shift</em> + <em>q</em> which has always worked on windows.
				However, this gets a bit annoying when working with tabs, which I use rather infrequently
				anyway.
			</p>
		</div>
	);
}
