import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text =  `
Song of the day: Mr. Perfectly Fine, Taylor Swift\\
Lo que vamos a hacer: terminar la 9<sup>a</sup> unidad (temas D, E, F), comenzar la 10<sup>a</sup> (temas A, B)

*******************************************************************************

| _Cuartos de la casa_ | Rooms of the house |
| ---- | ----|
| _baño_ | bathroom|
| _habitación_ | bedroom|
| _sala de estar_ | living room|
| _cocina_ | kitchen|
| _comedor_ | dining room|
| _lavadero_ | laundry room|

#### Actividad de escuchar 

| Sentence | _Traducción_ |
| ---- | ---- |
| Dear Susie | _Querida Susie_ |
| It’s after dinner | _Es después de la cena_ |
| My family is working in the kitchen | _Mi famila está trabajando en la cocina_ |
| My daughter Li is washing the dishes | _Mi hija Li está lavando los platos_ | 
| My daughter Mei is drying the dishes | _Mi hija Mei está secando los platos_ |
| My husband and tao are taking out the trash | _Mi esposo y Tao están sacando la basura_ |
| Where is my oldest son? | _¿Dónde está mi hijo mayor?_ |
| He isn’t in the kitchen. | _Él no está en la cocina_ |
| He is sleeping in the living room! | _Está durmiendo en la sala de estar_ |
| I am not happy | _No estoy feliz_ |
| I need help | _Necesito ayuda_ |
| What can I do? | _¿Qué puedo hacer?_ |

- _quehaceres_ = chores
- walk/talk -> la l no se pronuncia

*******************************************************************************

- infinitivo: to do _(hacer)_, to wash _(lavar)_, to water _(regar)_
- la forma basa: do, wash, water (estas son las formas que se encuentran en un diccionario)
- presente progresivo: doing, washing, watering

## Unit 10: Free time _(tiempo libre)_

Activity | Actividad
| --------- | ---------| 
dance | _bailar_
exercise | _ejercitar/hacer ejercicio_
fish | _pescar_
play basketball | _jugar al baloncesto_
play cards | _jugar cartas_
swim | _nadar_
cook | _cocinar_
play the guitar | _tocar la guitarra_
listen to music | _escuchar música_
watch TV | _ver la tele_
read magazines | _leer revistas_
work in the garden | _trabajar en el jardín_

### Actividad del libro

| Sentence | Translation |
| ---- | ---- |
Do you like to dance? <br/> Yes, we do! | _¿Les gusta bailar? <br/> Sí, ¡nos gusta!_
Do you like to play cards? <br/> Yes, we do! | _¿Les gusta jugar cartas? <br/> Sí, ¡nos gusta!_
What do you like to do? <br/>  I like to fish.| _¿Qué te gusta hacer? <br/> Me gusta pescar._
What do you like to do? <br/> I like to swim. | _¿Qué te gusta hacer? <br/> Me gusta nadar._


|Questions | _Preguntas_ |
| ---- |---- |
What do you like to do? | _¿Qué te gusta hacer?_
What does he like to do? | _A él, ¿qué le gusta hacer?_
What does she like to do? | _A ella, ¿qué le gusta hacer?_
What do we like to do? | _¿Qué nos gusta hacer?_
What do they like to do? | _A ellos/ellas, ¿qué les gusta hacer?_
What do you not like to do? | _¿Qué no te gusta hacer?_

### Frases afirmativas

| Frase | _Traddución_ |
| ----| ----| 
I like _____ | *Me gusta(n) _____*
You like _____ | *Te gusta(n) _____*
He likes _____ | *A él le gusta(n) _____*
She likes _____ | *A ella le gusta(n) _____*
We like _____ | *Nos gusta(n) _____*
They like _____ | *A ellos/ellas les gusta(n) _____*


### Frases negativas

| Frase larga | Frase corta | _Traddución_ |
| ----| ----| ----|
I do not like _____ | I don’t like _____ | *No me gusta(n) _____*
You do not like _____ | You don’t like _____ | *No te gusta(n) _____*
He does not like _____ | He doesn’t like _____ | *A él no le gusta(n) _____*
She does not like _____ | She doesn’t like _____ | *A ella no le gusta(n) _____*
We do not like _____ | We don’t like _____ | *No nos gusta(n) _____*
They do not like _____ | They don’t like _____ | *A ellos/ellas no les gusta(n) _____*

Los espacios en las frases anteriores se pueden reemplazar por verbos (infinitivos o participios presentes) o por sustantivos
- infinitivo - comienzan en « to » (_to wash_, _to dance_ ...) 
- participio presente (terminan en _-ing_, como _washing_, _dancing_ ...)

#### Ejemplos 
- _Me gusta bailar_ = I like to dance / I like dancing
- _Te gusta nadar_ = You like to swim/You like swimming
- _A él le gusta pescar_ = He likes to fish/He likes fishing

### Pronombres de objeto
- Se usan estos pronombres para evitar repetir una parte de una frase
- « it » - singular o con los verbos
	- ¿Te gusta escuchar música? = Do you like to listen to music? / Do you like listening to music?
	-Sí, me gusta mucho = Yes, I like it a lot.
- « them » - plural

#### Ejemplos: 
- Singular
	- _¿Te gusta este libro?_ = Do you like this book?
	- _Sí, me gusta_ = Yes, I like it
	- _No, no me gusta_ = No, I do not like it / No, I don’t like it
- Plural
	- _¿Te gustan estos libros?_ = Do you like these books?
	- _Sí, me gustan_ = Yes, I like them
	- _No, no me gustan_ = No, I do not like them/ No, I don’t like them


`
export default function Week09() {
	return (
		<div>
			<h1>Week 13 Lecture Notes (4/27)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.ingles.com/guia/quehaceres">Más sobre los quehaceres</a></li>
					<li><a href="https://www.ingles.com/guia/el-presente-simple-vs-el-presente-progresivo">El presente progresivo y el presente simple</a></li>
				</ul>
			</p>
		</div>
	)
}
