import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Pocketful of Sunshine_ by Natasha Bedingfield

La agenda de hoy:

- Repasar las primeras secciones de Unidad 9
- Hacer actividades en secciones C y D

*******************************************************************************

## Repaso
- Yo estoy escribiendo = I am writing
- El presente progresivo:
	- Estar + participio / « to be » + participle
	- -ando/-iendo = -ing

## Formar el participio presente
- eat (comer) - eating
- walk (caminar) - walking
- cook (cocinar) - cooking
#### Excepciones
- Una « e » muda - hay que quitarla antes de añadir el sufijo
	- write - writing
	- make - making
	- bike - biking
- Si tiene una sílaba y termina en consonante-vocal-consonante - dupliquen la última letra
	- stop (parar) - stopping
	- run (correr) - running
	- cut (cortar) - cutting
	- get - getting
	- Excepciones - si la palabra termina en x, w, y - solamente agreguen el sufijo
		- buy (comprar) - buying
		- tow (remolcar) - towing
		- tax (cobrar impuestos) - taxing
		- draw (dibujar) - drawing
- Si termina en una c, agreguen una k 
	- panic (aterrorizarse) - panicking
- Verbos que terminan en « ie »: reemplacen « ie » con « y »
	- tie (atar) -  tying
	- lie (mentir) - lying


Verbos:
- to do (hacer), to water (regar), to walk (caminar), to write (escribir) - __infinitives (infinitivos)__
- doing (haciendo), watering (regando), walking (caminando), writing (escribiendo)- __participio presente__


| Infinitive | Present progressive | _Traducción_| 
|----| ----| ----|
| to do homework | doing homework | _hacer tarea_ |
| to do the laundry | doing the laundry | _lavar la ropa_ |
| to dry the dishes | drying the dishes | _secar los platos_ |
| to make lunch | making lunch | _preparar el almuerzo_ | 
| to make the bed | making the bed | _arreglar la cama_ |
| to wash the dishes | washing the dishes | _lavar los platos_ |
| to cut the grass | cutting the grass | _cortar el pasto_ |
| to get the mail | getting the mail | _recoger el correo_ |
| to take out the trash | taking out the trash | _sacar la basura_ |
| to walk the dog | walking the dog | _pasear al perro_ |
| to wash the car | washing the car | _lavar el carro_ |
| to water the grass | watering the grass | _regar el pasto_ | 

#### Repaso de « to be » ser/estar

|Subject | Conjugation |
| ---|----|
|I | am|
|You| are|
|He |is|
|She |is|
|We| are|
|They| are|



*******************************************************************************
| _Sala_ | Room |
| ----- | ---- |
| _baño_ | bathroom |
| _cuarto_ | bedroom | 
| _salón_ | living room |
| _lavadero_ | laundry room |
| _cocina_ | kitchen | 
| _comedor_ | dining room |
`

export default function Week02() {
	return (
		<div>
			<h1>Week 13 Lecture Notes (11/30)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
