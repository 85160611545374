
import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
Song of the day: Mykonos, Fleet Foxes\\
Lo que vamos a hacer hoy: repasar para el examen - las primeras 5 unidades\\
Recordatorio: el examen va a estar disponible hoy (3/23) y tienen hasta el martes (3/28) para terminarlo (antes de 11:59 el lunes)
*******************************************************************************

# Unit 1

Países
- país: country
- Rusia: Russia
- Haití: Haiti
- China: China - la i se pronuncia como « ai »
- EEUU: The United States
- Somalia: Somalia
- Vietnam: Vietnam

Frases
- Me llamo: My name is …
- Ella se llama: Her name is …
- Él se llama: His name is …
- Tú te llamas: Your name …
- Cómo te llamas: What is your name? What’s your name?

Artículos de papelería
- libro: book
- mochila: backpack
- cuaderno: notebook
- borrador: eraser
- regla: ruler
- computadora: computer
- escritorio: desk
- lápiz: pencil
- bolígrafo: pen
- diccionario: dictionary
- mesa: table

## Unit 2

prepositions!

- encima de: on
- detrás de: behind - (fonéticamente: bi-jaind en español ) 
- delante de: in front of
- dentro de: in
- debajo de: under
- a la izquierda de: to the left of
- a la derecha de: to the right of

Frases

- Una regla está sobre el libro: A ruler is on the book
- El borrador está dentro del escritorio: The eraser is in the desk
- La silla está debajo de la mesa: The chair is under the table
- El cuaderno está encima del escritorio: The notebook is on the desk
- El diccionario está dentro de la mochila: The dictionary is in the backpack



## Unit 3

Family!

- padre: father
- madre: mother
- abuelo: grandfather
- abuela: grandmother
- adolescente: teenager
- hijo: son
- hija: daughter
- mujer: woman (pronunciación: wu-muhn)
- mujeres: women (pronunciación: wi-men) 
- hombre: man
- hombre: men
- hermano: brother
- hermana: sister
- tío: uncle
- tía: aunt
- chico: boy
- chica: girl
- esposo: husband
- esposa: wife
- bebé: baby

### Práctica
- El hermano de mi padre es mi … uncle (tío)
    - My father’s brother is my ….
    - The brother of my father is my …  (traducción literal, es correcta)
- La madre de mi madre es mi … grandmother (abuela)
    - My mother’s mother is my …
- El hijo de mis padres es mi … brother (hermano)
    - My parents’ son is my …
- La hermana de mi madre es mi … aunt (tía)
    - My mother’s sister is my …
- El padre de mi madre es mi … grandfather (abuelo)
    - My mother’s father is my …
- El esposo de mi madre es mi … father (padre)
    - My mother’s husband is my …



## Unit 4

El cuerpo/el consultorio
- el cuerpo: body
- el consultorio: the doctor’s office - oficina de doctor
- enfermero: nurse
- medicina: medicine
- dolor de estómago: stomachache
- dolor de muelas: toothache
- dolor de garganta: sorre throat
- fiebre: fever
- dolor de cabeza: headache
- paciente: patient
- pie: foot
- pies: feet
- cabeza: head
- brazo: arm
- mano: hand
- pierna: leg
- ojo: eye (pronunciación: « ai »)
- cara: face
- diente: tooth
- dientes: teeth
- boca: mouth
- cabello: hair
- oreja: ear
- refriado: cold

Enfermedades
- Qué te duele? -> What hurts?
- Me duele la pierna: My leg hurts. - hurts con singular
- Me duelen las piernas: My legs hurt. - hurt con plural
- tengo una fiebre: I have a fever
- Ella tiene dolor de cabeza: 
    - She has a headache
- Le duele la cabeza
    - Her head hurts
- tengo frío: I am cold
- estoy resfriada: I have a cold


## Unit 5

Lugares
- biblioteca: library
- librería: bookstore
- banco: bank
- calle: street
- farmacia: pharmacy
- restaurante: restaurant
- lavandería: laundromat/laundry
- gasolinera: gas station
- panadería: bakery
- barbería: barbershop
- escuela: school
- oficina de correo: post office


Preposiciones
- enfrente de: across from
- entre: between
- a lado de: next to
- en: on

Práctica
- La farmacia está a la izquierda de la panadería: The pharmacy is on the left of the bakery.
- El restaurante está entre la farmacia y el supermercado: The restaurant is between the pharmacy and the supermarket
- La panadería está enfrente del hospital: The bakery is across from the hospital
- La escuela está detrás del banco: The school is behind the bank



números ordinales

las fechas: October 4th (el 4 de octubre)

- first - 1st
- second - 2nd
- third - 3rd
- fourth - 4th
- fifth - 5th
- sixth - 6th
- seventh
- eigth
- ninth
- tenth
- eleventh
- twelfth
- thirteenth
- fourteenth
- fifteenth
- sixteenth
- seventeenth
- eighteenth
- nineteenth
- twentieth
- twenty-first - 21st
- twenty-second - 22nd
- twenty-third … - 23rd


El cumpleaños de mi amigo es el 6 de agosto
My friend’s birthday is on August 6th.

2 de marzo: March 2nd (March second)

primero de enero: January 1st

meses:
- January
- February
- March
- April
- May
- June
- July
- August
- September
- October
- November
- December

días de la semana
- Sunday - domingo
- Monday
- Tuesday
- Wednesday
- Thursday
- Friday
- Saturday - sábado

hoy: today
mañana: tomorrow
ayer: yesterday

Perdón, dónde está el restaurante?
Excuse me, where is the restaurant?
- The restaurant is on 35th Street.


*******************************************************************************
`

export default function Week082() {
	return (
		<div>
			<h1>Week 8 Lecture Notes (3/23)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li>Los apuntes y las actividades del libro</li>
				</ul>
			</p>
		</div>
	)
}
