import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
*******************************************************************************
¡Último día de clases! Good luck!! \\
Song of the day (_Canción del día_): _Still Learning_ by Halsey \\
Lo que vamos a hacer: repasar para el examen final

------------

## 10a unidad
- ¿A ellos qué les gusta hacer? = What do they like to do?
- ¿A él qué le gusta hacer? = What does he like to do?
- ¿Qué te gusta hacer? = What do you like to do?
- Me gusta bailar = I like to dance/I like dancing
- Me gusta escuchar música = I like to listen to music/I like listening to music
- No me gusta pescar =  I do not like fishing/I don't like fishing/I don't like to fish/I do not like to fish
- Nos gusta cocinar = We like to cook/We like cooking

Activity | Actividad
| --------- | ---------| 
dance | _bailar_
exercise | _ejercitar/hacer ejercicio_
fish | _pescar_
play cards | _jugar cartas_
swim | _nadar_
cook | _cocinar_
play the guitar | _tocar la guitarra_
listen to music | _escuchar música_
watch TV | _ver la tele_
read magazines | _leer revistas_
work in the garden | _trabajar en el jardín_

------

## 9a unidad

| _Cuartos de la casa_ | Rooms of the house |
| ---- | ----|
| _baño_ | bathroom|
| _habitación_ | bedroom|
| _sala de estar_ | living room|
| _cocina_ | kitchen|
| _comedor_ | dining room|
| _lavadero_ | laundry room|

- Estoy cortando el pasto = I am cutting the grass
- Ella está recogiendo el correo = She is getting the mail
- Él está sacando la basura = He is taking out the trash
- Estamos lavando los platos = We are washing the dishes 
- Ellos están haciendo la tarea = They are doing homework
- Tú estás paseando al perro = You are walking the dog
- Estoy preparando el almuerzo = I am making lunch
- Ël está arreglando la cama = He is making the bed
- Ella está secando los platos = She is drying the dishes
- Estamos lavando el carro = We are washing the car
- Tú estás regando el pasto = You are watering the grass

### Repaso de « to be » ser/estar

|Subject | Conjugation |
| ---|----|
|I | am|
|You| are|
|He |is|
|She |is|
|We| are|
|They| are|

## 8a unidad

| Profesión | Profession |
| ---- | -----|
| _mesero_ | server |
| _cajero_ | cashier | 
| _vendedor_ | salesperson |
| _mecánico_ | mechanic |
| _conserje_ | custodian |
| _recepcionista_ | receptionist |
| _pintor_ | painter |
| _plomero_ | plumber |
| _conductor de bús_ | bus driver |
| _ama de casa_ | homemaker |
| _ayudante del profesor_ | teacher's aide |

- What do you do?

| Sentence | _Traducción_ | Profession |
| ---- | ---- | ---- |
| She answers the phone | _Ella contesta el teléfono_ | receptionist|
| She counts money | _Ella cuenta dinero_ | cashier |
| He fixes cars | _Él arregla carros_ | mechanic |
| He cleans buildings | _Él limpia edificios_ | custodian |
| She sells clothes | _Ella vende ropa_ | salesperson |
| He serves food | _Él sirve comida_ | server |

- ¿Ella contesta el teléfono? = Does she answer the phone?
- Yes, she does/No, she doesn't
- ¿Él sirve comida? = Does he serve food?
- Yes, he does/No, he doesn't
- ¿Ellos venden ropa? = Do they sell clothes? 
- Yes, they do/No, they don't

| Sujeto | Presente | 
| ---| ---| ---
| I | do |
| You | do | 
| He/She | does |
| We | do | 
| They | do |

********************

## 7a unidad

|_Color_| Color|
|---| ---|
|_negro_ | black|
|_rojo_ | red|
|_blanco_ | white|
|_morado_ | purple|
|_rosado_ | pink|
|_marrón_ | brown|
|_azul_ | blue|
|_verde_ | green|
|_amarillo_ | yellow|
|_gris_ | gray|
|_anaranjado_ | orange|


| _Español_ | English |
| ----- | -----|
| _ropa_ | clothes |
| _sombrero_ | hat |
| _gafas_ | glasses |
| _gafas de sol_| sunglasses |
| _calcetines_ | socks |
| _zapatos_ | shoes |
| _camisa_ | shirt |
| _camiseta_ | T-shirt |
| _precio_ | price |
| _corbata_ | tie |
| _vestido_ | dress |
| _pantalón_ | pants |
| _blusa_ | blouse |
| _suéter_ | sweater |
| _falda_ | skirt |
| _chaqueta_ | jacket |
| _impermeable_ | raincoat |

- La falda cuesta $20. = The skirt is twenty dollars
- Los zapatos cuestan $100 = The shoes are a hundred dollars
- ¿Cuánto cuestan los calcetines? = How much are the socks? 
- ¿Cuánto cuesta el pantalón? = How much are the pants?
- ¿Cuánto cuesta la blusa? = How much is the blouse?
- La blusa cuesta $30.99 = The blouse costs/is thirty ninety-nine/thirty dollars and ninety-nine cents

## 6a unidad

| Event | _Evento_ |
| ----| -----|
| appointment | _cita_ | 
| movie | _película_ |
| class | _clase_ |
| meeting | _reunión_ |
| party | _fiesta_ |
| TV show | _serie/programa_ |
| concert | _concierto_ |

- 6:30 de la tarde = 6:30 PM/6:30 in the evening
- 3 de la mañana = 3 AM/3 in the morning
- medianoche = midnight
- mediodía = noon
- ¿Qué hora es? = What time is it? 
- ¿A qué hora es la fiesta? = What time is the party?
- Son las 3 = It's 3 o'clock (horas exactas)

*******************************************************************************
`


export default function Week05() {
	return (
		<div>
			<h1>Week 8 Lecture Notes (7/27)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,

		</div>
	)
}
