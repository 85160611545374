import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home            from './components/Home'
import Contact         from './components/Contact'
import Navbar          from './components/Navbar'
import Error           from './components/Error'
import Footer          from './components/Footer'

import Blog            from './blog/Blog'
import Bluetooth       from './blog/Bluetooth'
import ESL             from './blog/ESL'
import Asahi           from './blog/Asahi'
import MacPreferences  from './blog/MacPreferences'
import Audio           from './blog/Audio'
import Mac             from './blog/Mac'
import Terminal        from './blog/Terminal'
import Dellxps         from './blog/Dellxps'
import Arch            from './blog/Arch'
import Spotify         from './blog/Spotify'
import Practices       from './blog/Practices'
import Scroll          from './blog/Scroll'
import Deskenv         from './blog/Deskenv'
import Preferences     from './blog/Preferences'
import Debian          from './blog/Debian'
import Virtualbox      from './blog/Virtualbox'
import Network         from './blog/Network'
import Browser         from './blog/Browser'
import Power           from './blog/Power'
import Tweaks          from './blog/Tweaks'
import Map             from './blog/Map'
import Bash            from './blog/Bash'
import Color           from './blog/Color'
import Sleep           from './blog/Sleep'

import Spr23Teaching   from './spring23/Teaching'
import Spr23Week02     from './spring23/Week02'
import Spr23Week03     from './spring23/Week03'
import Spr23Week05     from './spring23/Week05'
import Spr23Week06     from './spring23/Week06'
import Spr23Week081    from './spring23/Week081'
import Spr23Week082    from './spring23/Week082'
import Spr23Week09     from './spring23/Week09'
import Spr23Week10     from './spring23/Week10'
import Spr23Week11     from './spring23/Week11'
import Spr23Week12     from './spring23/Week12'
import Spr23Week13     from './spring23/Week13'

import Sum23Teaching   from './summer23/Teaching'
import Sum23Week01     from './summer23/Week01'
import Sum23Week02     from './summer23/Week02'
import Sum23Week03     from './summer23/Week03'
import Sum23Week04     from './summer23/Week04'
import Sum23Week05     from './summer23/Week05'
import Sum23Week07     from './summer23/Week07'
import Sum23Week08     from './summer23/Week08'

import Fal23Teaching   from './fall23/Teaching'
import Fal23Week01     from './fall23/Week01'
import Fal23Week02     from './fall23/Week02'
import Fal23Week031    from './fall23/Week031'
import Fal23Week032    from './fall23/Week032'
import Fal23Week04     from './fall23/Week04'
import Fal23Week05     from './fall23/Week05'
import Fal23Week06     from './fall23/Week06'
import Fal23Week07     from './fall23/Week07'
import Fal23Week09     from './fall23/Week09'
import Fal23Week10     from './fall23/Week10'
import Fal23Week11     from './fall23/Week11'
import Fal23Week13     from './fall23/Week13'
import Fal23Week14     from './fall23/Week14'

import Spr24Teaching   from './spring24/Teaching'
import Spr24Week01     from './spring24/Week01'
import Spr24Week02     from './spring24/Week02'
import Spr24Week03     from './spring24/Week03'
import Spr24Week04     from './spring24/Week04'
import Spr24Week05     from './spring24/Week05'
import Spr24Week06     from './spring24/Week06'
import Spr24Week07     from './spring24/Week07'
import Spr24Week08     from './spring24/Week08'
import Spr24Week09     from './spring24/Week09'
import Spr24Week10     from './spring24/Week10'
import Spr24Week12     from './spring24/Week12'
import Spr24Week13     from './spring24/Week13'
import Spr24Week14     from './spring24/Week14'

import Sum24Teaching   from './summer24/Teaching'
import Sum24Week01     from './summer24/Week01'
import Sum24Week02     from './summer24/Week02'
import Sum24Week03     from './summer24/Week03'
import Sum24Week04     from './summer24/Week04'
import Sum24Week06     from './summer24/Week06'
import Sum24Week07     from './summer24/Week07'
import Sum24Week08     from './summer24/Week08'

import Fal24Teaching   from './fall24/Teaching'
import Fal24Week01     from './fall24/Week01'
import Fal24Week02     from './fall24/Week02'
import Fal24Week03     from './fall24/Week03'
import Fal24Week04     from './fall24/Week04'
import Fal24Week05     from './fall24/Week05'
import Fal24Week06     from './fall24/Week06'
import Fal24Week07     from './fall24/Week07'
import Fal24Week08     from './fall24/Week08'
import Fal24Week09     from './fall24/Week09'

import Books           from './other/Books'

import ScrollToTop     from './components/ScrollToTop'

function App() {
  return (
    <Router>
		<ScrollToTop/>
		<Navbar/>
		<div className="flexLayout">
			<Routes>
				<Route path='/'                      element={<Home/>} />
				<Route path='/contact'               element={<Contact/>} />

				<Route path='/blog'                  element={<Blog/>} />
				<Route path='/blog/bluetooth'        element={<Bluetooth/>} />
				<Route path='/blog/esl'              element={<ESL/>} />
				<Route path='/blog/asahi'            element={<Asahi/>} />
				<Route path='/blog/mac-preferences'  element={<MacPreferences/>} />
				<Route path='/blog/audio'            element={<Audio/>} />
				<Route path='/blog/mac'              element={<Mac/>} />
				<Route path='/blog/terminal'         element={<Terminal/>} />
				<Route path='/blog/dellxps'          element={<Dellxps/>} />
				<Route path='/blog/arch'             element={<Arch/>} />
				<Route path='/blog/spotify'          element={<Spotify/>} />
				<Route path='/blog/practices'        element={<Practices/>} />
				<Route path='/blog/scroll'           element={<Scroll/>} />
				<Route path='/blog/deskenv'          element={<Deskenv/>} />
				<Route path='/blog/preferences'      element={<Preferences/>} />
				<Route path='/blog/debian'           element={<Debian/>} />
				<Route path='/blog/virtualbox'       element={<Virtualbox/>} />
				<Route path='/blog/network'          element={<Network/>} />
				<Route path='/blog/browser'          element={<Browser/>} />
				<Route path='/blog/power'            element={<Power/>} />
				<Route path='/blog/tweaks'           element={<Tweaks/>} />
				<Route path='/blog/map'              element={<Map/>} />
				<Route path='/blog/bash'             element={<Bash/>} />
				<Route path='/blog/color'            element={<Color/>} />
				<Route path='/blog/sleep'            element={<Sleep/>} />

				<Route path='/spring23'              element={<Spr23Teaching/>} />
				<Route path='/spring23/week02'       element={<Spr23Week02/>} />
				<Route path='/spring23/week03'       element={<Spr23Week03/>} />
				<Route path='/spring23/week05'       element={<Spr23Week05/>} />
				<Route path='/spring23/week06'       element={<Spr23Week06/>} />
				<Route path='/spring23/week08-1'     element={<Spr23Week081/>} />
				<Route path='/spring23/week08-2'     element={<Spr23Week082/>} />
				<Route path='/spring23/week09'       element={<Spr23Week09/>} />
				<Route path='/spring23/week10'       element={<Spr23Week10/>} />
				<Route path='/spring23/week11'       element={<Spr23Week11/>} />
				<Route path='/spring23/week12'       element={<Spr23Week12/>} />
				<Route path='/spring23/week13'       element={<Spr23Week13/>} />

				<Route path='/summer23'              element={<Sum23Teaching/>} />
				<Route path='/summer23/week01'       element={<Sum23Week01/>} />
				<Route path='/summer23/week02'       element={<Sum23Week02/>} />
				<Route path='/summer23/week03'       element={<Sum23Week03/>} />
				<Route path='/summer23/week04'       element={<Sum23Week04/>} />
				<Route path='/summer23/week05'       element={<Sum23Week05/>} />
				<Route path='/summer23/week07'       element={<Sum23Week07/>} />
				<Route path='/summer23/week08'       element={<Sum23Week08/>} />

				<Route path='/fall24'                element={<Fal23Teaching/>} />
				<Route path='/fall24/week01'         element={<Fal23Week01/>} />
				<Route path='/fall24/week02'         element={<Fal23Week02/>} />
				<Route path='/fall24/week031'        element={<Fal23Week031/>} />
				<Route path='/fall24/week032'        element={<Fal23Week032/>} />
				<Route path='/fall24/week04'         element={<Fal23Week04/>} />
				<Route path='/fall24/week05'         element={<Fal23Week05/>} />
				<Route path='/fall24/week06'         element={<Fal23Week06/>} />
				<Route path='/fall24/week07'         element={<Fal23Week07/>} />
				<Route path='/fall24/week09'         element={<Fal23Week09/>} />
				<Route path='/fall24/week10'         element={<Fal23Week10/>} />
				<Route path='/fall24/week11'         element={<Fal23Week11/>} />
				<Route path='/fall24/week13'         element={<Fal23Week13/>} />
				<Route path='/fall24/week14'         element={<Fal23Week14/>} />

				<Route path='/spring24'              element={<Spr24Teaching/>} />
				<Route path='/spring24/week01'       element={<Spr24Week01/>} />
				<Route path='/spring24/week02'       element={<Spr24Week02/>} />
				<Route path='/spring24/week03'       element={<Spr24Week03/>} />
				<Route path='/spring24/week04'       element={<Spr24Week04/>} />
				<Route path='/spring24/week05'       element={<Spr24Week05/>} />
				<Route path='/spring24/week06'       element={<Spr24Week06/>} />
				<Route path='/spring24/week07'       element={<Spr24Week07/>} />
				<Route path='/spring24/week08'       element={<Spr24Week08/>} />
				<Route path='/spring24/week09'       element={<Spr24Week09/>} />
				<Route path='/spring24/week10'       element={<Spr24Week10/>} />
				<Route path='/spring24/week12'       element={<Spr24Week12/>} />
				<Route path='/spring24/week13'       element={<Spr24Week13/>} />
				<Route path='/spring24/week14'       element={<Spr24Week14/>} />

				<Route path='/summer24'              element={<Sum24Teaching/>} />
				<Route path='/summer24/week01'       element={<Sum24Week01/>} />
				<Route path='/summer24/week02'       element={<Sum24Week02/>} />
				<Route path='/summer24/week03'       element={<Sum24Week03/>} />
				<Route path='/summer24/week04'       element={<Sum24Week04/>} />
				<Route path='/summer24/week06'       element={<Sum24Week06/>} />
				<Route path='/summer24/week07'       element={<Sum24Week07/>} />
				<Route path='/summer24/week08'       element={<Sum24Week08/>} />

				<Route path='/teaching'              element={<Fal24Teaching/>} />
				<Route path='/teaching/week01'       element={<Fal24Week01/>} />
				<Route path='/teaching/week02'       element={<Fal24Week02/>} />
				<Route path='/teaching/week03'       element={<Fal24Week03/>} />
				<Route path='/teaching/week04'       element={<Fal24Week04/>} />
				<Route path='/teaching/week05'       element={<Fal24Week05/>} />
				<Route path='/teaching/week06'       element={<Fal24Week06/>} />
				<Route path='/teaching/week07'       element={<Fal24Week07/>} />
				<Route path='/teaching/week08'       element={<Fal24Week08/>} />
				<Route path='/teaching/week09'       element={<Fal24Week09/>} />
				{/* <Route path='/teaching/week03'       element={<Sum24Week03/>} /> */}
				{/* <Route path='/teaching/week04'       element={<Sum24Week04/>} /> */}
				{/* <Route path='/teaching/week06'       element={<Sum24Week06/>} /> */}
				{/* <Route path='/teaching/week07'       element={<Sum24Week07/>} /> */}
				{/* <Route path='/teaching/week08'       element={<Sum24Week08/>} /> */}

				<Route path='/books'                 element={<Books/>} />

				<Route path='*'                      element={<Error/>} />
			</Routes>
		</div>
		<div className="spacer"/>
		<Footer/>
    </Router>
  );
}

export default App;
