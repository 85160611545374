import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text =  `

Song of the day: Arcade, by Duncan Laurence\\
Lo que vamos a cubrir: terminar la 7<sup>a</sup> unidad (temas E, F) , y comenzar la 8<sup>a</sup> unidad (temas A, B)

*******************************************************************************
## Colors 

|_Color_| Color|
|---| ---|
|_negro_ | black|
|_rojo_ | red|
|_blanco_ | white|
|_morado_ | purple|
|_rosado_ | pink|
|_marrón_ | brown|
|_azul_ | blue|
|_verde_ | green|
|_amarillo_ | yellow|
|_gris_ | gray|
|_anaranjado_ | orange|

### Un poco de práctica

Hay
- _there is_ - singular (hay una rana: _there is a frog_)
- _there are_ - plural (hay ranas: _there are frogs_) 

En inglés, el adjetivo va __enfrente__ del sustantivo: se dice _red shirt_, no _shirt red_.

| _Frase_ | Translation |
| -----|-----|
|_La camiseta es azul_ | The T-shirt is blue.|
|_Hay una camiseta azul_ | There is a blue shirt.|
|_Yo quiero una gorra verde_ | I want a green cap.|
|_¿De qué color es la camisa?_ | What color is the shirt?|
|_El calcetín es azul_ | The sock is blue|
|_Los calcetines son azules_ | The socks are blue|
|_Yo quiero un vestido rosado_ | I want a pink dress|
|_Quieres zapatos marrones._ | You want brown shoes|
|_¿De qué color es tu pantalón?_ | What color are your pants?|
|_El pantalón es morado_ | The pants are purple|
|_La corbata es amarilla y roja_ | The tie is yellow and red|

*******************************************************************************
## Preguntas de sí o no

Preguntas que se contestan con sí/no típicamente comienzan con una conjugación del verbo « to do » (en otros casos, se usa el verbo « to be »/ o otro verbo)

Primero, repasemos las congugaciones del verbo «to do» en el presente (y )

| Sujeto | Presente | Pasado |
| ---| ---| ---
| I | do | did | 
| You | do | did |
| He/She | does | did |
| We | do | did |
| They | do | did | 

Ejemplos
- _¿Quieres zapatos marrones?_ = Do you want brown shoes? 
- _¿Ella hace su tarea?_ = Does she do her homework?

Infinitivos: los infinitivos son las formas más sencillas de un verbo (la forma que se encuentra en un diccionario) 

Por ejemplo:
- make _(hacer)_
- run _(correr)_
- write _(escribir)_
- read _(leer)_
- walk _(caminar)_

Cómo contestar las preguntas de sí/no de manera afirmativa
- Yes, <nombre/pronombre> <una conjugación del verbo « to do »>
- Yes, he does.
- Yes, I do.
- Yes, she does.
- Yes, they do

Contracciones
- don’t
- doesn’t
- didn’t (pasado)

Cómo contestar las preguntas de sí/no de manera negativa
- No, <nombre/pronombre> <una conjugación del verbo « to do »> not
- No, he does not./No, he doesn’t
- No, I do not./No, I don’t
- No, she does not./No, she doesn’t
- No, they do not./No, they don’t

_____________

-- _¿Él vende ropa?_ =  Does he sell clothes?\\
-- Yes, he does/No, he does not/No, he doesn’t

--_¿Ellos sirven comida?_ = Do they serve food?\\
-- Yes, they do/ No, they do not/No, they don’t.

-- _¿Contestas el teléfono?_ =  Do you answer the phone? \\
-- Yes, I do/ No, I do not/No, I don’t

-- _¿Ella arregla los carros?_ = Does she fix cars? \\
-- Yes, she does/No, she doesn’t/No, she does not.

-- _¿Ellas cuentan dinero?_ = Do they count money? \\
-- Yes, they do/ No, they do not/No, they don’t.

-- _¿Ellas contaron dinero?_ = Did they count money? \\
-- Yes, they did/ No, they did not/No, they didn’t.



*******************************************************************************
## Unit 8


| Profesión | Profession |
| ---- | -----|
| _mesero_ | server |
| _cajero_ | cashier | 
| _vendedor_ | salesperson |
| _mecánico_ | mechanic |
| _conserje_ | custodian |
| _recepcionista_ | receptionist |
| _pintor_ | painter |
| _plomero_ | plumber |
| _conductor de autobús_ | bus driver |
| _ama de casa_ | homemaker |
| _ayudante del profesor_ | teacher's aide |

- What’s his/her job? = *¿Cuál es su trabajo?*
- What does he/she do? = *¿Qué hace?*
- What’s your job?  = *¿Cuál es su trabajo?*
- What do you do? = *¿Qué haces?*
- He/She is ____ = *Él/Ella es ____*


| Sentence | _Traducción_ | Profession |
| ---- | ---- | ---- |
| She answers the phone | _Ella contesta el teléfono_ | receptionist|
| She counts money | _Ella cuenta dinero_ | cashier |
| He fixes cars | _Él arregla carros_ | mechanic |
| He cleans buildings | _Él limpia edificios_ | custodian |
| She sells clothes | _Ella vende ropa_ | salesperson |
| He serves food | _Él sirve comida_ | server |

| Verb | _Verbo_ |
| ----- | ----- |
| answer | _contestar_ | 
| count | _contar_ |
| fix | _reparar_ |
| clean | _limpiar_ |
| sell | _vender_ |
| serve | _servir_ |

*******************************************************************************

`
export default function Week09() {
	return (
		<div>
			<h1>Week 11 Lecture Notes (4/13)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.ingles.com/guia/las-preguntas-yes-o-no-con-el-verbo-to-be">Preguntas de sí o no</a></li>
				</ul>
			</p>
		</div>
	)
}
