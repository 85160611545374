import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _lighthouse_ by Kelly Clarkson

La agenda de hoy:

- Terminar la Unidad 6

*******************************************************************************
## Repaso

| Event | _Evento_ |
| ----| -----|
| appointment | _cita_ | 
| movie | _película_ |
| class | _clase_ |
| meeting | _reunión_ |
| party | _fiesta_ |
| TV show | _serie/programa_ |
| concert | _concierto_ |

- Son las 7 = It's 7 o'clock
- Son las 5:30 = It's five thirty/It's half past five
- Son las 6:15 = It's six fifteen/It's quarter past six
- Son las 8:45 = It's eight forty-five/It's quarter to nine
- mediodía = noon
- medianoche = midnight
- por la mañana = in the morning
- por la tarde = in the afternoon/evening
- por la noche = in the night
- AM - medianoche a mediodía
- PM - mediodía a medianoche
*******************************************************************************
## Horas especiales
- _quarter past_ = y cuarto (_quarter_ significa «cuarto»)
- _half past_ = y media (_half_ significa «mitad», y la «l» no se pronuncia)
- *It is &lt;minutes&gt; till &lt;hour&gt;* = faltan &lt;minutos&gt; para &lt;hora&gt;
- faltan &lt;minutes&gt; para finalizar = _&lt;minutes&gt; left / twenty &lt;minutes&gt; to go_

| Frase | Traducción |
| ----- | --------|
| _Son las ocho y cuarto_ | It's quarter past eight |
| _Son las seis y media_ | It is half past eight |
| _Son las nueve y diez_ | It is ten past nine |
| _Faltan cuarto para las ocho_ | It's quarter to/till eight |
| _Faltan cinco para las doce_ | It's five to/till twelve |
| _Faltan diez para las tres_ | It's ten to/till three |
*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 8 Lecture Notes (10/24)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.ingles.com/guia/como-decir-la-hora-en-ingles">Decir la hora en inglés</a></li>
				</ul>
			</p>
		</div>
	)
}
