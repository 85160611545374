import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Eyes Closed_ by Halsey

Agenda de hoy:

- Repasar el presente progresivo y vocabulario
- Unidad 9 (E, F)
- Continuar con la unidad 10

*******************************************************************************

## Repaso

|  Present progressive | _Traducción_| 
|----| ----|
| drying the dishes | _secar los platos_ |
| walking the dog | _pasear al perro_ |
| watering the grass | _regar el pasto_ | 
| taking out the trash | _sacar la basura_ |
| making the bed | _arreglar la cama_ |
| cutting the grass | _cortar el pasto_ |
|  making lunch | _preparar el almuerzo_ | 
| doing the laundry | _lavar la ropa_ |
| getting the mail | _recoger el correo_ |
| doing homework | _hacer tarea_ |
|  washing the car | _lavar el carro_ |
| washing the dishes | _lavar los platos_ |

## Preguntas

### What are you doing?
- ¿Qúe estás haciendo? = What are you doing?
- ¿Qué estamos haciendo? = What are we doing?
- ¿Qué están haciendo ellos? = What are they doing?
- ¿Qué está haciendo él/ella? = What is he/she doing?
- ¿Qué estoy haciendo? = What am I doing?

### Preguntas de sí/no
- Are you walking the dog? = ¿Estás paseando al perro?
	- Yes, I am/No, I'm not (No, I am not)
- Is he watering the grass? = ¿Está regando el pasto?
	- Yes, he is/No, he isn't (No, he is not)
- Are they making lunch? = ¿Están preparando el almuerzo?
	- Yes, they are/No, they aren't (No, they are not)

### Repaso de « to be » ser/estar

|Subject | Conjugation |
| ---|----|
|I | am|
|You| are|
|He |is|
|She |is|
|We| are|
|They| are|

| _Cuartos de la casa_ | Rooms of the house |
| ---- | ----|
| _baño_ | bathroom|
| _cocina_ | kitchen|
| _comedor_ | dining room|
| _habitación_ | bedroom|
| _sala de estar_ | living room|
| _lavadero_ | laundry room|

***********************************************
## Unidad 10

Activity | Actividad
| --------- | ---------| 
hobby | _pasatiempo_
dance | _bailar_
exercise | _ejercitar/hacer ejercicio_
fish | _pescar_
play cards | _jugar cartas_
swim | _nadar_
cook | _cocinar_
play the guitar | _tocar la guitarra_
listen to music | _escuchar música_
watch TV | _ver la tele_
read magazines | _leer revistas_
work in the garden | _trabajar en el jardín_
ride a bike(bicycle) | _montar en bici(bicicleta)_
draw | _dibujar_
paint | _pintar_
run | _correr_
sing | _cantar_

### Preguntas
- ¿Qué te gusta hacer? = What do you like to do?
- ¿A él qué le gusta hacer? = What does he like to do?
- ¿A ella qué le gusta hacer? = What does she like to do?
- ¿A ellos qué les gusta hacer? = What do they like to do?
- ¿Qué nos gusta hacer? = What do we like to do?

### Respuestas
- Me gusta bailar = I like to dance/I like dancing
- Me gusta escuchar música = I like to listen to music/I like listening to music
- A él le gusta tocar la guitarra = He likes to play the guitar/He likes playing the guitar

### Negación
- No me gusta bailar = I don't like to dance/I don't like dancing
- A él no le gusta nadar = He doesn't like to swim/He doesn't like swimming
- ¿Qué no te gusta hacer? = What do you not like to do?
- No me gusta pescar 
	- I do not like fishing/I don't like fishing
	- I don't like to fish/I do not like to fish
- I don't like to make the bed/I don't like making the bed
*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 7 Lecture Notes (7/18)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://englishlive.ef.com/es-mx/blog/tips-para-estudiar/reglas-de-fonetica-para-leer-y-escribir-hablar/">La "e" muda</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
