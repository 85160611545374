import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text =  `
Song of the Day: Eyes Closed, by Halsey\\
Lo que vamos a hacer hoy: cubrir la 7<sup>a</sup> unidad (los temas A, B y C)

*******************************************************************************

## Clothes!

| _Español_ | English |
| ----- | -----|
| _ropa_ | clothes |
| _ropa de verano_ | summer clothes |
| _paraguas_| umbrella |
| _sombrero_ | hat |
| _gafas_ | glasses |
| _gafas de sol_| sunglasses |
| _calcetines_ | socks |
| _zapatos_ | shoes |
| _bolso_ | bag |
| _camisa_ | shirt |
| _camiseta_ | T-shirt |
| _shorts_ | pantalones cortos |
| _precio_ | price |
| _corbata_ | tie |
| _vestido_ | dress |
| _pantalón_ | pants |
| _blusa_ | blouse |
| _suéter_ | sweater |
| _falda_ | skirt |
| _chaqueta_ | jacket |
| _impermeable_ | raincoat |
| _guantes_ | gloves |
| _anillo_ | ring |

*******************************************************************************
# ¿Cuánto cuesta? 

## Pregunta y respesta - unas fórmulas

### Pregunta
- *¿Cuánto cuesta ____ ?* = How much is _____ ?/How much does ____ cost?
- *¿Cuánto cuestan ____ ?* = How much are ____ ?/How much do ____ cost?

### Respuesta
- *____ cuesta <el precio>* = ______ costs <the price> / _____ is <the price>
- *____ cuestan <el precio>* = ______ cost <the price>/ ______ are <the price>

### Ejemplos

#### Singular
- _¿Cuánto cuesta la camisa?_ = How much is the shirt? / How much does the shirt cost?
- _La camisa cuesta 10 dólares_ = The shirt costs ten dollars/ The shirt is ten dollars.

#### Plural
- _¿Cuánto cuestan los zapatos?_ = How much are the shoes? / How much do the shoes cost?
- _Los zapatos cuestan 40 dólares_ = The shoes cost forty dollars. / The shoes are forty dollars.

## Práctica del libro

1\\. How much is the shirt? = _¿Cuánto cuesta la camisa?_\\
2\\. The shirt? Nineteen dollars. = _¿La camisa? 19 dólares_

1\\. How much are the socks? = _¿Cuánto cuestan los calcetines_\\
2\\. The socks? One ninety-nine. = _¿Los calcetines? $1.99_

1\\. How much is the dress? = _¿Cuánto cuesta el vestido?_\\
2\\. The dress? Thirty-nine ninety-nine. = _¿El vestido? $39.99_

1\\. How much are the pants? = _¿Cuánto cuestan el pantalón?_\\
2\\. The pants? Twenty-four ninety-nine = _¿El pantalón? $24.99_

### Más práctica
- How much is the tie? The tie costs $10 (ten dollars)
- How much is the blouse? The blouse costs $19.99 (nineteen ninety-nine)
- How much is the sweater? The sweater costs $29.99 (twenty-nine ninety-nine)
- How much is the skirt? The skirt costs $24.99 (twenty-four ninety-nine)
- How much is the jacket? The jacket costs $89.99 (eighty-nine ninety-nine)
- How much is the raincoat? The raincoat costs $39.99 (thirty-nine ninety-nine)


## Información adicional

- _Pants_ es siempre plural en inglés - se dice _one pair of pants_ o _two pairs of pants_; no cambia
- Hay 2 maneras de decir los precios:
	-  $18.99 = eighteen ninety-nine / eighteen dollars and ninety-nine cents
	-  $16.95 = sixteen ninety-five/ sixteen dollars and ninety-five cents

*******************************************************************************
| Adjective | _Adjetivo_|
|------|------|
|this | _esto/esta/este_|
|that | _eso/ese/esa_|
|these | _estos/estas_|
|those | _esos/esas_|


## Unas oraciones

| _Español_ | English |
| -------|--------|
| _¿Cuánto cuesta esta camisa? <br/> Esta camisa cuesta $12._ | How much is this shirt? <br/> This shirt costs 12 dollars (twelve dollars). |
| _¿Cuánto cuestan estos guantes? <br/> Estos guantes cuestan $7.89_|How much are these gloves? <br/> The gloves cost $7.89/The gloves are $7.89 (seven eighty-nine/seven dollars and eighty-nine cents)|
| _¿Cuánto cuesta esa chaqueta? Esa chaqueta cuesta $200_ | How much is that jacket? That jacket costs $200 (two hundred dollars.)|
| _¿Cuánto cuestan las gafas de sol? <br/> Las gafas de sol cuestan $149.99_ | How much are the sunglasses? <br/>The sunglasses cost 149.99  (one hundred forty-nine dollars and ninety-nine cents/one forty-nine ninety-nine) |
| _¿Cuánto cuesta el anillo? <br/>El anillo cuesta $1000_ | How much is the ring/How much does the ring cost? <br/>The ring costs $1000 (a thousand dollars) |
| _¿Cuánto cuesta el pantalón?<br/> El pantalón cuesta $37.50_ | How much are the pants?<br/> The pants cost $37.50 (thirty-seven fifty/thirty-seven dollars and fifty cents.)|

*******************************************************************************

`
export default function Week09() {
	return (
		<div>
			<h1>Week 10 Lecture Notes (4/6)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://madridingles.net/como-decir-numeros-grandes/">Números grandes</a></li>
					<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li>
				</ul>
			</p>
		</div>
	)
}
