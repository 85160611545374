import React from 'react';
import { Link } from 'react-router-dom';

export default function Teaching () {
	return (
		<div>
			<h1>ESL Lecture Notes</h1>
			<p>
				I'm teaching an introductory ESL course
				at <a href="https://elbuen.org/">El Buen Samaritano</a> during the fall 2024 session!
				<br/>
				<br/>
				Here are a set of lecture notes covering both
				things we've talked about in class as well as some additional examples.
				I'll be updating this page as the semester progresses.
				Hopefully this helps!
				<br/>
				<br/>
				<ul>
					<li><Link to="/teaching/week01">Week 1 (week of 9/2)</Link></li>
					<li><Link to="/teaching/week02">Week 2 (week of 9/9)</Link></li>
					<li><Link to="/teaching/week03">Week 3 (week of 9/16)</Link></li>
					<li><Link to="/teaching/week04">Week 4 (week of 9/23)</Link></li>
					<li><Link to="/teaching/week05">Week 5 (week of 9/30)</Link></li>
					<li><Link to="/teaching/week06">Week 6 (week of 10/7)</Link></li>
					<li><Link to="/teaching/week07">Week 7 (week of 10/14)</Link></li>
					<li><Link to="/teaching/week08">Week 8 (week of 10/21)</Link></li>
					<li>Week 9 (week of 10/28): No class; Happy Halloween!</li>
					<li><Link to="/teaching/week09">Week 10 (week of 11/4)</Link></li>
					{/* <li><Link to="/spring24/week03">Week 3 (week of 2/12)</Link></li> */}
					{/* <li><Link to="/spring24/week04">Week 4 (week of 2/19)</Link></li> */}
					{/* <li><Link to="/spring24/week05">Week 5 (week of 2/26)</Link></li> */}
					{/* <li><Link to="/spring24/week06">Week 6 (week of 3/4)</Link></li> */}
					{/* <li>No class! Have a nice break!</li> */}
					{/* <li><Link to="/spring24/week07">Week 7 (week of 3/18)</Link></li> */}
					{/* <li><Link to="/spring24/week08">Week 8 (week of 3/25)</Link></li> */}
					{/* <li><Link to="/spring24/week09">Week 9 (week of 4/1)</Link></li> */}
					{/* <li>No class! (week of 4/8)</li> */}
					{/* <li><Link to="/spring24/week10">Week 11 (week of 4/15)</Link></li> */}
					{/* <li><Link to="/spring24/week12">Week 12 (week of 4/22)</Link></li> */}
					{/* <li><Link to="/spring24/week13">Week 13 (week of 4/29)</Link></li> */}
					{/* <li><Link to="/spring24/week14">Week 14 (week of 5/6)</Link></li> */}
				</ul>
			</p>


		</div>
	)
}
