import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
Song of the day: Willow, Taylor Swift\\
Lo que vamos a hacer hoy: comenzar la 5<sup>a</sup> unidad, temas A y B (vocabulario)
*******************************************************************************
## Los lugares (Places)

|Español | English |
|:-----|:-----|
|banco| bank|
|biblioteca| library (pronunciación: la «i» se pronuncia como «ai» - piénsenlo como lai-bre-ri)|
|librería| bookstore/bookshop|
|retaurante| restaurant (pronunciación: rest-rant)|
|tienda| store/shop|
|escuela| school|
|colegio/preparatoria| high school|
|calle| street|
|supermercado| supermarket|
|peluquería| barbershop|
|panadería| bakery|

*******************************************************************************
## Actividades del texto

Where? = ¿dónde? \\
Where is? (Where’s) = ¿dónde está? \\
Where are? = ¿dónde están?

main = principal

Where is the school? _(¿Dónde está la escuela?)_ \\
The school? It’s on Main Street. _(La escuela? Está en la Calle Principal.)_\\
Thanks. _(Gracias.)_

Where’s the restaurant? _(¿Dónde está el restaurante?)_ \\
The restaurant? I don’t know. _(El restaurante? No sé.)_ \\
Okay, thank you. _(Bien, gracias._)

Where’s the library? -> _(¿Dónde está la biblioteca?)_ \\
The library is on Market Street. _(La biblioteca está en la Calle Mercado.)_ \\
Thanks a lot. _(Muchas gracias.)_

Where’s the supermarket? _(¿Dónde está el supermercado?)_ \\
Sorry, I don’t know. _(Lo siento, no sé._) \\
Thanks anyway. _(Gracias de todas maneras/Bien, gracias.)_


*******************************************************************************
## Más lugares 

|English | Español |
|:-----|:-----|
|pharmacy| farmacia|
|hospital| hospital|
|laundromat| lavandería|
|post office| oficina de correo|
|movie theater| cine|
|gas station| gasolinera|

*******************************************************************************
## Práctica

|Pregunta | Respuesta |
|:-----|:-----|
|I need to buy books! Where can I buy them? _(Necesito comprar libros. ¿Dónde los puedo comprar?)_  |Bookstore _(librería)_ |
| I’m hungry! Where can I get some food? _(¡Tengo hambre! ¿Dónde se vende comida?)_ | Restaurant/supermarket _(restaurante/supermarket)_
|Where can I learn English? _(Dónde puedo aprender inglés?)_| school/high school/online _(escuela/colegio/en línea)_
|Where can I drive a car? _(¿Dónde puedo manejar un carro?)_ | street _(calle)_|
|I want cookies. Where can I get some? _(Quiero galletas. ¿Dónde se venden?)_| bakery/supermarket _(panadería/supermercado)_|
|I wrote a letter to my friend. Where can I send it? _(Escribí una carta a mi amigo. ¿Dónde puedo enviarla?)_| post office _(oficina de correo)_|
|My car ran out of gas. Where should I go? _(Mi carro se quedó sin gasolina. ¿Adónde debo ir?)_| gas station _(gasolinera)_|
|I need a haircut. Where should I go? _(Necesito un corte de cabello. ¿Adónde debo ir? )_| barbershop _(peluquería)_|
|Where can I get vaccinated?/Where can I get medicine? _(¿Adónde puedo ir a vacunarme?/Dónde puedo conseguir medicina?)_ | pharmacy _(farmacia)_ |
|All my clothes are dirty. Where should I go? _(Toda mi ropa está sucia. ¿Adónde debo ir?)_ | laundromat _(lavandería)_ |
|This man is really sick! Where should they take him? _(Este hombre está muy enfermo. ¿Adónde lo deben llevar?)_| hospital _(hospital)_|

run out of <something> = quedarse sin <algo> \\
¿Adónde debo ir? -> Where should I go?
*******************************************************************************
## Un poco sobre _gonna_
Con los lugares, no se puede reemplazar “going to” con “gonna”

Vamos a comer = We're going to eat (informal: We’re gonna eat)

Con la construcción «ir + a + verbo» (usada con acciones que toman lugar en el futuro próximo),
que es igual que _to go + verb_, se puede reemplazar _going to_ con _gonna_ en contextos informales.

Por eso, una manera de decir _We're going to run_ («vamos a correr») sería _We're **gonna** run_.
Sin embargo, no se puede reemplazar el _going to_ con _gonna_ en la frase _I am going to the store_
(«voy a la tienda»),
porque _going to_ en esta frase indica una ubicación, y no es una forma de _to go + verb_.
*******************************************************************************
`

export default function Week06() {
	return (
		<div>
			<h1>Week 6 Lecture Notes (3/9)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.aprenderinglesrapidoyfacil.com/2017/02/04/uso-gonna/"><em>Gonna</em> en inglés</a></li>
					<li><a href="https://quizlet.com/2127031/lugares-places-flash-cards/">Quizlet con unas palabras de vocabulario</a></li>
				</ul>
			</p>
		</div>
	)
}
