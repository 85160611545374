import React from 'react';

export default function Browser() {
	return (
		<div>
			<h1>Browser Reviews</h1>

			<p>
				I get to sound like one of those Youtubers that test out new products! Fun times. Let's get started.
			</p>
			<h2>The Situation</h2>
			<p>
				I wanted to test something other than the default browser, Firefox.
				Plus, I was kinda bored one evening and didn't want to start my homework.
			</p>
			<h2>GNOME Web/Epiphany</h2>
			<p>
				I started out by looking into GNOME Web (aka Epiphany), which is supposed to be
				lightweight and amazing. TL;DR: it wasn’t.
			</p>
			<p>
				First off, install Epiphany by typing in the following commands (this is on Ubuntu):
			</p>

			<div class="realcode">
				sudo add-apt-repository ppa:gnome3-team/gnome3 <br/>
				sudo apt-get update <br/>
				sudo apt-get install epiphany-browser
			</div>

			<h3>Pros</h3>
			<p>
				<ul>
					<li>It’s faster than Firefox</li>
					<li>It deals with passwords cleaner in non-private browsing mode</li>
				</ul>
			</p>
			<h3>Cons</h3>
			<p>
				<ul>
					<li>
						It makes &Eacute;owyn make a high-pitched sound and leads to overheating.
						I tried recalibrating powertop after installation, but that didn’t help any.
					</li>
					<li>It doesn’t have a “never remember history” option</li>
					<li>Private browsing settings are kinda weird</li>
					<li>Difficult and nonintuitive to customize</li>
				</ul>
			</p>
			<p>
				Overall, I wasn’t really a big fan (no pun intended, but the fans were running a bit too
				much). The main reason why I didn’t go with Epiphany was probably the weird hissing
				sounds my laptop started making. I cleaned up afterwards by getting rid of the repo and
				by uninstalling Epiphany.
			</p>

			<h2>Chromium</h2>
			<p>
				I installed and used Chromium for the better part of 10 minutes. This (obviously) had
				the same look and feel as Chrome, but I still wasn’t a big fan of its RAM consumption.
				Also, privacy. <s>I uninstalled Chromium and moved on with life. </s>
				I had to recently install it, since it's pretty good with MS Teams.
			</p>
			<h2>Tor/Onion Browser</h2>
			<p>
				I heard about Tor through the DuckDuckGo newsletter (which gives the average human a
				pretty decent intro to privacy).
				I'd heard fairly good things about the Onion Browser, that it would give the user more
				privacy and that it was FOSS.
			</p>
			<p>
				The UI's nearly identical to Firefox, since it's based off ESR version of Firefox.
				Yeah, I tried to make it my default browser, but I ran into
				one fundamental issue: it was far too slow for me. The performance drawback was a bit too
				much to bear, so I moved on. 
				<s>However, on my laptop, another annoyance was launching
				the application from an executable every time. There's probably a way to fix it;
				I tried running 
				<div class="realcode">./start-tor-browser.desktop --register-app</div> but that
				didn't add it to my launcher (<span class="monospace">dmenu</span>). </s>
				<br/>
				<br/>
				Update: on Arch, there's an app and it works quite well! I use Tor now
				pretty frequently. It's a bit annoying with certain sites, like YouTube, because
				your request might get flagged as suspicious (probably because there are so many requests
				coming from the same exit node). My university (UT Austin)
				uses <a href="https://duo.com">Duo</a> for
				MFA, which doesn't work on Tor at all; the request times out every time.
				Additionally, I can't query anything in the university's library catalog, so I can't
				use Tor for pretty much anything university-related (yikes).
				Other sites, like Quora, return a 403 Forbiden error.
				Most other sites are fine, and at some point in time I might consider running
				my own relay, and/or hosting this site as a <span class="monospace">.onion</span> site
				as well.

				<br/>
				<br/>
				<s>I wasn't sure that the program was exiting correctly, since when I
				quit the application, the command prompt would not return on its own.</s> On Arch, I'm no
				longer experiencing this issue. On my phone (iPhone), I don't really like Onion Browser,
				which uses Tor routing.
				It's pretty decent, albeit slow. Quoting the <a href="https://support.torproject.org/tormobile/">Tor
				website</a>, "Apple requires browsers on iOS to use something called Webkit, which prevents Onion
				Browser from having the same privacy protections as Tor Browser."
				<br/>
				<br/>
				Perhaps privacy is the price I pay for my impatience.

			</p>

			<h2>Opera</h2>
			<p>
				I installed Opera using the Ubuntu Software app, and not through command line.
			</p>
			<h3>Pros</h3>
			<p>
				<ul>
					<li>It has a battery conserving mode! You can choose whether you want to enable it all
							the time, or only when you’re not connected to external power</li>
					<li>Super easy to customize, with multiple themes! The task bar is easier to customize
							than any other browser I’ve used. There are so many options</li>
					<li>The GUI is intuitive, more so than MS Edge, Firefox, or Chrome.</li>
					<li>It seems faster than Firefox</li>
					<li>Videos work (HTML5), unlike in GNOME Web/Epiphany.</li>
					<li>VPNS! I love them</li>
					<li>The pop-out video feature is super cool and useful</li>
				</ul>
			</p>
			<h3>Cons</h3>
				<p>
					<ul>
						<li>Not all programs support Opera (like Overleaf), since it's not that popular.</li>
						<li>There’s not really a “never remember history” option, like there was in Firefox</li>
						<li>FB Messenger is kinda weird; it only works sometimes</li>
					</ul>
					It also gives you an option
					to <a href="https://help.opera.com/en/latest/security-and-privacy/#VPN">enable VPN</a>:
					<ol>
						<li>Go to <i>Settings</i> → <i>Advanced</i> and double click <i>Advanced</i></li>
						<li>Go to <i>VPN</i> and turn on <b>Enable VPN</b></li>
						<li>
							Go to the top of the search bar; you’ll see the letters “VPN” in a light grey. Click on
								that, so that it becomes blue. This will let you pick the location.
						</li>
					</ol>
				</p>

			<h2>Firefox</h2>
			<p>
				I just realized that I've talked about different browsers compared to Firefox, but
				I've never really discussed what I love about Firefox.
			</p>
			<p>
				I love how easy and intuitive it is to customize, as well as Mozilla being more privacy-focused
				compared to other companies. It's open source and ports well to different operating systems,
				which allows you to keep the same configuration between multiple devices. On that note, the sync
				feature is what I love the most - while I know my data has to be stored on the cloud for this to work,
				the ease of not having to email links to myself or Discord them to myself, being able to send tabs
				to different devices and access my history across devices makes it quite worth it.
			</p>
			<h2>DuckDuckGo (Beta, Mac)</h2>
			<p>
				DuckDuckGo recently released the beta version of
				its <a href="https://spreadprivacy.com/introducing-duckduckgo-for-mac/">desktop application for Macs</a>. I've
				used their phone app for quite a while, and I'm pretty stoked to try it out. It's not a fork
				of anything, so it doesn't have the cruft buildup from other browsers. They still haven't released
				one for Linux (or Windows either, for that matter), but I'm still really excited about this!
			</p>
			<p>
				My favorite feature is the same as the one I love about the iOS app - the ability to clear
				your data with just one click (the Fire button). You also have an option to fireproof certain
				sites so that you remain logged into them - that information won't then be deleted when you
				clear your data. You can also clear data on a site-by-site basis if you like, and this can
				be done with just a couple clicks. I also love how it shows you which trackers it's blocking,
				similar to the DDG extension, which I already regularly use with Firefox. 
			</p>
			<p>
				I am judging this a bit less harshly than I would other browsers, especially since
				they had to build this without forking existing browsers. That said, I <em>really</em> wish
				they had options for add ons. By default, ads are disabled, but DDG doesn't do as
				great of a job of blocking ads as plugins like uBlock do. 
				The DDG blog (linked above) does mention that blocking trackers blocks ads, but
				on a lot of sites, there's still a block where the ad was, and the browsing experience
				is still a bit slowed down. I also can't access articles on the
				the <em>NY Times</em>. I'm able to access a few articles on the <em>WaPo</em>, and pretty
				much everything on the <em> New Yorker</em>,
				but that may have to do with how strict their paywall policies are.
				Having a plugin to bypass the paywall would be great.
			</p>
			<p>
				The UI's arranged a bit different from most other browsers, but that's not much of a problem.
				My biggest complaint is that bookmarks require three clicks to access - one to access the
				menu on the right, one to access the bookmarks, and one to click on the desired link itself.
				Most browsers only require one, and I'd want that sort of one-click convenience in this app
				(clicks add up, and I'm quite impatient). I'd also want some sort of a way to sync bookmarks
				just like Firefox, but I've heard they're working on adding that. Firefox also
				offers greater customization in terms of autocomplete - specifically, I would like for it to
				autocomplete searches based off my bookmarks and nothing else.
			</p>
			<p>
				Overall, I'm fairly pleased. I'm excited to see what features they'll add and how they'll
				improve the service. I don't think it's quite ready for me to make a full transition; I'd
				want cross-device sync, Linux support, and plugins (ad-free navigation and reading the
				news are vitally important to me).
				All in all, I'm super hopeful about this project!
			</p>
			<hr/>
			<h2>Wrap up</h2>
			<p>
				This post is already pretty long, so here's a summary of the features I look for in a browser,
				ranked roughly in priority order:
				<ul>
					<li>privacy-focused</li>
					<li>customizable</li>
					<li>good store of plugins (ad-blockers, trackers, and the like)</li>
					<li>options to synchronize bookmarks</li>
					<li>cross-platform</li>
					<li>VPN support</li>
					<li>lightweight</li>
				</ul>
			</p>
			<hr/>
			<p>
				<u>Update:</u> as of September 2020, I'm back to Firefox. <s>As of June 2021, I also
				have Chromium installed, since it's pretty good with MS Teams.</s> Since I no longer
				need to use Teams, I have no need for Chromium. As of September 2021,
				I've started using Tor again, at least on my laptops.
			</p>
		</div>
	)
}
