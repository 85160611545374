import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Too Sweet_ by Hozier

La agenda de hoy:

- Repasar A y B de la Unidad 6
- Continuar con C y D
*******************************************************************************

- Son las 7 = It's seven o'clock
- Son las 5:30 = It's five thirty/It's half past five
	- half = mitad; l es muda
- Son las 5:15 = It's five fifteen/It's quarter past five
	- quarter = cuarto
- Son las 4:45 = It's four forty-five/It's quarter til five 
- It's 10 til 6 = Faltan diez para las seis
- mediodía = noon
- medianoche = midnight
- por la mañana = in the morning 
- por la tarde = in the afternoon/evening
- por la noche = in the night
- AM (ey-em) - medianoche a mediodía
- PM (pi-em)- mediodía a medianoche
- horario = schedule

| Event | _Evento_ |
| ----| -----|
| appointment | _cita_ | 
| movie | _película_ |
| class | _clase_ |
| meeting | _reunión_ |
| party | _fiesta_ |
| TV show | _serie/programa_ |
| concert | _concierto_ |

### Unas horas especiales

- _quarter past_ = y cuarto (_quarter_ significa «cuarto»)
- _half past_ = y media (_half_ significa «mitad», y la «l» no se pronuncia)
- *It is &lt;minutes&gt; till &lt;hour&gt;* = faltan &lt;minutos&gt; para &lt;hora&gt;
- faltan &lt;minutes&gt; para finalizar = _&lt;minutes&gt; left / twenty &lt;minutes&gt; to go_
`

export default function Week02() {
	return (
		<div>
			<h1>Week 4 Lecture Notes (6/27)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.ingles.com/guia/como-decir-la-hora-en-ingles">Decir la hora en inglés</a></li>
				</ul>
			</p>
		</div>
	)
}
