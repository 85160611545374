import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
Song of the day: Lavender Haze, Taylor Swift\\
Lo que vamos a hacer hoy: continuar con la 5<sup>a</sup> unidad, temas C y D
*******************************************************************************

## Preposiciones
|English| Español|
|----|-------|
|on| en|
|next to| a lado de|
|across from| enfrente de|
|between| entre|

## Actividades del texto

### Escuchar

-- Where's the supermarket?\\
-- On Main Street.

-- Where's the restaurant?\\
-- Next to the pharmacy.

-- Where's the police station?\\
-- Next to the bakery.

### Conversaciones pequeñas

| English | Español |
| --------| --------|
| -- Excuse me, where is the bank?<br/>-- Next to the supermarket.<br/> -- Thanks. | -- Disculpe, ¿dónde está el banco?<br/> -- Al lado del supermercado. <br/> -- Gracias.|
| -- Excuse me, where's the restaurant? <br/> -- Across from the bank. <br/> -- Thanks. | -- Disculpe, ¿dónde está el restaurante?<br/> -- Enfrente del banco. <br/> -- Gracias|
| -- Excuse me, where is the bank?<br/> -- Between the pharmacy and the supermarket. <br/> -- Thanks.| -- Disculpe, ¿dónde está el banco? <br/> -- Entre la farmacia y el supermercado. <br/> -- Gracias.|
| -- Excuse me, where is the police station? <br/> -- On Park Street. <br/> -- Thanks.| -- Disculpe, ¿dónde está la estación de policía? <br/> -- En Park Street.  <br/> -- Gracias.|
| -- Excuse me, where is the bakery? <br/> --Next to the restaurant. <br/> -- Thanks. | -- Disculpe, ¿dónde está la panadería? <br/> -- Al lado del restaurante. <br/> -- Gracias. |
| -- Excuse me, where is the supermarket? <br/> -- Across from the bakery. <br/> -- Thanks.| -- Disculpe, ¿dónde está el supermercado? <br/> -- Enfrente de la panadería. <br/> -- Gracias |

### Traducción

library = biblioteca \\
bookstore = librería

en medio = in the middle/in the center

Ven y visiten la Biblioteca Riverside. \\
La nueva biblioteca abre hoy.\\
La biblioteca está en Main Street.\\
Está enfrente de la escuela de adultos Riverside.\\
Está al lado del Supermercado K y P.\\
Está entre el Supermercado K y P y el restaurante de Rosie. \\
La biblioteca está abierta entre las 9 y las 5 los lunes, miércoles, y los viernes.

9:00 = nine o'clock / nine sharp (con énfasis) \\
5:00 = five o'clock

--------------------------
 ## Transportation

| English | Español |
| ------| ------|
| by bicycle (fonéticamente: bai-ci-cl) | en bicicleta |
| by bus | en autobús |
| by car | en carro |
| by taxi | en taxi |
| by train | por tren |
| on foot | a pie |

Ben, how do you get to school? _(Ben, ¿cómo llegas a la escuela?)_ \\
By car. _(En carro)_

How do you get to work? _(¿cómo llegas a trabajo?)_ \\
How do you get to the supermarket/grocery store? _(¿cómo llegas al supermercado?)_ \\
How do you get to the airport? _(¿cómo llegas al aeropuerto?)_

I drive to work. _(Voy manejando al trabajo.)_

I ride a bike to the supermarket. _(Voy en bicicleta al supermercado.)_

*******************************************************************************
`

export default function Week081() {
	return (
		<div>
			<h1>Week 8 Lecture Notes (3/21)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href='https://www.britishcouncil.org.mx/blog/preposiciones'>Unas prepocisiones</a></li>
				</ul>
			</p>
		</div>
	)
}
