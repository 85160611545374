import React from 'react';
import { 
	FaGithub, 
	// FaGitlab,
	FaLinkedin,
	FaDiscord,
	FaEnvelope,
	FaOrcid
} from 'react-icons/fa';
import { TbBrandMatrix } from "react-icons/tb";

export default function Contact() {
	return (
		<div>
			<h1>Contact</h1>
			
			<p>
				Wanna grab a bagel and coffee with me? Here's how to reach me!
				<ul>
					<li>
						<FaEnvelope/>
						{' '}krithika (at) krithravi (dot) com
					</li>
					<li>
						<FaGithub/>
						<a href="https://www.github.com/krithravi"> {' '}krithravi</a>
					</li>
					{/* <li> */}
					{/* 	<FaGitlab/> */}
					{/* 	<a href="https://gitlab.com/krithravi">{' '}krithravi</a> */}
					{/* </li> */}
					<li>
						<FaLinkedin/>
						<a href="https://linkedin.com/in/krithravi">{' '}krithravi</a>
					</li>
					<li>
						<TbBrandMatrix/>
						{' '}krithravi:matrix.org
					</li>
					<li>
						<FaDiscord/>
						{' '}krithravi#2664
					</li>
					<li>
						<FaOrcid/>
						<a href="https://orcid.org/0000-0003-4910-0961">
							{' '}0000-0003-4910-0961
						</a>
					</li>
					
				</ul>
			</p>
		</div>
	);
}
