import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Blank Space_ by Taylor Swift

La agenda de hoy:

- Repasar Unidad 6
- Comenzar Unidad 7

*******************************************************************************

## Repaso

- Son las 7 = It's seven o'clock
- Son las 5:30 = It's five thirty/It's half past five
- Son las 5:15 = It's five fifteen/It's quarter past five
- Son las 4:45 = It's four forty-five/It's quarter til/to five (faltan cuarto para las cinco)
- mediodía = noon
- medianoche = midnight
- por la mañana = in the morning
- por la tarde = in the afternoon/evening
- por la noche = in the night
- AM (medianoche hasta el mediodía)
- PM (mediodía hasta la medianoche)

*******************************************************************************
## Unidad 7

| _Español_ | English |
| ----- | -----|
| _ropa_ | clothes/clothing |
| _camisa_ | shirt |
| _camiseta_ | T-shirt |
| _pantalón_ | pants |
| _vestido_ | dress |
| _zapatos_ | shoes |
| _calcetines_ | socks |
| _blusa_ | blouse |
| _falda_ | skirt |
| _chaqueta_ | jacket |
| _sombrero_ | hat |
| _suéter_ | sweater |
| _impermeable_ | raincoat |
| _corbata_ | tie |
| _abrigo_ | coat | 
| _gafas_ | glasses | 
| _precio_ | price |

### Pregunta
- *¿Cuánto cuesta ____ ?* = How much is _____ ?/How much does ____ cost?
- *¿Cuánto cuestan ____ ?* = How much are ____ ?/How much do ____ cost?

### Respuesta
- *____ cuesta &lt;el precio>* = ______ costs &lt;the price> / _____ is &lt;the price>
- *____ cuestan &lt;el precio>* = ______ cost &lt;the price>/ ______ are &lt;the price>

### Ejemplos

#### Singular
- _¿Cuánto cuesta la falda?_ = How much is the skirt?/How much does the skirt cost?
- _La falda cuesta 15 dólares_ = The skirt costs fifteen dollars/The skirt is fifteen dollars

#### Plural
- _¿Cuánto cuestan los zapatos?_ = How much are the shoes?/How much do the shoes cost?
- _Los zapatos cuestan 60 dólares_ = The shoes cost sixty dollars/The shoes are sixty dollars


### Práctica
1. How much is the tie? The tie costs ten dollars
2. How much is the blouse? The blouse costs nineteen ninety-nine/nineteen dollars and ninety-nine cents
3. How much is the sweater? The sweater costs twenty-nine ninety-nine
4. How much is the skirt? The skirt is twenty-four ninety-nine
5. How much is the jacket? The jacket is eighty-nine ninety-nine
6. How much is the raincoat? The raincoat is thirty-nine ninety-nine

| Demonstrative pronoun | _Pronombre demostrativo_|
|------|------|
|this | _esto/esta/este_|
|that | _eso/ese/esa_|
|these | _estos/estas_|
|those | _esos/esas_|

### Más practica
| _Español_ | English |
| -------|------|
| _¿Cuánto cuesta esta camisa? <br/> Esta camisa cuesta $12._ | How much is this shirt? <br/> This shirt costs 12 dollars (twelve dollars). |
| _¿Cuánto cuestan estos calcetines? <br/> Estos calcetines cuestan $7.89_| How much are these socks? <br/> These socks cost $7.89/These socks are $7.89 (seven eighty-nine/seven dollars and eighty-nine cents)|
| _¿Cuánto cuesta esa chaqueta? <br/> Esa chaqueta cuesta $200_ | How much is that jacket? <br/> That jacket costs $200 (two hundred dollars.)|
| _¿Cuánto cuesta el pantalón?<br/> El pantalón cuesta $37.50_ | How much are the pants?<br/> The pants cost $37.50 (thirty-seven fifty/thirty-seven dollars and fifty cents.)|
| _¿Cuánto cuestan las gafas de sol? <br/> Las gafas de sol cuestan $149.99_ | How much are the sunglasses? <br/>The sunglasses cost 149.99  (one hundred forty-nine dollars and ninety-nine cents/one forty-nine ninety-nine) |
| _¿Cuánto cuesta el anillo? <br/>El anillo cuesta $1000_ | How much is the ring/How much does the ring cost? <br/>The ring costs $1000 (a thousand dollars) |

#### Cosas que saber
- buck = dólar
- pants es siempre plural
- centavos = cents
- $10.99 = ten dollars and ninety-nine cents/ten ninety-nine
*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 9 Lecture Notes (11/2)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li>
				</ul>
			</p>
		</div>
	)
}
