import React from 'react';

export default function Bluetooth() {
	return (
		<div>
		<h1>Bluetooth Setup</h1>
		<h2>The Problem</h2>
		<p>
			After my Logitech K380 broke (its keys fell off) and the keys on Faramir started to lose functionality,
			I ended up getting an additional keyboard and mouse. I ended up going with the Logitech K480 and a 
			fairly inexpensive Tecknet mouse. 
		</p>
		<p>
			Both of these peripherals connect to my laptop
			via Bluetooth. Bluetooth, however, is weird with Linux - every time my keyboard disconnects, it's incredibly difficult to get it to reconnect. 
			Specifically, I got a bunch of <span class="monospace">org.bluez</span> errors
			like <span class="monospace">org.bluez.Error.Failed br-connection-profile-unavailable</span>, <span class="monospace">org.bluez.Error.Failed br-connection-page-timeout</span> or <span class="monospace">org.bluez.Error.Failed br-connection-create-socket</span>, and it would refuse to connect even though the device had previously been trusted and paired. 
		</p>
		<p>
			I also ran into situations where an explicit error wouldn't be printed at all, but
			where the Bluetooth device wouldn't work. <span class="monospace">bluetoothctl</span> would{' '}
			<em>say</em> the device is connected, yet neither the keyboard nor mouse would be able to
			act as input devices. The other problem I ran into was trying to connect the device after
			trusting and pairing it. It would connect, and then after a few seconds disconnect for
			no apparent reason.
		</p>
		<p>
			I'm not sure what's causing these errors, and I wasn't able to find much
			information about what causes each type of error. 
			There's surprisingly little information about these sorts of errors in the ArchWikis.
		</p>
		<h2>Stuff that didn't work</h2>
		<p>
			GUI utilities like Blueberry or Blueman were completely ineffective. <span class="monospace">bluetoothctl</span> was the first utility I tried, and the only one I needed (I think).
			<br/>
			<br/>
			I also installed <span class="monospace">pulseaudio-bluetooth</span> to no avail, and I suspect this is because I'm not trying to connect an audio device.
			<br/>
			<br/>
			I mostly just tried variations of removing and pairing, trusting and untrusting, and connecting and disconnecting to get my keyboard to connect properly. 
		</p>
		<h2>One solution</h2>
		<p>
			This is the only reproducible way I've found to connect to the K480 and Tecknet mouse.
		</p>
		<ol>
			<li>Start <span class="monospace">bluetoothctl</span> and remove any devices that were previously connected. You can check this by running <span class="monospace">devices</span> to see what's connected. </li>
			<li>Turn <span class="monospace">scan on</span> and put your device in pairing mode.</li>
			<li>Wait for your device to show up, then you can <span class="monospace">trust</span> it.</li>
				<li>
					<span class="monospace">pair</span> it. At this point,
					a couple of things might happen:
				</li>
				<ul>
					<li>You'll have to <span class="monospace">connect</span> and potentially follow some additional instructions like entering in a code. If this happens, I don't have a reproducible way to connect. Just trial and error until something works, I guess. I'm sorry.</li>
					<li>If it doesn't prompt you to do anything, I'd wait until you get an error. It'll probably say that the authentication canceled (<span class="monospace">Failed to pair: org.bluez.Error.AuthenticationCanceled</span>), the pairing failed, or some similar error message. If this is the case, hang on until you get this error. Once you see it, go ahead and <span class="monospace">connect</span>. At this point, it'll say the device connected, and the device will actually have connected. Trying to connect prior to the error message will not work, and I'm still not sure why.</li>
				</ul>
		</ol>

		<p>
			The biggest negative about this is having to repeat this process <em>every</em> time
			the device disconnects. For example, if you went to eat lunch or something, it would have likely
			disconnected by the time you returned, which is extremely irritating.
		</p>

		<h2>Current solution</h2>
		<p>
			I've mostly resigned myself to using a receiver for wireless connection.
			None of my Linux-using friends are happy with Bluetooth, so I've also thrown in
			the towel for the time being.
			The receiver pairing works out of the box, and I've had zero issues with it thus far.
			I love not having to fiddle with <span class="monospace">bluetoothctl</span> at all.
			I currently use Logitech's <a href="https://www.logitech.com/en-us/products/combos/mk540-advanced-wireless-keyboard-mouse.920-008671.html">MK540</a> keyboard and mouse combo which uses Logitech's
			unifying receiver.
		</p>
		<p>
			This unfortunately doesn't help with most wireless audio setups,
			since those typically don't have receivers. The headphones I'd be most interested in
			would have a USB-C receiver (hence compatible with mobile devices, laptops and
			docking stations without the pairing/unpairing hassle), <s> but I haven't tested these
			kinds of headphones/earbuds.
			However, I'm content for now. I'll keep this updated as I test more wireless
			device connections!</s>
		</p>
		<p>
			Update as of 8/31/24:
			I did end up getting wireless headphones with a USB-C receiver! I went with the JBL Quantum
			TWS Air, which were about $70. I currently have it paired via Bluetooth to my phone, and
			it additionally connects to my other laptops using the dongle. It worked out of the box
			with my phone, MacBook (Mithrandir) and work computer,
			but not with Faramir due to my audio settings.
		</p>
		<p>
			I previously used pulseaudio for audio, but had to switch over to pipewire since pulse wasn't
			detecting the USB-C input as audio. I had to do a handful of things to get it to work:
			<ul>
				<li> Install <span class="monospace">pipewire</span>, <span class="monospace">pipewire-pulse</span>, <span class="monospace">pipewire-alsa</span>, and <span class="monospace">pipewire-jack</span></li>
				<li> This replaces pulseaudio, so my polybar script had to change to use pipewire. Polybar doesn't natively support a pipewire module, so I had it use a <a href="https://github.com/krithravi/dotfiles/blob/main/scripts/pipewire-simple.sh">modified version of a bash script I found</a></li>
				<li> Start the pipewire service</li>
			</ul>
			And that was it! It's able to do what pulseaudio couldn't. Both the audio input and output
			are detected and work fine. I'm pretty happy with both the headphones and the overall setup.
		</p>
		
		</div>
	)
}
