import React from 'react';
import { Link } from 'react-router-dom';

export default function Teaching () {
	return (
		<div>
			<h1>ESL Lecture Notes</h1>
			<p>
				I'm teaching an introductory ESL course
				at <a href="https://elbuen.org/">El Buen Samaritano</a> during the fall 2023 session,
				and previously during the spring and summer 2021 sessions as well as the <Link to="/spring23">spring 2023</Link> and <Link to="/summer23">summer 2023</Link> sessions.
				<br/>
				<br/>
				Here are a set of lecture notes covering both
				things we've talked about in class as well as some additional examples.
				I'll be updating this page as the semester progresses.
				Hopefully this helps!
				<br/>
				<br/>
				<ul>
					<li><Link to="/fall24/week01">Week 1 (week of 9/4)</Link></li>
					<li><Link to="/fall24/week02">Week 2 (week of 9/11)</Link></li>
					<li><Link to="/fall24/week031">Week 3 (week of 9/18)</Link></li>
					<li><Link to="/fall24/week032">Week 3 (week of 9/18)</Link></li>
					<li><Link to="/fall24/week04">Week 4 (week of 9/25)</Link></li>
					<li><Link to="/fall24/week05">Week 5 (week of 10/2)</Link></li>
					<li><Link to="/fall24/week06">Week 6 (week of 10/9)</Link></li>
					<li><Link to="/fall24/week07">Week 7 (week of 10/16)</Link></li>
					<li>Week 8 (week of 10/23) - substitute instructor</li>
					<li><Link to="/fall24/week09">Week 9 (week of 10/30)</Link></li>
					<li><Link to="/fall24/week10">Week 10 (week of 11/6)</Link></li>

					<li><Link to="/fall24/week11">Week 11 (week of 11/13)</Link></li>
					<li>Week 12 - no class, have a nice break!</li>
					<li><Link to="/fall24/week13">Week 13 (week of 11/27)</Link></li>
					<li><Link to="/fall24/week14">Week 14 (week of 12/4)</Link></li>
					<li>Week 15: Good luck on your final exam!</li>
				</ul>
			</p>


		</div>
	)
}
