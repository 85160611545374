import React from 'react';
import { Link } from 'react-router-dom';

export default function Teaching () {
	return (
		<div>
			<h1>ESL Lecture Notes</h1>
			<p>
				I'm teaching an introductory ESL course
				at <a href="https://elbuen.org/">El Buen Samaritano</a> during the summer 2023 session,
				and previously during the spring and summer 2021 sessions as well as the <Link to="/spring23">spring 2023 session</Link>.
				<br/>
				<br/>
				Here are a set of lecture notes covering both
				things we've talked about in class as well as some additional examples.
				I'll be updating this page as the semester progresses.
				Hopefully this helps!
				<br/>
				<br/>
				<ul>
					<li><Link to="/summer23/week01">Week 1 (week of 6/5)</Link></li>
					<li><Link to="/summer23/week02">Week 2 (week of 6/12)</Link></li>
					<li><Link to="/summer23/week03">Week 3 (week of 6/19)</Link></li>
					<li><Link to="/summer23/week04">Week 4 (week of 6/26)</Link></li>
					<li><Link to="/summer23/week05">Week 5 (week of 7/3)</Link></li>
					<li>Week 6 (week of 7/10) - no class </li>
					<li><Link to="/summer23/week07">Week 7 (week of 7/17)</Link></li>
					<li><Link to="/summer23/week08">Week 8 (week of 7/24)</Link></li>
				</ul>
			</p>


		</div>
	)
}
