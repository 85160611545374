import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
*******************************************************************************
Song of the day (_Canción del día_): _lighthouse_ by Kelly Clarkson\\
Lo que vamos a cubrir: terminar la 6a unidad (tema F), comenzar la 7a (temas A, B)
******************************* 
- Faltan cuarto para las seis = It's quarter til/to six
- half
	- 7:30 = It's half past 7

******************************* 

| _Español_ | English |
| ----- | -----|
| _ropa_ | clothes |
| _zapatos_ | shoes |
| _vestido_ | dress |
| _corbata_ | tie |
| _pantalón_ | pants |
| _sombrero_ | hat |
| _camisa_ | shirt |
| _camiseta_ | T-shirt |
| _calcetines_ | socks |
| _falda_ | skirt |
| _blusa_ | blouse |
| _gafas_ | glasses |
| _gafas de sol_| sunglasses |
| _chaqueta_ | jacket |
| _precio_ | price |
| _suéter_ | sweater |
| _impermeable_ | raincoat |

******************************* 

### Pregunta
- *¿Cuánto cuesta ____ ?* = How much is _____ ?/How much does ____ cost?
- *¿Cuánto cuestan ____ ?* = How much are ____ ?/How much do ____ cost?

### Respuesta
- *____ cuesta &lt;el precio>* = ______ costs &lt;the price> / _____ is &lt;the price>
- *____ cuestan &lt;el precio>* = ______ cost &lt;the price>/ ______ are &lt;the price>

### Ejemplos

#### Singular
- _¿Cuánto cuesta la camisa?_ = How much is the shirt? / How much does the shirt cost?
- _La camisa cuesta 10 dólares_ = The shirt costs ten dollars/ The shirt is ten dollars.

#### Plural
- _¿Cuánto cuestan los zapatos?_ = How much are the shoes? / How much do the shoes cost?
- _Los zapatos cuestan 40 dólares_ = The shoes cost forty dollars. / The shoes are forty dollars.

### Más práctica
- How much is the tie?/How much does the tie cost? The tie costs $10 (ten dollars)/The tie is $10
- How much is the blouse? The blouse costs $19.99 (nineteen ninety-nine)
- How much is the sweater? The sweater is $29.99 (twenty-nine ninety-nine)
- How much is the skirt? The skirt is $24.99 (twenty-four ninety-nine)
- How much is the jacket? The jacket is $89.99 (eighty-nine ninety-nine)
- How much is the raincoat? The raincoat costs $39.99 (thirty-nine ninety-nine)
- How much are the sunglasses? The sunglasses are $200 (two hundred dollars).

| Adjective | _Adjetivo_|
|------|------|
|this | _esto/esta/este_|
|that | _eso/ese/esa_|
|these | _estos/estas_|
|those | _esos/esas_|

## Unas oraciones

| _Español_ | English |
| -------|--------|
| _¿Cuánto cuesta esta camisa? <br/> Esta camisa cuesta $12._ | How much is this shirt? <br/> This shirt costs 12 dollars (twelve dollars). |
| _¿Cuánto cuestan estos guantes? <br/> Estos guantes cuestan $7.89_|How much are these gloves? <br/> The gloves cost $7.89/The gloves are $7.89 (seven eighty-nine/seven dollars and eighty-nine cents)|
| _¿Cuánto cuesta esa chaqueta? Esa chaqueta cuesta $200_ | How much is that jacket? That jacket costs $200 (two hundred dollars.)|
| _¿Cuánto cuestan las gafas de sol? <br/> Las gafas de sol cuestan $149.99_ | How much are the sunglasses? <br/>The sunglasses cost 149.99  (one hundred forty-nine dollars and ninety-nine cents/one forty-nine ninety-nine) |
| _¿Cuánto cuesta el anillo? <br/>El anillo cuesta $1000_ | How much is the ring/How much does the ring cost? <br/>The ring costs $1000 (a thousand dollars) |
| _¿Cuánto cuesta el pantalón?<br/> El pantalón cuesta $37.50_ | How much are the pants?<br/> The pants cost $37.50 (thirty-seven fifty/thirty-seven dollars and fifty cents.)|

*******************************************************************************
`


export default function Week05() {
	return (
		<div>
			<h1>Week 5 Lecture Notes (7/6)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://madridingles.net/como-decir-numeros-grandes/">Números grandes</a></li>
					<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li>
				</ul>
			</p>
		</div>
	)
}
