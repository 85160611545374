import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
*******************************************************************************

La agenda de hoy:

- Hacer actividades en secciones C, D, E, F de Unidad 2



| _Palabra_ | Word |
| ---- | ----- |
_un libro_ | a book
_una silla_ | a chair
_una computadora_ | a computer
_un escritorio_ | a desk
_un cuaderno_ | a notebook
_un lápiz_ | a pencil

## Los artículos indefinidos
- a/an: los artículos indefinidos
- a: si el sustantivo comienza en una consonante (el sonido de una consonante)
- an: si el sustantivo comienza en una vocal (el sonido es lo que cuenta)


Ejemplos:
- an apple (_una manzana_)
- an umbrella (_un paraguas_)
- a university - "yu"
- an heir
- a horse
- an hour
- a historic event

| _Palabra_ | Word |
| ---- | ----- |
_diccionario_ | a dictionary
_papel_ | paper
_una hoja de papel_ | a sheet of paper
_pluma_ | a pen
_borrador_ | an eraser
_grapadora_ | a stapler
_regla_ | a ruler

## Preposiciones
- in = _en_/_dentro de_
- on = _en_/_sobre_
- under = _debajo de_

Ejemplos:
- Un borrador está sobre el escritorio = _An eraser is on the desk._
- La hoja de papel está dentro del libro = _The sheet of paper is in the book_
- Una pluma está debajo de la silla = _A pen is under the chair_

Preguntas
- Where is (Where's)= ¿Dónde está ...? 
- Where are = ¿Dónde están ...?

## Fechas = Dates

### Days of the Week = Días de la semana
| _Día_ | Day |
| ---- | ----- |
_lunes_ | Monday
_martes_ | Tuesday
_miércoles_ | Wednesday
_jueves_ | Thursday
_viernes_ | Friday
_sábado_ | Saturday
_domingo_ | Sunday

### Months of the Year = Meses del año
| _Mes_ | Month| 
| ---- | ----- |
_enero_ | January
_febrero_ | February
_marzo_ | March
_abril_ | April
_mayo_ | May
_junio_ | June
_julio_ | July
_agosto_ | August
_septiembre_ | September
_octubre_ | October
_noviembre_ | November
_diciembre_ | December

Ejemplos
- 9 de abril = April 9<sup>th</sup> (ninth)
- 4 de julio = July 4<sup>th</sup> (fourth)
- 3 de octubre = October 3<sup>rd</sup>
- 31 de octubre = October 31<sup>st</sup>
- 25 de diciembre = December 25<sup>th</sup>
- Today is June 6<sup>th</sup>/Thursday, June 6<sup>th</sup>/ June 6, 2024
- What is today's date? = ¿Cuál es la fecha de hoy?
- What day is today?/What day is it?= ¿Qué día es hoy?
- birthday = cumpleaños
- "on" con las fechas/días, "in" con los meses
	- My birthday is in September
	- My birthday is on February 8<sup>th</sup>
	- La clase es el martes = The class is on Tuesday
- My birthday is on ...
- My mother's/father's/sister's/brother's/son's/daughter's birthday is on ...
- Friday, August 9<sup>th</sup>

Números ordinales
| Ordinal number | Forma corta | Número ordinal|
| --- | --- |  --- |
| first |1<sup>st</sup>  | primero|
second |2<sup>nd</sup>| segundo
 third |3<sup>rd</sup> | tercero
fourth |4<sup>th</sup> | cuarto
fifth | 5<sup>th</sup> | quinto
sixth |6<sup>th </sup> | sexto
seventh | 7<sup>th</sup> | séptimo
eighth | 8<sup>th</sup> | octavo
ninth | 9<sup>th</sup> | noveno
tenth | 10<sup>th</sup> | décimo
eleventh | 11<sup>th</sup>  | undécimo |  
twelfth | 12<sup>th</sup>  | duodécimo |  
thirteenth | 13<sup>th</sup>  | decimotercero |  
fourteenth | 14<sup>th</sup>  | decimocuarto |  
fifteenth | 15<sup>th</sup>  | decimoquinto |  
sixteenth | 16<sup>th</sup>  | decimosexto |  
seventeenth | 17<sup>th</sup>  | decimoséptimo |  
eighteenth | 18<sup>th</sup>  | decimoctavo |  
ninteenth | 19<sup>th</sup>  | decimonoveno |  
twentieth | 20<sup>th</sup>  | vigésimo | 
twenty-first | 21<sup>st</sup> | vigésimo primero | 
twenty-second | 22<sup>nd</sup> | vigésimo segundo | 
twenty-third | 23<sup>rd</sup> | vigésimo tercero | 
twenty-fourth | 24<sup>th</sup> | vigésimo cuarto | 
twenty-fifth | 25<sup>th</sup> | vigésimo quinto | 
twenty-sixth | 26<sup>th</sup> | vigésimo sexto | 
twenty-seventh | 27<sup>th</sup> | vigésimo séptimo | 
twenty-eighth | 28<sup>th</sup> | vigésimo octavo | 
twenty-ninth | 29<sup>th</sup> | vigésimo noveno| 
thirtieth | 30<sup>th</sup> | trigésimo | 
thirty-first | 31<sup>st</sup> | trigésimo primero | 


- should = debería
	- verbo auxiliar
	- indica obligación
	- "l" es muda
	- ¿Qué debería hacer él? = What should he do?
*******************************************************************************
`

export default function Week01() {
	return (
		<div>
			<h1>Week 1 Lecture Notes (6/6)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://quizlet.com/84184442/stationary-spanish-flash-cards/">Quizlet on stationery</a></li>
					<li><a href="https://www.britishcouncil.org.mx/blog/preposiciones">Preposiciones en inglés</a></li>
					<li><a href="https://grammar.collinsdictionary.com/us/english-easylearning-in-spanish/cuando-se-usan-a-o-an-en-ingles">A/An</a></li>
				</ul>
			</p>
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://www.ingles.com/guia/como-dar-la-fecha-en-ingles">https://www.ingles.com/guia/como-dar-la-fecha-en-ingles</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
