import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const text = 
`
Canción del día: Work Song, by Hozier \\
Lo que vamos a cubrir: los temas E y F del segundo capítulo, repasar el capítulo
*******************************************************************************


The class starts __on Monday__.\\
La comienza comienza el lunes.

We have class __on Saturdays__.\\
Tenemos clase los sábados.

His birthday is __on Tuesday__.\\
Su cumpleaños es el martes.

__On Wednesday__, I have an exam.\\
El miércoles, tengo un examen.
*******************************************************************************
## Vocabulary

### Review
libro: a book\\
borrador: an eraser\\
diccionario: a dictionary\\
lápiz: a pencil\\
cuaderno: a notebook\\
escritorio: a desk\\
grapadora: a stapler\\
silla: a chair\\
papel: a Paper\\
pluma: a pen\\
regla: a ruler\\
computadora: a computer

### Extra vocabulary
stationery: artículos de escritorio\\
attach: adjuntar\\
schedule: horario\\
mistake: error\\
make a mistake: cometer un error\\
sheet of paper: hoja de papel\\
read: leer\\
write: escribir\\
measure: medir\\
word: palabra\\
world: mundo\\
letter: letra \\
lyrics: letra (canción)
should expresa obligación (deber)\\
What should he do?: ¿Qué debería hacer?
*******************************************************************************

Activity!

If I make a mistake (when I am writing), what do I need?\\
Si cometo un error (cuando estoy escribiendo), qué necesito? \\
__an eraser__ (borrador)

I need a surface to write on. What can I use?\\
Necesito una superficie para escribir. ¿Qué puedo usar?\\
__paper__ (papel)

I want to read. What do I need?\\
Quiero leer. ¿Qué necesito?\\
__a book__ (libro)

I want to write, but I also want to erase what I write. What do I need? \\
Quiero escribir, pero también quiero borrar lo que escribo. ¿Qué necesito? \\
__a pencil__ (lápiz)

I want to measure something. What do I need?\\
Quiero medir algo. ¿Qué necesito?\\
__a ruler__ (regla)

I don’t know what a word means. What do I need?\\
No sé qué quiere decir alguna palabra. ¿Qué necesito?\\
__a dictionary__ (diccionario)

I want to sit down. What do I need?\\
Quiero sentarme. ¿Qué necesito?\\
__a chair__ (silla)

I need something to take notes on. What can I use?\\
Necesito algo para tomar apuntes. ¿Qué puedo usar?\\
__a notebook__ (cuaderno)

I need to sign something. What should I use?\\
Necesito firmar algo. ¿Qué debería usar?\\
__a pen__ (pluma)

I want to attend an online meeting. What do I need?\\
Quiero asistir a una reunión en línea. ¿Qué necesito?\\
__a computer__ (computadora)

Where do I keep my stationery?\\
¿Dónde guardo mis artículos de escritorio?\\
__in a desk__ (en un escritorio)

I want to attach papers together. What do I use? \\
Quiero adjuntar unas hojas de papel. ¿Qué uso?\\
__a stapler__ (grapadora)
*******************************************************************************
### Tema opcional - las vocales largas y cortas
Long vowels (vocales largas) and short vowels (vocales cortas)

__Long__: - como en el alfabeto\\
a: name (ei en español)\\
e: meet\\
i: time\\
o: low (ou en español)\\
u: cute

vocal-consonante-e -> name, cute, lime, code \\
no se pronuncia la última e \\
la otra vocal se pronuncia como una vocal larga

Ojo: varias vocales se pronuncian como una vocal larga sin seguir esta regla.
En la gran mayoría de palabras que contienen este patrón, la vocal que precede la consonante
se va a pronunciar como una vocal larga.


__Short__:\\
a: man\\
e: bet\\
i: sit\\
o: frog\\
u: jump
`

export default function Week02() {
	return (
		<div>
			<h1>Week 3 Lecture Notes (2/16)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<a href="https://youtalkonline.com/blog/vocales-largas-en-ingles/">https://youtalkonline.com/blog/vocales-largas-en-ingles/</a>
				</ul>
			</p>
		</div>
	)
}
