import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Harleys in Hawaii_ by Katy Perry

La agenda de hoy:

- Comenzar la última unidad, Unidad 10

-------------------
## Tiempo libre = free time

- hobby/pastime = pasatiempo

Activity | Actividad | 
| --------- | ---------| 
dance | _bailar_
exercise | _hacer ejercicio_
fish | _pescar_
play cards | _jugar cartas_
cook | _cocinar_
listen to music | _escuchar música_
play basketball | _jugar al baloncesto_
swim | _nadar_
play the guitar | _tocar la guitarra_
watch TV | _ver la tele_
read magazines | _leer revistas_
work in the garden | _trabajar en el jardín_
go to the movies | _ir al cine_
go online | _ir en línea_
shop/go shopping | _ir de compras_
travel | _viajar_ 
visit friends | _visitar amigos_
voluteer | _trabajar como voluntario_

### Preguntas
- ¿Qué te gusta hacer? = What do you like to do?
- ¿Qué te gusta hacer en tu tiempo libre? = What do you like to do in your free time?
- ¿Qué te gusta hacer cuando estás libre? = What do you like to when you're free?

### Respuestas
- Me gusta ____  = I like _____
- Los espacios se pueden llenar con los participios presentes (cooking, dancing, reading magazines) o con los infinitivos (to cook, to dance, to read magazines)
- Me gusta nadar
	- I like swimming
	- I like to swim
- Me gusta correr
	- I like running
	- I like to run
- Me gusta ir de compras
	- I like to shop
	- I like shopping
	- I like going shopping
	- I like to go shopping 

### Tercera persona
- What does he like to do? = A él, ¿qué le gusta hacer?
	- He likes _____(running/to run)
- What does she like to do? = A ella, ¿qué le gusta hacer?
	- She likes _____ (dancing/to dance)
- What do they like to do? = A ellos, ¿qué les gusta hacer?
	- They like _____ (fishing/to fish)
	
- ¿Qué le gusta hacer a tu mamá? = What does your mom like to do?
- ¿Qué le gusta hacer a tu hijo? = What does your son like to do?

### Frases afirmativas

|Questions | _Preguntas_ |
| ---- |---- |
What do you like to do? | _¿Qué te gusta hacer?_
What does he like to do? | _A él, ¿qué le gusta hacer?_
What does she like to do? | _A ella, ¿qué le gusta hacer?_
What do we like to do? | _¿Qué nos gusta hacer?_
What do they like to do? | _A ellos/ellas, ¿qué les gusta hacer?_

| Frase | _Traddución_ |
| ----| ----| 
I like _____ | *Me gusta(n) _____*
You like _____ | *Te gusta(n) _____*
He likes _____ | *A él le gusta(n) _____*
She likes _____ | *A ella le gusta(n) _____*
We like _____ | *Nos gusta(n) _____*
They like _____ | *A ellos/ellas les gusta(n) _____*

### Frases negativas
|Questions | _Preguntas_ |
| ---- |---- |
What do you not like to do? | _¿Qué no te gusta hacer?_
What does he not like to do? | _A él, ¿qué no le gusta hacer?_
What does she not like to do? | _A ella, ¿qué no le gusta hacer?_
What do we not like to do? | _¿Qué no nos gusta hacer?_
What do they not like to do? | _A ellos/ellas, ¿qué no les gusta hacer?_

| Frase larga | Frase corta | _Traddución_ |
| ----| ----| ----|
I do not like _____ | I don’t like _____ | *No me gusta(n) _____*
You do not like _____ | You don’t like _____ | *No te gusta(n) _____*
He does not like _____ | He doesn’t like _____ | *A él no le gusta(n) _____*
She does not like _____ | She doesn’t like _____ | *A ella no le gusta(n) _____*
We do not like _____ | We don’t like _____ | *No nos gusta(n) _____*
They do not like _____ | They don’t like _____ | *A ellos/ellas no les gusta(n) _____*

## Más preguntas

- Do you like ______ = ¿Te gusta _____?
	- Do you like playing cards/Do you like to play cards? = ¿Te gusta jugar cartas?
- Respuesta: Yes, I do/ No, I don't
- ¿A él, le gusta pescar? = Does he like to fish?/Does he like fishing?
- Respuesta: Yes, he does/No, he doesn't
*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 13 Lecture Notes (5/7)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
