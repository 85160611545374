import React from 'react';

export default function Asahi() {
	return (
		<div>
			<h1>Asahi Linux Setup</h1>
			<p>
				When I purchased Mithrandir, I hadn't realized that there wasn't a way to run Linux on it.
				I've been really excited about Asahi Linux, even though I'm still missing a few key features
				such as Display Port/HDMI support as well as audio.
			</p>
			<h2>Set up decisions</h2>
			<p>
				I went with a 40-60 MacOS to Linux split. I originally wanted to give the MacOS partition
				even less space, but I find that Macs tend to run out of space
				fairly quickly, while I barely use any space on my Linux machine. I ended up choosing the
				no-frills option while setting up, since I wanted my setup to look like my
				primary setup on Faramir. Unlike Arch Linux, there is no default installer, so I got
				to set everything up from scratch for the first time!
			</p>
			<h2>Set up process</h2>
			<p>
				The first step was to log in using the default
				credentials, <span class="monospace">alarm</span> for
				both the username and password. The next step was to get connection to a network. I like
				using <span class="monospace">iwd</span> to manage my connections, and it was already installed.
				When I tried to start <span class="monospace">iwctl</span>, I got the
				message "Waiting for IWD to start...", and it never did. I thought this was because the service
				hadn't been started yet, so I made myself root (since I didn't have superuser privileges yet)
				and ran <span class="monospace">systemctl start iwd</span>, but that didn't fix the problem.
				Based off <a href="https://linuxconfig.org/how-to-manage-wireless-connections-using-iwd-on-linux">this post</a>, I added the lines
				<div className="realcode">
					[General]<br/>
					EnableNetworkConfiguration=true
				</div>
				to my <span class="monospace">/etc/iwd/main.conf</span>, which fixed the configuration issue
				I was having.
				<br/>
				<br/>
				Since I've never had to install Xorg myself (the archinstaller has an option that installs
				it for you), I first installed <span class="monospace">xorg-server</span>, and then tried
				to boot. It kept leading me back to the login page since it didn't know
				what <span class="monospace">startx</span> was, so I installed <span class="monospace">xorg-xinit</span>, which fixed the issue.
				<br/>
				<br/>
				I then logged in as root, and usermodded myself to rename user + user directory, running
				<div class="realcode">
					usermod -l krithika alarm
					usermod -d /home/krithika -m krithika
				</div>
				I set the password for both root and user.
				After that, I installed <span class="monospace">sudo</span> and made myself a superuser
				by adding the line <span class="monospace">krithika ALL=(ALL) ALL</span> to the sudoers file.
				<br/>
				<br/>
				For whatever reason, I couldn't install <span class="monospace">yay</span> at first
				because of something to do with Go, but it magically started working later. I also
				have the edge package installed, so I get the latest updates. When I was installing
				Firefox, I ran into an error on launching the app:
				<div class="realcode">
					libpython3.11.so.1.0: cannot open shared object file
				</div>
				The error was resolved by running <span class="monospace">pacman -Syu</span>

				When trying to install audio-related software, I ran into an issue about the
				service being masked, but following the advice in <a href="https://askubuntu.com/questions/1207493/unit-systemd-resolved-service-is-masked">this StackExchange site</a> fixed it
				(although the audio doesn't really work, only Bluetooth does).
				I haven't gotten the audio to work with the jack, although the documentation says that
				it should work.
			</p>
			<h3>Mac-specific tweaks</h3>
			<p>
				You'll want to increase the font size of your terminal to about 16 or so - I usually like
				12-14, but that's far too small on Macs. Regarding the brightness, <em>F1</em>
				turns the display off and <em>F2</em> turns it back on. I haven't really investigated
				why this happens, but the brightness is a bit weird. Since I use Polybar, I had to find
				what the battery and adapter were called using
				<div class="realcode">
					ls -1 /sys/class/power_supply/
				</div>
			</p>
			<h3>Keybindings</h3>
			<p>
				The primary reason why I wanted to switch over to Linux was the keybindings.
				MacOS isn't that bad; it's just that I'm so used to my keybindings: i3wm bindings,
				{' '}<em>Ctrl</em>-based commands and diacritics that it's nearly impossible for me
				to use any other workflow.
			</p>
			<p>
				I originally wanted to map the <em>Command</em> key to <em>Alt</em> and have the keys
				be in the same order as Windows keys so that typing would be seamless.
				I installed <span class="monospace">xorg-xev</span> to figure out what the names of the keys
				were, and it turns out that the Globe key doesn't map to anything. From what I read,
				if <span class="monospace">xev</span> can't detect an event, you'll have to install drivers.
				This sounded pretty painful, if not impossible, given that the MacBook is an Apple product.
			</p>
			<p>
				To remedy this, I went for a relatively-cheap and functional hardware solution:
				purchasing a keyboard. I went for the &asymp;$30 <a href="https://www.logitech.com/en-us/products/keyboards/k380-multi-device.920-007558.html">Logitech K380 keyboard</a>. It's pretty thin
				and portable, and I like how it has the Windows-style keys. It also has the forward-delete
				key right above the <em>Backspace</em> key, which is something I've sorely missed.
				I also like that it lets you connect with up to three devices, and I've thus far paired
				it with both my Mac and Linux machines. Now I can use the same keyboard for my setups!
				It doesn't have brightness keys by default, but I can maybe bind a couple F
				keys I don't use to as brightness modifiers.
			</p>
			<h2>Overall thoughts</h2>
			<p>
				I'm really excited about Asahi's features. I'm far more productive when I'm using the
				same keybindings and set up across different computers. One of the biggest drawbacks for
				me is the lack of sleep/hibernate, which means the computer heats up quickly and the battery
				drains. This isn't too big of an issue if you're pretty much always plugged in, but it's
				really annoying if you have to go a stretch of time without charge - such as running errands
				after work with your laptop still in your bag. The DisplayPort/HDMI connections don't
				work at the present, unfortunately. Even though it's missing a lot of important features,
				I still prefer using Asahi to MacOS.
			</p>
		</div>
	)
}
