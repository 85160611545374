import React from 'react';
import { Link } from 'react-router-dom';

export default function Blog() {
	return (
		<div>
			<h1>Krithika's Blog</h1>

		<p>
			Welcome to my blog!
		</p>
		<p>
			My <a href="https://github.com/krithravi/dotfiles">dotfiles</a> and these write-ups
			are a compilation of things I’ve learned from experience and have found online.
			A good chunk of this comes from the ArchWikis, Reddit, StackExchange, and other folks' GitHub repos.
		</p>
		<p>
			I typically go back and update old posts instead of making
			new ones, so many of my older entries contain info about practices and tools I currently use.
		</p>
		<p>
		Here are my current <Link to="/blog/preferences">Linux</Link> and <Link to="/blog/mac-preferences">Mac</Link> setups:
			<ul>
				<li>Faramir: HP Pavilion Aero 13{/*(be0177nr)*/}, with Arch Linux </li>
				<li>Mithrandir: MacBook Pro with M1 chip, dual boot with MacOS and Asahi Linux</li>
				<li><s>&Eacute;owyn: Dell XPS 15 9570, with Arch Linux</s></li>
				<li>Pippin: Raspberry Pi 3B+, with Raspbian Lite</li>
			</ul>
		</p>
		<p>
			Here live some entries, ordered newest to oldest:
		</p>
		<p>
			<ul>
				<li><Link to="/blog/bluetooth">Bluetooth Setup</Link></li>
				<li><Link to="/blog/asahi">Asahi Linux Setup</Link></li>
				<li><Link to="/blog/esl">ESL Class Workflow</Link></li>
				<li><Link to="/blog/mac-preferences">My Mac Setup</Link></li>
				<li><Link to="/blog/audio">Audio Startup with PulseAudio</Link></li>
				<li><Link to="/blog/terminal">Terminal Emulator Reviews</Link></li>
				<li><Link to="/blog/dellxps">Boot Up Frustrations in Dell XPS (Goodbye, &Eacute;owyn)</Link></li>
				<li><Link to="/blog/arch">Arch Linux Install + Setup</Link></li>
				<li><Link to="/blog/spotify">Former Spotify Installation</Link></li>
				<li><Link to="/blog/practices">Good Practices</Link></li>
				<li><Link to="/blog/scroll">Natural Scrolling with i3</Link></li>
				<li><Link to="/blog/deskenv">Desktop Environments</Link></li>
				<li><Link to="/blog/preferences">My Linux Setup</Link></li>
				<li><Link to="/blog/debian">Debian Installation</Link></li>
				<li><Link to="/blog/virtualbox">VirtualBox Setup</Link></li>
				<li><Link to="/blog/network">Network Connection on Arch</Link></li>
				<li><Link to="/blog/browser">Browser Reviews</Link></li>
				<li><Link to="/blog/power">Power Management</Link></li>
				<li><Link to="/blog/tweaks">Accent Marks in Ubuntu (GNOME)</Link></li>
				<li><Link to="/blog/map">Mapping Keys</Link></li>
				<li><Link to="/blog/bash">Beautifying the Bash Experience</Link></li>
				<li><Link to="/blog/color">Changing Login Color</Link></li>
				<li><Link to="/blog/sleep">Sleep and Wake</Link></li>
			</ul>
		</p>
		</div>
	);
}
