import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _lighthouse_ by Kelly Clarkson

La agenda de hoy:

- Repasar Unidades 3 y 4
- Empezar Unidad 5 (hacer actividades en secciones A y B)

*******************************************************************************

## Los lugares (Places)

|Español | English |
|:-----|:-----|
|banco| bank|
|biblioteca| library|
|librería| bookstore/bookshop|
|restaurante| restaurant  |
|tienda| store/shop|
|escuela| school|
|calle| street|
|supermercado| supermarket|
|peluquería| barbershop/salon|
|panadería| bakery|
| farmacia | pharmacy|
| hospital | hospital | 
| cine | movie theater |
| gasolinera | gas station | 
| lavandería | laundromat | 
| oficina de correo | post office | 
*******************************************************************************
## La e muda
- La "e" al fin no se pronuncia
- Modifica la pronunciación de la vocal que inmediatamente precede la "e"
- reptile (reptil)

Vocales largas:
- a: bake (hornear)
- e: cede (ceder)
- i: bike (bicicleta)
- o: hope (esperanza)
- u: rebuke (reprochar)

*******************************************************************************
| English | Traducción | 
| --- | --- |
|I need to buy books! Where can I buy them? <br/> Bookstore | ¡Necesito comprar libros! ¿Dónde puedo comprarlos? <br/>  librería| 
| I'm hungry! Where can I get some food? <br/> Restaurant/Supermarket | Tengo hambre. ¿Dónde se vende comida? <br/> Restaurante/supermercado | 
| Where can I learn English? <br/> School | ¿Dónde puedo aprender inglés? <br/> Escuela|
| Where can I drive a car? <br/> Street | ¿Dónde puedo manejar un carro? <br/> Calle |
| I want cookies. Where can I get some? <br/> Bakery/supermarket | Quiero galletas. ¿Dónde se venden? <br/> Panadería/supermercado|
| I wrote a letter to my friend. Where can I send it? <br/> Post office | Escribí una carta a mi amigo. ¿Dónde puedo enviarla? <br/> Oficina de correro |
| My car ran out of gas! Where should I go? <br/> gas station | ¡Mi carro se quedó sin gasolina! ¿Adónde debo ir? <br/> Gasolinera  | 
| I need a haircut. Where should I go? <br/> barbershop/salon | Necesito un corte de cabello. ¿Adónde debo ir? <br/> Peluquería/salón de belleza |
| Where can I get vaccinated? <br/> pharmacy | ¿Adónde puedo ir para vacunarme? <br/> Farmacia |
| This man is really sick! Where should they take him? <br/> Hospital | ¡Este hombre está muy enfermo! ¿Adónde lo deben llevar? <br/> hospital | 
*******************************************************************************

## Más preposiciones
- next to = al lado de
- between = en medio de
- across from = enfrente de
- on - con las calles
- at - decir que algo está en algún lugar
	- I am at home = Estoy en casa
	- I am at the library
	- He is at the bank
`

export default function Week02() {
	return (
		<div>
			<h1>Week 3 Lecture Notes (6/20)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://englishlive.ef.com/es-mx/blog/tips-para-estudiar/reglas-de-fonetica-para-leer-y-escribir-hablar/">La "e" muda</a></li>
				</ul>
			</p>
		</div>
	)
}
