import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Anti-Hero_ by Taylor Swift

La agenda de hoy:

- Repasar la Unidad 2 con unas actividades del libro

*******************************************************************************

## A y An

- A university (yu-niversity) | una universidad
- A unique box = una caja única
- An umbrella = paraguas
- A black cat = un gato negro
- An orange pumpkin = una calabaza anaranjada
- An hour = una hora - h muda
- An heir = un heredero - h muda
- A history book = un libro de historia


| Frase | Translation |
| ---- | ---- |
| una chaqueta amarilla | a yellow jacket |
| una caja marrón | a brown box | 
| un teléfono | a phone | 
| una botella de agua | a water bottle | 
| una hormiga | an ant |
| un ojo | an eye (ai) | 
| una mano | a hand | 
| un mono | a monkey | 
| un huevo | an egg |
| un animal | an animal | 
| una medusa | a jellyfish | 
| una manzana | an apple | 
| una cebolla | an onion | 
| una unión | a union | 
| una clase de ESL | an ESL class | 
| un agente de FBI | an FBI agent | 
| un agente de CIA | a CIA agent | 
| una Licenciatura en Ciencias (BS) | a BS |

*******************************************************************************

`

export default function Week02() {
	return (
		<div>
			<h1>Week 3 Lecture Notes (9/21)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
