import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`
Song of the day (_Canción del día_): _Stressed Out_ by Twenty One Pilots \\
Lo que vamos a cubrir: comenzar la 3<sup>a</sup> unidad (temas A, B)

*******************************************************************************

| Word | _Traducción_ |
| ---- | ----- |
| baby | _bebé_ |
| girl | _chica_ |
| boy | _chico_ |
| teenager | _adolescente_ |
| woman (wu-muhn) | _mujer_ |
| women (wi-men)| _mujeres_ |
| man | _hombre_ |
| men | _hombres_ |
| daughter | _hija_ |
| son | _hijo_ |
| wife | _esposa_ |
| husband | _esposo_ |
| sister | _hermana_ |
| brother | _hermano_ |
| mother | _madre_ |
| father | _padre_ |
| grandmother | _abuela_ |
| grandfather | _abuelo_ |
| uncle | _tío_ |
| aunt | _tía_ |
| child/kid | _hijo/hija_ (neutro)|
| children/kids | _hijos_ (neutro)|

- ant = _hormiga_
- My brother's name is Jake
- My husband's name is Mark = _El nombre de mi esposo es Mark_
- How many children/kids do you have? (_¿Cuántos hijos tienes?_)
- I have three children. (_Tengo 3 hijos_)

### Unas palabras opcionales 


| Word | _Traducción_ |
| ---- | ----- |
| nephew | _sobrino_ |
| niece | _sobrina_ |
| brother-in-law | _cuñado_ |
| sister-in-law | _cuñada_ |
| father-in-law | _suegro_ |
| mother-in-law | _suegra_ |
| in-laws | _suegros_ |
| stepfather | _padrastro_ |
| stepmother | _madrastra_ |
| stepbrother | _hijastro_ |
| stepsister | _hijastra_ |
| godfather | _padrino_ |
| godmother | _madrina_ |
| cousin | _primo/prima_ |
| son's/daughter's godmother | _comadre_ |
| son's/daughter's godfather | _copadre_ |
| son's/daughter's mother-in-law | _consuegra_ |
| son's/daughter's father-in-law | _consuegro_ |

*******************************************************************************
`

export default function Week01() {
	return (
		<div>
			<h1>Week 1 Lecture Notes (6/8)</h1>
			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://quizlet.com/544256453/vocabulario-de-familia-flash-cards/">Quizlet sobre el vocabulario de esta unidad</a></li>
				</ul>
			</p>
		</div>
	)
}
